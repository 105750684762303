import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';
import pgridUtils, { Diff_PgridData, IsNumeric } from './pgridUtils.js'



/* This function has issues as it does not know of the PGrid validation type when converting HT values to PGrid values */
export function Get_PGridCell_Value(y, x, hotCellVal, mType, pgridTable, source = "unknown for Get_PGridCell_Value") {

    let retPGridCell = null;

    // if(typeof hotCellVal === 'string'){
    //     hotCellVal = hotCellVal.replace(/\n$/g, '');
    // }
    if (pgridTable.length < y) {
        if (pgridTable[y].length < x) {
            let errMsg = `> Get_PGridCell_Value() Illigal, matrix is to small`;
            throw new Error(errMsg);
        }
    }

    if (mType == "Json") { //Special case for Json mode

        if (typeof hotCellVal === 'string' && hotCellVal.indexOf('\n') != -1) {
            console.warn("Will merge json with a newline ");
        }

        retPGridCell = Get_PGridCell_JSON(y, x, hotCellVal, pgridTable, true);
    }
    else {

        var mTypeNew = null;
        var mTypeCleanup = ""; //Colon separated list, like "Val:ValStr"
        var newValue = undefined;


        // retPGridCell = JSON.parse(JSON.stringify(pgridTable[y][x]));
        retPGridCell = pgridTable[y][x]

        if (retPGridCell == undefined) {
            retPGridCell = {};
        }

        if (hotCellVal === null || hotCellVal === undefined) { // New input is null or undefined then remove cell data from field
            if (mType == 'default') { //Remove 'Val' and 'ValStr´s
                mTypeNew = null;
                mTypeCleanup = 'Val:ValStr';
                newValue = undefined;
            } else { // If Format, Formula or RefType
                mTypeCleanup = mType;
            }

        } else { //Cell has data, then overwrite existing

            /* Select correct mType for the cell data */


            //Preparations, cleanup of other types of values
            {
                //If in dynamic mode
                if (mType.indexOf('default') === 0) {

                    if (hotCellVal === "") {
                        mTypeCleanup = 'Val:ValStr';
                        mTypeNew = null;
                    }
                    else
                        if (isNaN(hotCellVal)) { //Cleanup 'Val' and formula if text
                            mTypeNew = 'ValStr';
                            mTypeCleanup = 'Val';

                            //Is a formula
                            if (String(hotCellVal).length > 0 && hotCellVal[0] === '=') {
                                mTypeNew = "Formula";
                                mTypeCleanup = 'Val';
                            }
                        }
                        else {
                            mTypeNew = 'Val';               //Cleanup 'ValStr' if is numberu
                            mTypeCleanup = 'ValStr';
                        }
                }

                //If in static mode
                else { // Format, Formula, RefType
                    mTypeNew = mType;

                    if (mTypeNew === "ValStr") {
                        mTypeCleanup = 'ValStr:Val';
                    }
                    if (mTypeNew === "Val") {
                        mTypeCleanup = 'ValStr:Val';
                    }
                    if (mTypeNew === "Formula") {
                        mTypeCleanup = 'Formula';
                    }
                }
            }
            /* Prepare the new value*/

            if (mTypeNew === "Val") { //only store object (or number) if mType is "Val"
                newValue = Number(hotCellVal);
                // } else if (mType.indexOf('default_raw') === 0) {
                //     newValue = JSON.parse(`"${hotCellVal}"`); //parse back json
            } else {
                newValue = ("" + hotCellVal).replace(/\\\\/g, "\\");
            }
        }

        //Cleanup

        var mTypesToDel = mTypeCleanup.split(":");
        for (var i = 0; i < mTypesToDel.length; i++) {
            var mTypeDel = mTypesToDel[i];
            if (mTypeDel in retPGridCell) {
                delete retPGridCell[mTypeDel];
            }
        }

        //Store new value, except if mtype is null
        if (mTypeNew != null) {
            retPGridCell[mTypeNew] = newValue;
        } else {
            if (retPGridCell.Meta && retPGridCell.Meta.Validator && retPGridCell.Meta.Validator.Type) {
                if (retPGridCell.Meta.Validator.Type == 'date') {
                    //If empty string, save as ValStr = null. This is required for example entering '' string in date fields. 
                    retPGridCell['ValStr'] = null;
                }
            }
        }
    }

    // return pgridTable;
    return retPGridCell;
}

/*

Merges json formated cell input from hot table and returns an pgriTable cell

*/
export function Get_PGridCell_JSON(y, x, hotCellVal, pgridTable, jsonEscape = false) { //Special case for Json type

    let retPGridCell = null;

    if (pgridTable.length < y) {
        if (pgridTable[y].length < x) {
            throw 'Illigal, matrix is to small'
        }
    }

    if (pgridTable[y][x] === undefined || pgridTable[y][x] === null) {
        retPGridCell = {};
    }
    else {
        //  retPGridCell = JSON.parse(JSON.stringify(pgridTable[y][x]));
        retPGridCell = lodash_clone(pgridTable[y][x]);
        //retPGridCell = pgridTable[y][x];


        //Always cleanup
        var mTypeCleanup = "Val:ValStr:Format:Formula:RefType:JsonInvalidTmp";
        var mTypesToDel = mTypeCleanup.split(":");
        for (var i = 0; i < mTypesToDel.length; i++) {
            var mTypeDel = mTypesToDel[i];
            if (mTypeDel in retPGridCell) {
                delete retPGridCell[mTypeDel];
            }
        }

    }

    var jsonAllProps = null;

    try {
        if (typeof hotCellVal === 'string' && hotCellVal.length > 0) {
            hotCellVal = hotTableValueEscape(hotCellVal);
            jsonAllProps = JSON.parse(hotCellVal);
        }
    } catch (jsonAllProps_parse_error) {
        jsonAllProps = { JsonInvalidTmp: hotCellVal };
        console.error("jsonAllProps_parse_error: " + jsonAllProps_parse_error + "\n\nhotCellVal: " + hotCellVal)
    }

    if (jsonAllProps !== null) {

        retPGridCell = jsonAllProps;
    }

    return retPGridCell;
}
export function Get_Val_For_FormulaParser(pgridCell, source = null) {

    let parseValue = null;

    if (pgridCell != null) {
        if (("Formula" in pgridCell)) {

            //A Formula cell, must now have a Val or ValStr
            if ("Val" in pgridCell) {
                parseValue = pgridCell.Val;
            } else if ("ValStr" in pgridCell) {
                parseValue = pgridCell.ValStr
            } else {
                let errMsg = `Get_Val_For_FormulaParser() pgridTable cell should have Val or ValStr, but hasn't, source: ${source}`
                let errMsg2 = `...Parser() Missing Val or ValStr: ${source}`

                console.warn(errMsg2);
                throw new Error(errMsg);
                // throw `pgridTable cell shoul have Val or ValStr, but hasn't`
            }
        } else { // Non formula cell
            if ("Val" in pgridCell) {
                parseValue = pgridCell.Val;
            } else if ("ValStr" in pgridCell) {
                if (pgridCell.ValStr.length > 0 && pgridCell.ValStr[0] === '=') {
                    throw `=XXX is not considered a formula as it is stored in ValStr`;
                }
                parseValue = pgridCell.ValStr
            } else {
                // parseValue = undefined; //Implicit undefined
            }
        }
    }

    if (parseValue === null) {
        false && console.warn("Get_Val_For_FormulaParser() Returning 0 for empty pgridCell");
        parseValue = 0;
    }

    return parseValue;
}

export function InputBar_ConvertPGridCellForEdit(store, { pcell, source }) {
    let ret = null;

    if (pcell) {
        if ("Formula" in pcell) {
            ret = pcell.Formula;
        } else if ("Val" in pcell) {
            ret = pcell.Val;
        } else if ("ValStr" in pcell) {
            ret = pcell.ValStr;
        }
    }
    return ret;
}

export function Insert_Calculated_Cell(inCalcCell, pgridTable, oneTimeFormula) {

    let ret = { hotValue: null, error: null };
    let mTypeCleanup = "Val:ValStr";

    let y = inCalcCell.y;
    let x = inCalcCell.x;

    if ((y < 0 || y + 1 > pgridTable.length)
        || (x < 0 || x + 1 > pgridTable[y].length > x)) {
        let errMsg = `> Insert_Calculated_Cell() illegal dimension reference y: ${y} x: ${x}`;
        // throw new Error(errMsg);
        console.error(errMsg);
        ret.error = errMsg;
    } else {

        let pgModifyCell = pgridTable[y][x];

        let valIsOK = true;

        mTypeCleanup.split(":").forEach(
            (mTypeDel) => {
                delete pgModifyCell[mTypeDel];
            });



        if (inCalcCell.error != null) {



            if (inCalcCell.error == "#DIV/0!" || inCalcCell.error == "#VAL_DIV/0!") {

                let numberFormatZeroIfError = lodash_get(pgModifyCell, "Meta.NumberFormat.ZeroIfError", null);
                if (numberFormatZeroIfError) {
                    inCalcCell.error = null;
                    inCalcCell.value = 0;
                }
            } else {

                valIsOK = false;


                //Got parse error
                console.error(`Parse error: '${inCalcCell.error}' for formula '${inCalcCell.formula}' in cell y: ${y} x: ${x} cellData: ${JSON.stringify(pgridTable[y][x])}`);

                ret.hotValue = inCalcCell.error; //"#ERROR!"
                let pgModifyCell = pgridTable[y][x];

                // PGrid
                //Dont add erroneous value

                pgModifyCell.Error = inCalcCell.error;
            }

        }


        if (valIsOK) {
            delete pgModifyCell.Error;
            if (isNaN(inCalcCell.value)) {
                if (inCalcCell.value === null || inCalcCell.value === "") {
                    //Dont add empty value
                    ret.hotValue = null
                }

                ret.hotValue = inCalcCell.value;
                pgModifyCell.ValStr = inCalcCell.value;

            } else {
                let numVal = Number(inCalcCell.value);
                ret.hotValue = numVal
                pgModifyCell.Val = numVal;
            }

            if (oneTimeFormula) {
                pgModifyCell.Meta.OrginalFormula = pgModifyCell.Formula;
                delete pgModifyCell.Formula; //Delete formula as it has OneTimeFormula flag
            }
        }
    }
    return ret;
}


export function hotTableValueEscape(str) {
    let ret = str;
    if (typeof ret === 'string') {
        ret = ret.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        console.debug(`hotTableValueEscape() escaping '${str}' --> '${ret}'`);
    }
    return ret;
}

//Homogenize add new rows?
export function expandIfNeeded(pgridTable, newY, newX, fillValue = null) {
    //Add extra rows

    while (pgridTable.length < (newY + 1)) {
        for (let yNewIter = 0; yNewIter < (newY + 1) - pgridTable.length; yNewIter++) {
            pgridTable.push(new Array(pgridTable[0].length).fill(fillValue));
        }
    }

    //Add extra cols
    while (pgridTable[0].length < (newX + 1)) {
        for (let yNewIter = 0; yNewIter < pgridTable.length; yNewIter++) { //All rows
            pgridTable[yNewIter] = pgridTable[yNewIter].concat(Array((newX + 1) - pgridTable[yNewIter].length).fill(fillValue));
        }
    }

    return pgridTable;
}

export function expandHOTIfNeeded(hotTable, newY, newX, fillValue = null) {
    //Add extra rows

    while (hotTable.length < (newY + 1)) {
        for (let yNewIter = 0; yNewIter < (newY + 1) - hotTable.length; yNewIter++) {
            hotTable.push(new Array(hotTable[0].length).fill(fillValue));
        }
    }

    //Add extra cols
    while (hotTable[0].length < (newX + 1)) {
        for (let yNewIter = 0; yNewIter < hotTable.length; yNewIter++) { //All rows
            hotTable[yNewIter] = hotTable[yNewIter].concat(Array((newX + 1) - hotTable[yNewIter].length).fill(fillValue));
        }
    }

    return hotTable;
}


export function insertRowAt(pgridTable, insertY, fillValue = undefined, debugFillRightmost = undefined) {

    let deepDebug = false;

    let pgridDataBefore = null;
    if (deepDebug) {
        pgridDataBefore = JSON.parse(JSON.stringify(pgridTable));
    }

    //Add extra rows
    if (insertY < pgridTable.length + 1) {

        let newRow = new Array(pgridTable[0].length).fill(fillValue);
        if (debugFillRightmost != undefined) {
            newRow[newRow.length - 1] = { ValStr: String(debugFillRightmost) };
        }
        pgridTable.splice(insertY, 0, newRow)
    } else {
        throw new Error("Cannot insert row at ${insertY} when table row don´t exists");
    }

    let pgridDataAfter = null;
    if (deepDebug) {
        pgridDataAfter = JSON.parse(JSON.stringify(pgridTable));
    }

    deepDebug && console.debug(`insertRowAt insert at ${insertY} Diff:\n${JSON.stringify(Diff_PgridData(pgridDataBefore, pgridDataAfter), null, 2)}`);

    return pgridTable;
}


export function indexOfDSProp(pgridDimDS, propName) {

    // let propNameTrimmed = propName.replace(/[\[\]]/g, "")
    let propNameTrimmed = propName;
    // if (propName !== propNameTrimmed) {
    //     console.warn(`propName !== propNameTrimmed (${propName} !== ${propNameTrimmed} )`)
    // }
    let ret = pgridDimDS[0].indexOf(propNameTrimmed);
    if (ret == -1) {
        throw new Error(`indexOfProp() Could not find property '${propNameTrimmed}' in headers ${JSON.stringify(lodash_get(pgridDimDS, "[0]", []))}`);
    }
    return ret;
}


export function pgridCellHasValue(pcell) {
    let ret = false;

    if (pcell.Val != null || pcell.ValStr != null) {
        ret = true;
    }

    return ret;
}

export function pgridCellGetOnlyValOrValStr(pcell) {
    let ret = {};

    if ("Val" in pcell) {
        ret = { Val: pcell.Val }
    } else if ("ValStr" in pcell) {
        ret = { ValStr: pcell.ValStr }
    }

    return ret;
}

export function ECellMerge(currCell, insCell, options = {}) {

    if (insCell != null) {
        if (currCell == null) {
            currCell = {};
        }

    }

    if ("Val" in insCell) {
        delete currCell["ValStr"];
    }
    if ("ValStr" in insCell) {
        delete currCell["Val"];
    }

    let ret = lodash_merge(currCell, insCell);
    return ret;
}

export function mergeCells(currCell, insCell, strategy = []) {

    let ret_mergedCell = null;

    if (currCell == null) {
        currCell = {};
    }


    let currCellCopy = lodash_clone(currCell);

    if (lodash_has(currCellCopy, `Meta.CopyFromColumnAppliedWith`)) {

        if (lodash_has(insCell, `Meta.CopyFromColumnAppliedWith`)) {

            //Remove allready added props from CopyFromColumnAppliedWith;
            //This to avoid that this overwrites itself for other levels:
            /*
            "Rule": {
                "If_Axis": "Rows",
                "If_Level": "any"
            },
            "Insert": [
                {
                    "LevelPosition": "FactRows",
                    "Merge": true,
                    "MergeStrategies": [
                        "CustomKeep:Meta.MetaForRows"
                    ],
                    "CopyFromColumn": {
                        "From": "Meta.MetaForRows",
                        "To": "Meta",
                        "Merge": true,
                        "MergeStrategies": [
                            "Defensive"
                        ]
                    }
                }
            ]
            */

            let primKeys = ["Save", "Validator"];

            for (let pki = 0; pki < primKeys.length; pki++) {

                let pk = primKeys[pki];

                if (pk in insCell.Meta) {

                    let secKeys = Object.keys(insCell.Meta[pk]);

                    for (let ski = 0; ski < secKeys.length; ski++) {

                        let sk = secKeys[ski];
                        delete insCell.Meta[pk][sk]; //Deleting before merge
                    }
                }
            }
        }
    }

    let skippMerge = false;

    if (strategy.indexOf("Defensive") != -1) {
        ret_mergedCell = lodash_merge(insCell, currCellCopy,);
    }
    else if (strategy.indexOf("Merge") != -1) {

        //But thiswill overwrite static Format for example
        ret_mergedCell = lodash_merge({}, currCellCopy, insCell);

    }
    else {
        //FUB should be a generic solution to not overwrite on merge

        let customStrategyTxt = strategy.find(o => o.indexOf("Custom") == 0);

        let appendOnArray = {};

        if (customStrategyTxt) {

            let [CustomMode, lodashPath] = customStrategyTxt.split(":");

            if (CustomMode == "CustomKeep") {

                let test = lodash_get(insCell, lodashPath, undefined);

                if (test != undefined) {
                    skippMerge = true;
                }
            }

            if (CustomMode == "CustomSwipe") {
                skippMerge = true;
            }
        }

        let oldFormatArr = lodash_get(currCellCopy, "Format", "").split(",").sort((a, b) => a - b);

        if (skippMerge) {
            ret_mergedCell = currCellCopy;
        } else {
            ret_mergedCell = lodash_merge({}, currCellCopy, insCell);
        }

        //Merge css
        if (strategy.indexOf("MergeFormat") != -1) {
            let newFormatArr = lodash_get(ret_mergedCell, "Format", "").split(",").sort((a, b) => a - b);
            if (oldFormatArr != newFormatArr) {
                let onlyUnique = function (value, index, self) {
                    return self.indexOf(value) === index;
                }
                let mergedFormatArr = [...oldFormatArr, ...newFormatArr].filter(onlyUnique);
                lodash_set(ret_mergedCell, "Format", mergedFormatArr.join(","));
            }
        }

        if (strategy.indexOf("KeepValStr") == -1) {
            if (("Val" in insCell) && ("ValStr" in ret_mergedCell)) {
                delete ret_mergedCell.ValStr;
            }
        }

        if (strategy.indexOf("KeepRefType") == -1) {
            if (("KeepRefType" in insCell) && ("KeepRefType" in ret_mergedCell)) {
                delete ret_mergedCell.ValStr;
            }
        }


        if (strategy.indexOf("KeepVal") == -1) {

            //If har a Val value and input only has an ValStr = "", then delete ValStr, not Val.

            if (strategy.indexOf("KeepValOnEmptyValStr") != -1 && "Val" in currCellCopy && "ValStr" in insCell && insCell.ValStr === "") {
                // Delete ValStr insted
                delete ret_mergedCell.ValStr;
            } else {
                //The normal case
                if (("ValStr" in insCell) && ("Val" in ret_mergedCell)) {
                    delete ret_mergedCell.Val;
                }

            }

        }

        if (("Formula" in ret_mergedCell) && ret_mergedCell.Formula === "") {
            delete ret_mergedCell.Formula;
        }

        function RemoveFormatKeyListItem(formatKey, itemName) {

            if (("Meta" in ret_mergedCell) && ("Format" in ret_mergedCell.Meta) && (formatKey in ret_mergedCell.Meta.Format)) {
                let currentFormatKeyItems = ret_mergedCell.Meta.Format[formatKey]
                if (Array.isArray(currentFormatKeyItems)) {
                    ret_mergedCell.Meta.Format[formatKey] = currentFormatKeyItems.filter(x => x != itemName);
                }
            }
        }

        function RemoveFormatItemFromIn(fromFormatKey, removeFormatKey) {
            if (("Meta" in insCell) && ("Format" in insCell.Meta) && (fromFormatKey in insCell.Meta.Format)) {
                let currentFormatKeyItems = ret_mergedCell.Meta.Format[fromFormatKey]
                if (Array.isArray(currentFormatKeyItems)) {
                    for (let itmIdx = 0; currentFormatKeyItems.length > itmIdx; itmIdx++) {
                        let itmName = currentFormatKeyItems[itmIdx];
                        RemoveFormatKeyListItem(removeFormatKey, itmName);
                    }
                }
            }
        }
        RemoveFormatItemFromIn("AddClasses", "RemoveClasses");
        RemoveFormatItemFromIn("RemoveClasses", "AddClasses");

        //Handle Remove Classes from AddClasses

    }
    return ret_mergedCell;
}


export function merge_PCellValue(oldCell, newCell) {

    if (oldCell == null) {
        oldCell = {};
    }

    if ("Val" in newCell) {

        oldCell.Val = newCell.Val;

        if ("ValStr" in oldCell) {
            delete oldCell.ValStr;
        }

    } else if ("ValStr" in newCell) {

        oldCell.ValStr = newCell.ValStr;

        if ("Val" in oldCell) {
            delete oldCell.Val;
        }

    } else {
        if ("Val" in oldCell) {
            delete oldCell.Val;
        }
        if ("ValStr" in oldCell) {
            delete oldCell.ValStr;
        }
    }

    return oldCell;
}


export function getHotValue2(pcell, mType = "default") {
    let hotVal = undefined;

    if (mType.indexOf("default") === 0) {
        if (pcell == undefined) {
            hotVal = undefined;
        }
        else if ("Formula" in pcell) {

            if ("Val" in pcell) {
                hotVal = pcell.Val;
            } else if ("ValStr" in pcell) {
                hotVal = pcell.ValStr;
            }
        } else if ("Val" in pcell) {
            hotVal = pcell.Val;
        } else if ("ValStr" in pcell) {
            if (mType.indexOf("default_raw") === 0
                || mType.indexOf("default_static") === 0) {
                // hotVal = JSON.stringify(pcell.ValStr).slice(1, -1);
                hotVal = pcell.ValStr;
            } else {
                hotVal = pcell.ValStr;
            }
        } else {
            // hotVal = undefined; //Keep  undefined 
        }

    }
    else if (mType == "Json") {  //See: Translate_ViewModeToMergeType()
        if (pcell == undefined) {
            hotVal = undefined;
        } else {
            var jsonAllProps = {};


            var mTypes = "Val:ValStr:Format:Formula:RefType";
            mTypes.split(":").forEach(mt => {
                if (mt in pcell) {
                    if (mt === "RefType") {
                        try {
                            let mTypeVal = pcell[mt];
                            if (mTypeVal) { //Don't add empty RefType:s
                                let mTypeValParsed = JSON.parse(pcell[mt])
                                jsonAllProps[mt] = JSON.stringify(mTypeValParsed); //His helps removing \r \b \t etc
                            }
                        } catch (err) {
                            let errMsg = `> Convert_PgridDataToHotTable() JSON.parse RefType got exception: ${err.message || err}`;
                            throw errMsg;
                        }
                    } else {
                        jsonAllProps[mt] = pcell[mt];
                    }
                }
            });


            hotVal = JSON.stringify(jsonAllProps);
        }
    } else {
        if (pcell == undefined) {
            hotVal = undefined;
        } else {
            if (mType in pcell) {
                hotVal = pcell[mType];
            }
        }
    }



    return hotVal;
}

export function getHotValue(pcell) {
    let ret = undefined;

    if ("Val" in pcell) {
        ret = pcell.Val;
    } else if ("ValStr" in pcell) {
        ret = pcell.ValStr;
    }
    return ret;
}

export function GetPCellValue_FromHOTValue(value) {
    let ret = null;
    if (value == null) {
        ret = null;
    } else {
        ret = {};
        if (IsNumeric(value)) {
            ret.Val = value;
        } else {
            ret.ValStr = String(value)
        }
    }

    return ret;
}


export function MaybieCopyObj(inObj, importance = 3 /* 1-5 */) {
    let ret = null;

    const treshhold = 1;

    if (importance > treshhold) {
        ret = JSON.parse(JSON.stringify(inObj));
    }
    else {
        ret = inObj;
    }

    return ret;
}


export function extractDimNodeData(pgridLR, currentLvl, startIdx) {

    const THRESHOLD_FORCE_NEWLINE_AT_SPACE_LENGTH = 20;
    const THRESHOLD_FORCE_NEWLINE_AT_SPACE_FIST_SPACE_LENGTH = 13;
    const THRESHOLD_FORCE_NEWLINE_FORCE_LENGTH = 25;

    let ret = {
        NODE_ID: null,
        NODE_NAME: null,
        NODE_VALUE: null,
        NODE_NAME_FIRST_ROW: null,
        NODE_NAME_FORCED_NEWLINES: null,
        NODE_WRITABLE: null,
        NODE_TYPE: null,
        NODE_ISGROUP: 0,
        NODE_TYPE_FORMAT: null,
    };

    const currentColumnCellId = currentLvl.DataSet[startIdx][currentLvl.Header.Id.ColIdx]; //The current row "Name" value (should be text data)
    const currentColumnCellName = String(currentLvl.DataSet[startIdx][currentLvl.Header.Name.ColIdx]); //The current row "Name" value (should be text data)
    const currentColumnCellValue = currentLvl.DataSet[startIdx][currentLvl.Header.Value.ColIdx]; //The current row "Value" value, usually same ad Id

    ret.NODE_ID = `${currentColumnCellId}`;
    ret.NODE_VALUE = `${currentColumnCellValue}`; //Display name here
    ret.NODE_NAME = `${currentColumnCellName}`;
    ret.NODE_NAME_FIRST_ROW = `${currentColumnCellName}`;
    ret.NODE_NAME_FORCED_NEWLINES = `${currentColumnCellName}`;

    Object.keys(currentLvl.Header).forEach(hk => {
        if (/*hk != "Value" && hk != "Id"  && */ hk != "Name") {
            ret[`NODE_COL_VALUE_${hk}`] = lodash_get(currentLvl.DataSet[startIdx], currentLvl.Header[hk].ColIdx, null); //The current row "Value" value, usually same ad Id
        }
    })


    if (pgridLR.replaceNodeProps) {

        pgridLR.replaceNodeProps.forEach(o => {

            if ("ReplaceAll" in o) {
                ret[o.Prop] = ret[o.Prop].replaceAll(o.ReplaceAll, o.Replacement);
            }
            else {
                let re = new RegExp(o.RegEx, (("Flags" in o) ? o.Flags : "g"));
                ret[o.Prop] = ret[o.Prop].replace(re, o.Replacement);
            }
        });

    }



    //Add writable if exists
    const hasWritable = lodash_has(currentLvl, "Header.Writable.ColIdx");

    ret.NODE_WRITABLE = false; // false was New default, but is now old
    if (hasWritable) {
        const currentColumnCellIsWritable = currentLvl.DataSet[startIdx][currentLvl.Header.Writable.ColIdx]; //The current row "Value" value, usually same ad Id
        // if (currentColumnCellIsWritable != null) {
        //     if (currentColumnCellIsWritable == "false" || currentColumnCellIsWritable == 0) {
        //         ret.NODE_WRITABLE = false;
        //     } else if (currentColumnCellIsWritable == "true" || currentColumnCellIsWritable == 1) {
        //         ret.NODE_WRITABLE = true;
        //     }
        // }
        ret.NODE_WRITABLE = pgridUtils.Parse_TrueFalseNull(currentColumnCellIsWritable);
    }

    //Add type if  xists
    const hasType = lodash_has(currentLvl, "Header.Type.ColIdx");
    ret.NODE_TYPE = null;
    if (hasType) {
        const currentColumnCellIsType = currentLvl.DataSet[startIdx][currentLvl.Header.Type.ColIdx]; //The current row "Value" value, usually same ad Id
        if (currentColumnCellIsType != null) {
            ret.NODE_TYPE = currentColumnCellIsType;
        }
    }

    //Add IsGroup if exists
    const hasIsGroup = lodash_has(currentLvl, "Header.IsGroup.ColIdx");
    ret.NODE_ISGROUP = 0;
    if (hasIsGroup) {
        const currentColumnCellIsIsGroup = currentLvl.DataSet[startIdx][currentLvl.Header.IsGroup.ColIdx]; //The current row "Value" value, usually same ad Id
        if (currentColumnCellIsIsGroup != null) {
            ret.NODE_ISGROUP = currentColumnCellIsIsGroup;
        }
    }


    let formatType = 'number';
    if (ret.NODE_TYPE == null) {
        //keep number';
    }
    else {
        if (ret.NODE_TYPE.toUpperCase().indexOf('MEASURE') != -1) {
            formatType = 'number';
        }
        else if (ret.NODE_TYPE.toUpperCase().indexOf('DATE') != -1) {
            formatType = 'date';

        } else if (ret.NODE_TYPE.toUpperCase().indexOf('PERCENT') != -1) {
            formatType = 'percent';
        } else if (ret.NODE_TYPE.toUpperCase().indexOf('PERC100') != -1) {
            formatType = 'perc100';
        } else if (
            ret.NODE_TYPE.toUpperCase().indexOf('JA_NEJ') != -1 ||
            ret.NODE_TYPE.toUpperCase().indexOf('YES_NO') != -1
        ) {
            formatType = 'yesno';
        } else if (ret.NODE_TYPE.toUpperCase().indexOf('TRUE_FALSE') != -1) {
            formatType = 'truefalse';
        } else if (ret.NODE_TYPE.toUpperCase().indexOf('LOOKUP') != -1) {
            formatType = 'lookup';

        } else if (ret.NODE_TYPE.toUpperCase().indexOf('CHECKBOX') != -1) {
            formatType = 'checkbox';

        } else if (
            ret.NODE_TYPE.toUpperCase().indexOf('INFO') != -1 ||
            ret.NODE_TYPE.toUpperCase().indexOf('TEXT') != -1
        ) {
            formatType = 'text';
        }
    }
    ret.NODE_TYPE_FORMAT = formatType;

    if (ret.NODE_NAME !== null) {


        if (ret.NODE_NAME.indexOf("- ") != -1) {

            let rows = ret.NODE_NAME.split("- ");
            let sortRows = rows.sort(o => o.trim().length);

            ret.NODE_NAME_FIRST_ROW = sortRows[0];
        } else if (ret.NODE_NAME.indexOf("\n") != -1) {
            ret.NODE_NAME_FIRST_ROW = ret.NODE_NAME.slice(0, ret.NODE_NAME.indexOf("\n"));
        } else if (ret.NODE_NAME.length > THRESHOLD_FORCE_NEWLINE_AT_SPACE_LENGTH) {
            let boforeFirstSpace = ret.NODE_NAME.slice(THRESHOLD_FORCE_NEWLINE_AT_SPACE_FIST_SPACE_LENGTH);
            if (boforeFirstSpace.indexOf(" ") != -1) {
                ret.NODE_NAME_FIRST_ROW = ret.NODE_NAME.slice(0, THRESHOLD_FORCE_NEWLINE_AT_SPACE_FIST_SPACE_LENGTH + boforeFirstSpace.indexOf(" "));

                ret.NODE_NAME_FORCED_NEWLINES = ret.NODE_NAME_FIRST_ROW
                    + '\\n'
                    + ret.NODE_NAME.slice(ret.NODE_NAME_FIRST_ROW.length + 1 /* + the space*/);

            } else {
                if (ret.NODE_NAME.length > THRESHOLD_FORCE_NEWLINE_FORCE_LENGTH) {
                    ret.NODE_NAME_FIRST_ROW = ret.NODE_NAME.slice(0, THRESHOLD_FORCE_NEWLINE_FORCE_LENGTH);

                    ret.NODE_NAME_FORCED_NEWLINES = ret.NODE_NAME_FIRST_ROW
                        + '-\\n'
                        + ret.NODE_NAME.slice(ret.NODE_NAME_FIRST_ROW.length);

                } else {
                    ret.NODE_NAME_FIRST_ROW = ret.NODE_NAME; //in fact, is already this value;
                }
            }
        }
    }

    return ret;
}


export function Get_Val_For_PGrid_FromObj(obj) {

    let ret = {};

    let retVal = null;
    let retValStr = null;

    retVal = lodash_get(obj, "Val", undefined);
    retValStr = lodash_get(obj, "ValStr", undefined);

    if (retVal !== undefined) {
        ret.Val = retVal;
    }
    if (retValStr !== undefined) {
        ret.ValStr = retValStr;
    }

    return ret;
}

export function FormatAddClassOf(currentFormat, addClass) {

    let ret = currentFormat;

    if (!currentFormat) {
        //Empty, then just add
        ret = addClass;
    } else {
        if (currentFormat.indexOf(addClass) == -1) {
            ret = `${addClass},${ret}`;
        }
    }

    return ret;
}

export function FormatRemoveClassOf(currentFormat, removeClass) {

    let ret = currentFormat;

    if (currentFormat) {
        if (currentFormat.indexOf(removeClass) != -1) { //Remove any occurances of pg-writable

            // ret = currentFormat.split(',').filter(o => o != removeClass).join(',');
            let currentFormats = currentFormat.split(',');
            for (let i = currentFormats.length; i >= 0; i--) {
                if (currentFormats[i] == removeClass) {
                    currentFormats.splice(i, 1);
                }
            }
            ret = currentFormats.join(',');
        }
    }

    return ret;
}

export function isFormula(inHotCellVal) {
    return (typeof inHotCellVal === 'string' && inHotCellVal.indexOf("=") == 0)
}

export function getFormula(pcell) {

    let ret = null;
    if (pcell != null && "Formula" in pcell) {
        ret = pcell.Formula;
    }
    return ret;
}

export function addOrUpdateToHotCellsToUpdate(hotCellsToUpdate, newY, newX, newVal, currentHotData = null) {

    // if (newY == 13 && newX == 18) {
    //     let foo = "balkjflsdak";
    // }

    let foundCurrent = hotCellsToUpdate.find(o => {
        // let [oy, ox, oval] = o; // .split(":");
        return o[0] == newY && o[1] == newX;
    });
    if (foundCurrent) {
        let [fy, fx, fval] = foundCurrent;

        //Only update if other value
        if (fval != newVal) {
            window.PGridClientDebugMode >= 2 && console.warn(`addOrUpdateToHotCellsToUpdate() fval != refValue - will update`);
            hotCellsToUpdate = hotCellsToUpdate.filter(q => q[0] == newY && q[1] == newX);
        }

    } else {
        hotCellsToUpdate.push([newY, newX, newVal]);
    }
}

export default {
    expandIfNeeded,
    expandHOTIfNeeded,
    extractDimNodeData,
    FormatAddClassOf,
    FormatRemoveClassOf,
    Get_PGridCell_JSON,
    Get_PGridCell_Value,
    Get_Val_For_FormulaParser,
    Get_Val_For_PGrid_FromObj,
    getHotValue,
    getHotValue2,
    getFormula,
    GetPCellValue_FromHOTValue,
    hotTableValueEscape,
    indexOfDSProp,
    InputBar_ConvertPGridCellForEdit,
    Insert_Calculated_Cell,
    insertRowAt,
    MaybieCopyObj,
    merge_PCellValue,
    mergeCells,
    pgridCellHasValue,
    isFormula,
    addOrUpdateToHotCellsToUpdate,
    /* ---- REV 2 below ---- */
    ECellMerge,

}