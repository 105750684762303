var initialReadOnly = false;

export function initState_get(w) {

    return {
        globalErrorMessage: null,
        hotRef: null,
        pgridfiltersRef: null,
        hotSettings: {
            data: [],
            outsideClickDeselects: false,  //Will also disable when clicking the input bar
            spanCells: [],
            // rowHeaders: false,
            // colHeaders: false,
            rowHeaderWidth: null, //Null == auto calculate
            readOnly: initialReadOnly,
            width: '100%',
            autoWrapRow: false,
            autoWrapCol: false,
            LRHotSettings: [],
            DisableEvents: false,
            pgHiddenColumns: [],       // almost same as this.wot.wtSettings.settings.columnWidth but true = 0 /false = others
            _nonReactives: [
                'data',
                'outsideClickDeselects',
                'spanCells',
                'rowHeaders',
                'colHeaders',
                'readOnly',
                'width',
                'autoWrapRow',
                'autoWrapCol',
                'LRHotSettings',
                'DisableEvents',
                'OFFpgridLRCoordinatesHints'
            ],
            //viewportRenderingOffset: ['auto', 100],  WARNING 'auto' or low row offset may render a bit strange when scrolling(!)  //[65536, 65536], Default [ 'auto', 'auto' ]
            viewportRenderingOffset: ['auto', 65536], //Column must be 65536, as there is something wrong with the 
            autoSize: [{ syncLimit: '40%' }, { syncLimit: '40%' }],
            disableVisualSelection: false,
            pgScrollFactorY: 0.10,
            pgScrollFactorX: 1.0,
            pgScrollScrollWaitMS: 25,
        },
        pgridSettings: {
            viewMode: 'Default',
            window_PGridClientDebugMode: 0,
            window_PGridPerformanceMode: true, /*Ignore warnings to right -> */  /* WARNING #1! If true viewportRenderingOffset must be hight to load whole report */ /*Warning #2! seems not to be reliable when navigating back (with jump to past cell) */ /* WARNING #3! If not viewportRenderingOffset is set to render whole, scroll down to botton, then up again (a view height + 10 rows). Then if you enter data, the row will "jump" */
            window_PGridPerformanceMode_ScrollDelay: true,
            window_PGridPerformanceMode_Heights: true,
            headers_Hidden: false,
            contextMenu: [ 'row_below','row_above','remove_row' ],
            isCustomerAdmin: false,
            PGridTableEditMode: false,
            PGridTableShowHiddenMode: false,
            PGridTableDebugMode_HasBeenActive: false,
            fixedColumnsLeft: null,
            inputReadOnly: initialReadOnly,
            showDefEditViewMode: false,
            isLoading: true,
            IsLoadingDisabled: false,
            isSaving: false,
            isLoadingUntilRedraw: false,
            optimizeForLargData: false,
            isCellEditing: false,
            isApplyGridChange: false,
            hasNeverLoadedFilters: true,
            hasUnsavedFactChangesForUrl: null,
            attestRequired: false,
            attestStatusOK: false,
            calculateFormulas: false,
            calculateFormulasInProgress: 0,
            cellsThatAreReferenced: {},
            cellsThatAreReferenced_Inv: {},
            cellsThatAreReferencingLinkedRangeOverlays: null,
            cellLookups: {}, //hierarchy lookup for cells
            calcOrderCache: null,
            DisableEvents: true, //Initial disabled
            DisableEvents_Filters: false,
            DisableEventsSetDataAtCellNTimes: 0,
            showPanelOnSelect: true,
            showPanel: false,
            showCssPanel: false,
            delayedAfterChangeMiliseconds: 100, //1500, //Will not use commits
            delayedAfterChangeActionTimeout: -1, //Will not use commits
            delayedAfterChangeChanges: [], //Will not use commits
            delayedAfter_BeforeSaveFact_Miliseconds: 0.25 * 1000,
            // delayedAfter_Save_Miliseconds: 0.25 * 1000,
            // delayedAfter_AfterSaveFact_Miliseconds: 1.5 * 1000, //Must be smaller than MIMICA_PGRID_CACHE_TTL
            defaultFractionDigits: [0, 0],
            scrollbarSizeHorizontal: (typeof (w) == 'object' && w.navigator && w.navigator.userAgent.indexOf("Firefox") > 0) ? 17 : 8, //Firefox does not support smaller scrollbar
            scrollbarSizeVertical: (typeof (w) == 'object' && w.navigator && w.navigator.userAgent.indexOf("Firefox") > 0) ? 17 : 8, //Firefox does not support smaller scrollbar
            numClearsToZero: true,
            initOfLastQuery_Url: null,
            initOfLastQuery_Timestamp: null,
            inputBar_Hidden: false, //true, //FUB false,
            inputBar_Hidden_Restore: null,
            inputBar_Enabled: false,
            inputBar_HasFocus: false,
            inputBar_inputRef: null,
            inputBar_HasFocusCellJumpBack: false,
            inputBar_HasFocusCellJumpBack_CursorPos: -1,
            inputBar_HasFocusCellJumpBack_delayedMiliseconds: 100,
            inputBar_IsFormulaBuildingMode: false,
            inputBar_SelectCellVal: '',
            inputBar_BarMode: 'small',
            inputBar_DirtyHighlights: {},
            filterAndGuidanceList_RowsMode: 'filterrows1',
            LRPGridSettingsCollection: {},
            language: "en", //FUB "sv",
            locale: "en-US",// FUB "sv-SE",
            lastChangesLocked: false,
            lastKeyStrokeShiftWasPressed: false,
            fixedRowsTopMaxCompensation: 15,
            // fixedColumnsLeft: null,
            // fixedRowsTop: null,
            scollbackMemory: {},
            IsMissingFilterSelectionCount: 0,

            //viewportRenderingOffset: ['auto', 100],  WARNING 'auto' or low row offset may render a bit strange when scrolling(!)  //[65536, 65536], Default [ 'auto', 'auto' ]
            // viewportRenderingOffset: ['auto', 65536], //['auto',

            // viewportRenderingOffset: [50, 50], //This seems not to give any strange cell rendering when scrolling
            // autoSize: [{ syncLimit: '70%' }, { syncLimit: '70%' }], //[{ syncLimit: 50 }, { syncLimit: 30 }], //[{ syncLimit: '30%' }, { syncLimit: '30%' }], //, default: [ {syncLimit: 500}, {syncLimit: 50}]

            _nonReactives: [
                'inputReadOnly',
                'showDefEditViewMode',
                'hasNeverLoadedFilters',
                'hasUnsavedFactChangesForUrl',
                'attestRequired',
                'attestStatusOK',
                'calculateFormulas',
                'calculateFormulasInProgress',
                'cellsThatAreReferenced',
                'cellsThatAreReferenced_Inv',
                'cellsThatAreReferencingLinkedRangeOverlays',
                'cellLookups',
                'calcOrderCache',
                'DisableEventsSetDataAtCellNTimes',
                'delayedAfterChangeMiliseconds',
                'delayedAfterChangeActionTimeout',
                'initOfLastQuery_Url',
                'initOfLastQuery_Timestamp',
                'inputBar_HasFocus',
                'inputBar_HasFocusCellJumpBack',
                'inputBar_HasFocusCellJumpBack_CursorPos',
                'inputBar_HasFocusCellJumpBack_delayedMiliseconds',
                'inputBar_DirtyHighlights',
                'LRPGridSettingsCollection',
                'lastKeyStrokeShiftWasPressed',
            ],
        },

        // pgridDataRowsDBCache: null,
        LowestRightNonHiddenLR: { y: -1, x: -1 },
        LowestRightNonHiddenCell: { y: -1, x: -1 },
        LowestRightOptimization: true,
        HideBackButton: false,
        pgridData: null, //Data from DB
        pgridDim: null, //Dim data from DB
        pgridFilter: {
            Filters: [],
            ApplyMode: 'Auto_All_Selected', //available are: Auto_All_Selected
            Hidden: false,
            // ShowPublisButton: false,
            // ShowSaveButton: 'auto',
            // EnableSaveButton: true,
            DynamicButtons: defaultDynamicButtons(),
            Guidances: [], //GuidanceHtml: null,
            Guidances_MinWidth: null, //90, //Default
            Guidances_MaxWidth: null, //180, //Default
            Guidances_Color: 'rgb(255,0,15)', //Default
            GuidanceHtml: null, //Legacy
            GuidanceHtmlMinWidth: 100, //Legacy

            SelectedFilterValues: ''
        },
        mimicaAppSettings: { closeDrawerOnClick: false },
        pgridDataDynContent: null, //The "working" pgrid data
        pgridDataUndo: null, //The Ctrl+z "undo" pgrid data
        pgridLRCoordinatesHints: [], //The "working" pgrid data
        pgridVars: null, //Static varibels like tab, grid, customer id's
        lastSelected: null,
        lastSelectedWasA_selectionchange: null,
        lastClickedCell: null,
        secondRenderRequest: 0,
        // pgridPlugins: {
        //     openTable: { changedRows: {} }
        // },
        aceCssEditorRef: null,
        _nonReactives: [
            'LowestRightNonHiddenLR',
            'LowestRightNonHiddenCell',
            'pgridData',
            'pgridDim',
            'pgridDataDynContent',
            'pgridDataUndo',
            'pgridLRCoordinatesHints',
            'lastClickedCell',
            'lastClickedCell',
            '',
        ],

    }
}

function defaultDynamicButtons() {

    return [
        {
            "Title_DYN": {
                "op": "translate-key",
                "path": "saveButton"
            },
            "Type": "FactSave",
            "EventArgs": {
                "ButtonPushed": "SAVED",
                "ForceFilterRefresh": true,
                "SkipEventBeforeSaveFact": true
            },
            "ConfirmMessage": null,
            "Visible": false,
            "Visible_DYN":
            {
                "op": "sequence",
                "tuples": [
                    {
                        "op": "get",
                        "path": "State.pgridVars.GridName"
                    },
                    {
                        "op": "contains",
                        "pattern": "Slutkostnadsprognos"
                    },
                    {
                        "op": "invert"
                    }
                ]
            },

            "Disabled_WHEN_NO_CHANGES": true,
            "Disabled_DYN": {
                "op": "sequence",
                "tuples": [
                    {
                        "op": "get",
                        "path": "State.pgridFilter.Filters"
                    },
                    {
                        "op": "find",
                        "path": [
                            "title",
                            "Version"
                        ]
                    },
                    {
                        "op": "get",
                        "path": "filterValue.text"
                    },
                    {
                        "op": "test",
                        "pattern": "(PUBLISHED|PUBLICERAD)"
                    }
                ]
            }
        },
        {
            "Title_DYN": {
                "op": "translate-key",
                "path": "saveButton"
            },
            "Type": "FactSave",
            "EventArgs": {
                "ButtonPushed": "PUBLISHED",
                "ForceGridReload": true
            },
            "ConfirmMessage_DYN": {
                "op": "concat",
                "tuples": [
                    "Är du säker på att du vill spara en ny version?"
                ]
            },
            "Visible_DYN": {
                "op": "any",
                "tuples": [
                    {
                        "op": "sequence",
                        "tuples": [
                            {
                                "op": "get",
                                "path": "State.pgridFilter.Filters"
                            },
                            {
                                "op": "find",
                                "path": [
                                    "title",
                                    "Version"
                                ]
                            },
                            {
                                "op": "get",
                                "path": "filterValue.text"
                            },
                            {
                                "op": "test",
                                "pattern": "PUBLISHED"
                            }
                        ]
                    },
                    {
                        "op": "sequence",
                        "tuples": [
                            {
                                "op": "get",
                                "path": "State.pgridVars.GridName"
                            },
                            {
                                "op": "contains",
                                "pattern": "Slutkostnadsprognos"
                            }
                        ]
                    }
                ]
            },
            "Disabled": false,
            "Disabled_DYN": {
                "op": "any",
                "tuples": [
                    {
                        "Description": "Disable published ",
                        "op": "sequence",
                        "tuples": [
                            {
                                "op": "get",
                                "path": "State.pgridFilter.Filters"
                            },
                            {
                                "op": "until-true",
                                "tuples": [
                                    {
                                        "op": "find",
                                        "path": [
                                            "dimName",
                                            "FilterDim_GetUsercalcVersionHierarchy"
                                        ],
                                        "save": "MyVersionFilter"
                                    },
                                    {
                                        "op": "find",
                                        "path": [
                                            "dimName",
                                            "FilterDim_GetPlanningVersionHierarchy"
                                        ],
                                        "save": "MyVersionFilter"
                                    }
                                ]
                            },
                            {
                                "op": "get",
                                "load": "MyVersionFilter",
                                "path": "filterValue.text",
                                "save": "MySelectedValueText"
                            },
                            {
                                "op": "contains",
                                "load": "MySelectedValueText",
                                "pattern": "PUBLISHED",
                                "save": "MySelectedValueIsPuliched"
                            }
                        ]
                    },
                    {
                        "op": "sequence",
                        "Description": "Is not admin for the specific CK",
                        "tuples": [
                            {
                                "op": "get",
                                "path": "State.pgridSettings.isCustomerAdmin"
                            },
                            {
                                "op": "parse-boolean"
                            },
                            {
                                "op": "invert"
                            }
                        ]
                    }
                ]
            }
        },
        {
            "Title_DYN": {
                "op": "translate-key",
                "path": "deleteButton"
            },
            "Type": "FactSave",
            "EventArgs": {
                "ButtonPushed": "DELETED",
                "ForceGridReload": true
            },
            "RequireValidation": false,
            "ConfirmMessage_DYN": {
                "op": "concat",
                "tuples": [
                    "Är du säker på att du vill ta bort beräkningen ",
                    {
                        "op": "sequence",
                        "tuples": [
                            {
                                "op": "get",
                                "path": "State.pgridFilter.Filters"
                            },
                            {
                                "op": "find",
                                "path": [
                                    "title",
                                    "Version"
                                ]
                            },
                            {
                                "op": "get",
                                "path": "filterValue.text"
                            },
                            {
                                "op": "replace",
                                "pattern": "SAVED ",
                                "with": ""
                            }
                        ]
                    },
                    " ?."
                ]
            },
            "Visible_DYN": {
                "op": "sequence",
                "tuples": [
                    {
                        "op": "get",
                        "path": "State.pgridVars.GridName"
                    },
                    {
                        "op": "equals",
                        "value": "5-minutaren"
                    }
                ]
            }
        },
        {
            "Title_DYN": {
                "op": "translate-key",
                "path": "reloadButton"
            },
            "Type": "GridReload",
            "EventArgs": {
                "ButtonPushed": "RELOAD",
                "ForceFilterRefresh": false,
                "ForceGridReload": true
            },
            "ConfirmMessage": null,
            "Visible": false
        }

    ]
}


export default {
    initState_get
}