import { toNumber } from '../../helper/number';
import { ERROR_DIV_ZERO, ERROR_VALUE_DIV_ZERO, ERROR_VALUE } from '../../error';

export const SYMBOL = '/';

export default function func(first, ...rest) {
  //Evaluate operator DIVIDE
  const result = rest.reduce((acc, value) => acc / toNumber(value), toNumber(first));

  //Evaluate operator DIVIDE
  if (result === Infinity) {
    throw Error(ERROR_DIV_ZERO);
  }
  if (Number.isNaN(result)) {
    throw Error(ERROR_VALUE_DIV_ZERO);
  }

  return result;
}

func.SYMBOL = SYMBOL;
