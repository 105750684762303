import { PGridLR_Base } from './pgridLR_Base.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import PGridLR_FreeCell_Schema from './PGridLR_FreeCell-schema.json';
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';
import pgridCell, { expandIfNeeded, indexOfDSProp, pgridCellGetOnlyValOrValStr } from './pgridCell.js';
import PGridUtils from './pgridUtils.js';
import PGridMatrices from './pgridMatrices.js'

import { extractLabel } from '../formula-parser/helper/cell.js';
import pgridMatrices from './pgridMatrices.js';

import { FillInRenderOptions } from './pgridLR_Common.js';


export class PGridLR_FreeCell extends PGridLR_Base {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}

    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        this.schema = PGridLR_FreeCell_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        this.AxisDefMap = {
            COLUMNS: { type: 'COLUMNS', mapsTo: 'Columns', IsTableAxis: true },  //'Column' is a property in the linked range definition
            // ROWS: { type: 'ROWS', mapsTo: 'Rows' },
            FACTS: { type: 'FACTS', mapsTo: 'Facts' }
        }

        this.Templates = lodash_get(this.linkedRange, "Templates", null);
        this.FreeCellMap = lodash_get(this.linkedRange, "FreeCellMap", null);
        this.xStart = null;
        this.yStart = null;

        if (!this.Templates) {
            this.Templates =
                //Default template for inserting dim metadata for facts load/save
                [
                    {
                        "Name": "Default",
                        "Description": "Default template",
                        "Sort": 1,
                        "PrimaryTemplate": true,
                        "Rule": {
                            "If_Axis": "Columns",
                            "If_Level": "any"
                        },
                        "Insert": {
                            "LevelPosition": "FreeCellMapOneLeft",
                            "InNewRow": false,
                            "Merge": false,
                            "CellTemplate": {
                                "ValStr": "<<NODE_NAME>>"
                            }
                        }
                    },
                    {
                        "Name": "WriteCell1",
                        "Description": "Write cell",
                        "Sort": 2,
                        "PrimaryTemplate": false,
                        "Rule": {
                            "If_Axis": "RowsAndColumns",
                            "If_Level": "any"
                        },
                        "Insert": {
                            "LevelPosition": "FreeCellMap",
                            "InNewRow": false,
                            "Merge": true,
                            "CellTemplate": {
                                "Format": "writable",
                                "Meta": {
                                    "DimVal": "<<NODE_VALUE>>",
                                    "DimName": "<<PIVOTHIERARARCHY_NAME>>",
                                    "LRName": "<<LINKEDRANGE_NAME>>"
                                }
                            }
                        }
                    }
                ]
        }

        this.Overlay = lodash_get(this.linkedRange, "Overlay", null);


        if (!this.FreeCellMap) {
            this.FreeCellMap = [];
        }
    }

    whoAmI() {
        return 'I am a PGridLR_FreeCell'
    }

    // FUB May be same as in EPivot, extract to a function?
    Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {

        let ret = Array();

        let addPivotHierarchy = function (pH, overrides) {

            const type = "Pivot_Hierarcy"

            let pivotHiers = dataSetDefinitions.filter(dsd => {
                return dsd.Type === "Pivot_Hierarcy" && dsd.Name == pH
            });

            if (pivotHiers.length != 1) {
                throw new Error(`Could not find one Pivot_Hierarcy type dataset of name '${pH}' for type '${type}'`);
            }

            let pivotHier = pivotHiers[0];

            ret.push({ DSName: pH, DSDef: pivotHier, DSOverrides: overrides, LRName: me.name });
        }

        try {

            let cols = me.linkedRange[me.AxisDefMap.COLUMNS.mapsTo];
            // let rows = me.linkedRange[me.AxisDefMap.ROWS.mapsTo];

            let axisObjs = [cols /*, rows*/];

            axisObjs.forEach(axisDef => {
                if (lodash_get(axisDef, "PivotHierarchy", null) != null || lodash_get(axisDef, "DataSetName", null) != null) {

                    let dsName = axisDef.PivotHierarchy || axisDef.DataSetName;

                    let overrides = lodash_get(axisDef, "Override", null);

                    addPivotHierarchy(dsName, overrides);
                } else {
                    throw new Error(`Missing "PivotHierarchy" in LR ${me.name}`);
                }
            });


        } catch (err) {
            throw new Error(`PGridLR_FreeCell:Phase1_Get_DataSetDefs() Could not get dataset with name: ${err.message || err}`);
        }

        return ret;
    }


    // FUB May be same as in EPivot, extract to a function?
    Phase2_Load_DimDataInAxisData(pgridDim) {

        try {
            const axisToLoad = Object.keys(this.AxisDefMap).filter(x => this.AxisDefMap[x].IsTableAxis).map((x) => { return { key: x, value: this.AxisDefMap[x] } });

            for (let i = 0; i < axisToLoad.length; i++) { //COLUMNS & ROWS
                let atl = axisToLoad[i];
                // axisToLoad.forEach(function (atl) {

                window.PGridClientDebugMode >= 3 && console.debug(`Phase2_Load_DimDataInAxisData() Processing ${atl.key}`)

                let axisDef = this.linkedRange[atl.value.mapsTo];


                if (!("PivotHierarchy" in axisDef)) {
                    throw new Error(`No dimensions specified for ${atl.value.mapsTo}`)
                }

                let overrides = lodash_get(axisDef, "Override", null);

                let dsName = axisDef.PivotHierarchy;

                if (overrides) {
                    dsName = `${dsName}_OVERRIDES_FROM_${this.name}`;
                }

                if (dsName === null) {
                    throw new Error("No PivotHierarchy name fund");
                }

                let pgridDimDS = lodash_get(pgridDim, dsName, null);

                if (pgridDimDS == null) {
                    throw new Error("DataSet not in result");
                }

                let metaDataDS = lodash_get(pgridDim, `${dsName}_(¤HierMetadata¤)`, null);
                if (metaDataDS === null) {
                    throw new Error("No PivotHierarchy metedata fund");
                }

                this.axisMeta[atl.key] = lodash_clone(metaDataDS);
                delete this.axisMeta[atl.key].Dimensions; //Redundant

                let dsLevelsMetadataSorted = metaDataDS.Dimensions.sort((x_left, x_right) => (x_left.Level > x_right.Level ? 1 : -1));
                //Filter out disabled
                dsLevelsMetadataSorted = dsLevelsMetadataSorted.filter(x => (('Disabled' in x) ? !x.Disabled : true));
                const dsLevelsMetadataSorted_length = dsLevelsMetadataSorted.length;
                for (let l = 0; l < dsLevelsMetadataSorted_length; l++) {
                    // const pgridDSDefLvl = lodash_clone(dsLevelsMetadataSorted[l]);
                    const pgridDSDefLvl = dsLevelsMetadataSorted[l];

                    let dimAxisRes = {
                        Name: `${pgridDSDefLvl.Name}`,//`${dsName}_LEVEL_${mdLvl.Level}_${mdLvl.Name}`,
                        Header: {
                            Id: null,
                            Value: null,
                            Name: null,
                            Description: null
                        },
                        // Parent: null,
                        // ParentChildInxLookup: null, //Fill here
                        DataSet: [] //Fill here
                    }

                    //Add parent def to axis result
                    if ("Parent" in pgridDSDefLvl) {
                        dimAxisRes.Parent = pgridDSDefLvl.Parent;
                    }

                    // Populate Header
                    let countCol = 0;
                    Object.keys(pgridDSDefLvl.SrcColMapping).forEach(function (prop) {
                        let val = pgridDSDefLvl.SrcColMapping[prop];
                        dimAxisRes.Header[prop] = { SrcCol: val, ColIdx: countCol++ };
                    });


                    for (let w = 0; w < pgridDimDS.length; w++) {

                        if (w === 0) {
                            continue;
                        }

                        let newRow = [];


                        let filterOutRow = false;

                        if ("Filter" in pgridDSDefLvl) {

                            filterOutRow = true;

                            // pgridDSDefLvl.Filter.forEach(function (filt) {
                            for (let v = 0, n = pgridDSDefLvl.Filter.length; v < n; v++) {
                                let filt = pgridDSDefLvl.Filter[v];

                                // console.debug("Filtering");
                                if (("SrcCol" in filt)) {
                                    let filtCol = filt.SrcCol;

                                    if (("SrcValEqual" in filt)) {
                                        let filtVar = filt.SrcValEqual;
                                        let rowColInd = indexOfDSProp(pgridDimDS, filtCol);
                                        let rowColVal = pgridDimDS[w][rowColInd];

                                        if (rowColVal == filtVar) {
                                            filterOutRow = false;
                                        }
                                    }
                                    else if (("SrcValNotEqual" in filt)) {
                                        let filtVar = filt.SrcValEqual;
                                        let rowColInd = indexOfDSProp(pgridDimDS, filtCol);
                                        let rowColVal = pgridDimDS[w][rowColInd];

                                        if (rowColVal != filtVar) {
                                            filterOutRow = false;
                                        }
                                    }
                                }
                            }
                        }

                        if (filterOutRow == false) {
                            Object.keys(pgridDSDefLvl.SrcColMapping).forEach(function (prop) {
                                let idxDSCol = indexOfDSProp(pgridDimDS, pgridDSDefLvl.SrcColMapping[prop]);
                                let valDSCell = pgridDimDS[w][idxDSCol];
                                newRow.push(valDSCell);
                            });

                            dimAxisRes.DataSet.push(newRow);
                        }
                    }

                    if (!(atl.key in this.axisData)) {
                        this.axisData[atl.key] = [];
                    }
                    this.axisData[atl.key].push(dimAxisRes);
                }
            }

            for (let i = 0; i < axisToLoad.length; i++) { //COLUMNS & ROWS
                let atl = axisToLoad[i];


                let currentAxisMeta = this.axisMeta[atl.key];
                let currentAxisData = this.axisData[atl.key];

                let doParentChildCals = false;

                let hierarchyType = lodash_get(currentAxisMeta, "HierarchyType", null);
                let relationType = lodash_get(currentAxisMeta, "RelationType", null);



                if (/* hierarchyType == "Tree" && */relationType == "ParentChild") {
                    doParentChildCals = true;
                }

                //Assume InlineHierarchy (if not exsist) the do do_parentChildPreCals (obsolete?)
                if (JSON.toString(lodash_get(currentAxisMeta, "", {})) == "{}") {
                    doParentChildCals = true;
                }

                if (doParentChildCals) {


                    //Start at 1, as first has no parents
                    for (let lvlZ = 1; lvlZ < currentAxisData.length; lvlZ++) { // Dim levels 0 to x, where 0 is first (level 1) (leftmost, or upper in grid labels)


                        let axisDataCurrent = null;
                        let axisDataParent = null;


                        axisDataCurrent = currentAxisData[lvlZ];

                        if (lvlZ - 1 >= 0) {
                            axisDataParent = currentAxisData[lvlZ - 1];
                        } else {
                            throw new Error(`Cannot calculate parent relation when no parent dim exits`);
                        }


                        let parTargColName = lodash_get(axisDataCurrent, "Parent.TargetCol", "Id");
                        let mySrcColName = lodash_get(axisDataCurrent, "Parent.SrcCol", "Id");

                        if ((!parTargColName) || (!mySrcColName)) {
                            console.debug(`Skipping parentchildCalc for level ${axisDataAxLvl.Name} as has no "Parent.SrcCol"`);
                        }
                        else {

                            let parTargCol = lodash_get(axisDataParent, `Header[${parTargColName}]`, null);
                            let mySrcCol = lodash_get(axisDataCurrent, `Header[${mySrcColName}]`, null);


                            if (!parTargCol) {
                                throw new Error(`parTargCol == null`);
                            }
                            if (!mySrcCol) {
                                throw new Error(`mySrcCol == null`);
                            }


                            axisDataCurrent.LookupIdx_ParentToMe = {};

                            //Iterate current
                            for (let currRow = 0, currDSLen = axisDataCurrent.DataSet.length; currRow < currDSLen; currRow++) {

                                let currDSRow = lodash_get(axisDataCurrent, `DataSet[${currRow}]`, null);
                                if (currDSRow == null) {
                                    throw new Error(`Could not lookup row from dataset`);
                                }

                                let mySrcColVal = lodash_get(currDSRow, `[${mySrcCol.ColIdx}]`, "<No_ColIdx_Match>");

                                if (mySrcColVal == "<No_ColIdx_Match>") {
                                    throw new Error(`Could not find a col index match in currDSRow`);
                                }


                                let parMatchId = null;


                                for (let parRow = 0, parDSLen = axisDataParent.DataSet.length; parRow < parDSLen; parRow++) {


                                    let parDSRow = axisDataParent.DataSet[parRow];

                                    let parTargColVal = parDSRow[parTargCol.ColIdx];

                                    if (parTargColVal == null) {
                                        continue;
                                    }

                                    if (parTargColVal == "<No_ColIdx_Match>") {
                                        throw new Error(`Could not find a col index match in parDSRow`);
                                    }


                                    if (parTargColVal == mySrcColVal) {
                                        if (parMatchId != null) {
                                            throw new Error("multiple parent node matched, this is now allowed");
                                        }
                                        parMatchId = parRow;
                                    }
                                }

                                //Found parent child link, now store it in lookup
                                if (parMatchId != null) {

                                    if (!(parMatchId in axisDataCurrent.LookupIdx_ParentToMe)) {
                                        axisDataCurrent.LookupIdx_ParentToMe[parMatchId] = [];
                                    }

                                    axisDataCurrent.LookupIdx_ParentToMe[parMatchId].push(currRow)
                                }

                            }
                        }
                    }
                }
            } // end for atl = COLUMNS & ROWS

            //Dont return anything (have insted populated axisData)
            return null;

        } catch (Phase2_Load_DimDataInAxisData_error) {
            let errMsg = `> Phase2_Load_DimDataInAxisData_error: ${this.name} got exception: ${Phase2_Load_DimDataInAxisData_error.stack || Phase2_Load_DimDataInAxisData_error.message || Phase2_Load_DimDataInAxisData_error}`;
            throw new Error(errMsg)
        }
    }


    Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {

        this.y = pgridMatrices.Get_LRsFromTable(pgridTableDyn, { onlyThisLR: this.name, source: `pgridLR_EPivot.js Phase3_Insert_DynDimData() name: ${this.name}` }).y;

        let ret = { pgridTableDyn: null, addedRowsOffset: null, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }

        //FreeCell only needs the Columns axis dimension
        if (lodash_get(pgridAxis, "mapsTo", null) != "Columns") {
            //Not an dimension to care about
            return pgridTableDyn;
        }

        // let pgridTableDynCopy =  JSON.parse(JSON.stringify(pgridTableDyn));
        let pgridTableDynCopy = pgridTableDyn;

        try {

            let currentAxis = this.axisData[pgridAxis.type]

            let firstLvlWithData = null;
            let firstLvlWithDataZ = 0;

            for (let i = 0; i < currentAxis.length; i++) {

                if (firstLvlWithData == null) {
                    let testLvl = currentAxis[i];
                    if (testLvl.DataSet.length > 0) {
                        firstLvlWithData = testLvl;
                        firstLvlWithDataZ = i;
                    }
                }
            }

            let accSpan = 0;
            let accAddedRowsOffset = 0; //Always zero for FreeCell

            let usedMaxOffsetY = -1;
            let usedMaxOffsetX = -1;


            let addedRowsTracking = {}

            if (firstLvlWithData == null) {
                throw new Error("Found no data for " + pgridAxis.type);
            }

            let shiftCellsDownOffset = {}

            let shiftRowsDownTracker = {}

            for (let rootLvlNodesCount = 0; rootLvlNodesCount < firstLvlWithData.DataSet.length; rootLvlNodesCount++) {

                let shiftCellsDownOffset2 = {}

                let nextOffsetY = 0;
                let nextOffsetX = 0;

                if (pgridAxis.type == "COLUMNS") {
                    nextOffsetY = 0;
                    if (this.type == "FreeCell") {
                        nextOffsetX = 0;
                    } else {
                        nextOffsetX = 0 + accSpan;
                    }
                }
                if (pgridAxis.type == "ROWS") {
                    nextOffsetY = 0 + accSpan;
                    nextOffsetX = 0;
                }
                //  await sleep(10);
                let retSTN = await PGridUtils.spinningTreeNet(
                    state
                    , this
                    , pgridTableDynCopy
                    , currentAxis
                    , pgridAxis
                    , firstLvlWithDataZ
                    , rootLvlNodesCount
                    , false //AppendVirtualRootNode
                    , nextOffsetY
                    , nextOffsetX
                    , addedRowsTracking
                    , shiftCellsDownOffset
                    , shiftCellsDownOffset2
                    , shiftRowsDownTracker
                    , []
                    , addedRowsOffsetLR
                );

                accSpan += retSTN.span;
                accAddedRowsOffset += retSTN.addedRowsOffset;


                usedMaxOffsetY = Math.max(usedMaxOffsetY, retSTN.usedMaxOffsetY);
                usedMaxOffsetX = Math.max(usedMaxOffsetX, retSTN.usedMaxOffsetX);
            }

            this.xLength = Math.max(this.xLength, usedMaxOffsetX + 1);
            this.yLength = Math.max(this.yLength, usedMaxOffsetY + 1);


            ret.pgridTableDyn = pgridTableDynCopy;
            ret.addedRowsOffset += accAddedRowsOffset

            ret.lrIsHidden = this.lrIsHidden;
            ret.lowerRightCoord.y = this.y + this.yLength;
            ret.lowerRightCoord.x = this.x + this.xLength;

        } catch (Phase3_Insert_DynDimData_error) {
            let errMsg = `> ${this.type}: Phase3_Insert_DynDimData_error: ${Phase3_Insert_DynDimData_error.message || Phase3_Insert_DynDimData_error}`;
            throw new Error(errMsg);
            this.errors.push(JSON.parse(JSON.stringify(Phase3_Insert_DynDimData_error.toString())));
        }

        return ret;
    }


    Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {

        // let pgridTableCopy = JSON.parse(JSON.stringify(pgridTable));
        // this.y = PGridUtils.findLRCoordinate(pgridTable, this.name, `pgridLR_FreeCell.js Phase4_Insert_Metadata()`).y;
        this.y = PGridMatrices.Get_LRsFromTable(pgridTable, { onlyThisLR: this.name, source: `pgridLR_EPivot.js Phase4_Insert_Metadata()` }).y;

        let DSCol = this.axisData[lr.AxisDefMap.COLUMNS.type][0].Name;

        let DSColHierarchyId = null;

        try {
            DSColHierarchyId = this.axisData[lr.AxisDefMap.COLUMNS.type][0].DataSet[0][this.axisData[lr.AxisDefMap.COLUMNS.type][0].Header.HierarchyId.ColIdx];
        } catch {
            console.debug(`DSColHierarchyId was not found`);
        }

        try {

            let insert_cell = {};

            lodash_set(insert_cell, "Meta.Save.DSCol", DSCol);
            lodash_set(insert_cell, "Meta.Load.DSCol", DSCol);
            lodash_set(insert_cell, "Meta.Load.DimSizeY", this.yLength);
            lodash_set(insert_cell, "Meta.Load.DimSizeX", this.xLength);

            lodash_set(insert_cell, "Meta.Save.DSColHierarchyId", DSColHierarchyId);

            pgridTable[this.y][this.x] = lodash_merge({}, pgridTable[this.y][this.x], insert_cell);

        } catch (err) {
            let errMsg = `> Phase4_Insert_Metadata_error: ${err.message || err}`;
            throw new Error(errMsg);
        }

        return pgridTable;
    }

    Phase6_Generate_FactRange(pgridDataDyn, factData, lang = null) {

        let factRangeData = null;

        this.y = PGridMatrices.Get_LRsFromTable(pgridDataDyn, { onlyThisLR: this.name, source: `pgridLR_FreeCell.js Phase6_Generate_FactRange() name: ${this.name}` }).y;

        //Make a copy
        // let pgridDataDynCopy = JSON.parse(JSON.stringify(pgridDataDyn));

        try {

            const factDataParsed = new Array();

            //Step 1 - parse fact data from db

            // let dimLookupTable = factData.DimLookup.sort((a, b) => {
            //     if (a.Id > b.Id) {
            //         return 1;
            //     }
            //     if (a.Id < b.Id) {
            //         return -1;
            //     }
            //     return 0;
            // }).map(o => o.DimName);

            let dimLookupTable = {};
            if ("DimLookup" in factData) {
                factData.DimLookup.forEach(o => {
                    dimLookupTable[o.Id] = o.DimName;
                });
            }


            if ("Rows" in factData) {
                //There are fact rows                            

                for (let k = 0; k < factData.Rows.length; k++) {
                    let fItem = factData.Rows[k];

                    let newData = {};


                    if ("V" /*"Val"*/ in fItem) {
                        // newData.Val = fItem.Val;
                        newData.Val = fItem.V;
                    }
                    if ("S" /*"ValStr"*/ in fItem) {
                        // newData.ValStr = fItem.ValStr;
                        newData.ValStr = fItem.S;
                    }


                    // let cellXYKey = JSON.parse(fItem.CellKey);
                    let cellXYKey = fItem.K;  //Handles the new non-stringified format


                    //Insert DimLookup values
                    for (let i = 0; i < cellXYKey.length; i++) {
                        cellXYKey[i].N = dimLookupTable[cellXYKey[i].D];
                    }


                    if (cellXYKey != null && Array.isArray(cellXYKey) && cellXYKey.length > 0) {

                        if (cellXYKey != null && length in cellXYKey) {
                        }
                        else {
                            false && console.warn("This clause gave false before");
                        }

                        for (let y = 0; y < cellXYKey.length; y++) {
                            //Only store dims not in filter
                            let keyId = null;
                            if (isNaN(cellXYKey[y].W)) {
                                keyId = cellXYKey[y].W;
                            }
                            else {
                                keyId = Number(cellXYKey[y].W);
                            }

                            if (!("Key" in newData)) {
                                newData.Key = {};
                            }
                            newData.Key[cellXYKey[y].N] = keyId;
                        }
                        factDataParsed.push(newData);
                    } else {
                        console.warn("Empty cellXYKey");
                    }
                }
            }

            const pgridDataLRCell = pgridDataDyn[this.y][this.x];

            //Only load if has Load meta in LRCell
            if (lodash_get(pgridDataLRCell, "Meta.Load", null) != null) {

                //Get stored LR dimension sizes
                this.yLength = pgridDataLRCell.Meta.Load.DimSizeY;
                this.xLength = pgridDataLRCell.Meta.Load.DimSizeX;

                //Step 2 - Initialize 2D data structure

                // factRangeData = [...Array(this.yLength)].map(x => Array(this.xLength).fill({})); // https://stackoverflow.com/questions/4852017/how-to-initialize-an-arrays-length-in-javascript
                factRangeData = [];

                for (let newY = 0; newY < this.yLength; newY++) {
                    let newRow = [];
                    for (let newX = 0; newX < this.xLength; newX++) {
                        newRow.push(undefined);
                    }
                    factRangeData.push(newRow);
                }

                let lookupX = {};
                let lookupY = {};

                // Step 2,3 was removed after optimization  
                // Step 4 fill in data
                for (let i = 0; i < Object.keys(this.FreeCellMap).length; i++) {
                    let fcAddr = this.FreeCellMap[Object.keys(this.FreeCellMap)[i]];

                    let readmetaY = null;
                    let readmetaX = null;
                    let insertY = null;
                    let insertX = null;

                    let regEx = /R(-?\d+)?C(-?\d+)/;
                    let match = regEx.exec(fcAddr);

                    if (match != null && match.length > 1) {

                        insertY = Number(match[1]);
                        insertX = Number(match[2]);
                        readmetaY = this.y + Number(match[1]);
                        readmetaX = this.x + Number(match[2]);

                    } else {

                        let [fcRow, fcCol] = extractLabel(fcAddr);
                        insertY = fcRow.index - this.y;
                        insertX = fcCol.index - this.x;
                        readmetaY = fcRow.index;
                        readmetaX = fcCol.index;

                    }

                    let fCell = pgridDataDyn[readmetaY][readmetaX];

                    //FUB Special case for FreeCell, if Meta.Writable is string "null", then Meta.Save.Writable = true
                    let dimValWritable = lodash_get(fCell, "Meta.Writable", null);
                    if (dimValWritable === "null") {
                        dimValWritable = true;
                    }

                    if (dimValWritable !== null) {
                        lodash_set(fCell, "Meta.Save.Writable", dimValWritable); //This write direct to pgridDataDyn
                    }

                    /* This part modifies pgridDataDyn directly */
                    //Hackish solution adopted from OpenTable
                    let validType = null;
                    validType = lodash_get(fCell, "Meta.Validator.Type", null);
                    if (validType == null) {
                        validType = lodash_get(fCell, "Meta.Save.Validator.Type", null); //This format is soon obsolete
                    }
                    if (validType) {
                        let updated = FillInRenderOptions(fCell, validType, lang);

                        if (updated) {
                            // This is not required as it is updated by reference --> pgridDataDyn[readmetaY][readmetaX] = fCell;
                        }
                    }

                    /* This part modifies generated the return facts i pcell format */

                    if (lodash_get(fCell, "Meta.DimVal", undefined) === undefined) {
                        console.warn(`PgridLR_FreeCell() Missing "Meta.DimVal" for ${fcAddr}`);
                    } else {
                        //Not optimized
                        let lrName = lodash_get(fCell, "Meta.LRName", null);
                        let dimVal = lodash_get(fCell, "Meta.DimVal", null);
                        let dimName = lodash_get(fCell, "Meta.DimName", null);
                        if (lrName == this.name) {

                            let factDataParsed_length = Object.keys(factDataParsed).length;
                            for (let j = 0; j < factDataParsed_length; j++) {

                                let factD = factDataParsed[Object.keys(factDataParsed)[j]];

                                let factDKey = lodash_get(factD, `Key`, null);

                                if (Object.keys(factDKey).length != 1) {
                                    //Skipping, as this cannot be freecell facts, as it not only one dimension
                                } else {
                                    if (dimName in factDKey) {
                                        //LR Match!
                                        let factDKeyVal = factDKey[dimName];
                                        if (factDKeyVal == dimVal) {
                                            //Found match! insert value
                                            expandIfNeeded(factRangeData, insertY, insertY);

                                            /***** PCell is created here */
                                            let cellValue = pgridCell.Get_Val_For_PGrid_FromObj(factD);
                                            factRangeData[insertY][insertX] = cellValue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else {
                console.warn("Missing Meta.Load");
            }
        } catch (ex) {
            console.error(`Get_FactsForLR_error - ${ex.message}, stack trace - ${ex.stack}`);
        }

        return factRangeData;

    }


    Phase11_Collect_FactRange(pgridDataIn, pgridDim) {

        let GetFreeCellMapCellCoordVals = function (pgridDataIn, lr = null, onlyWritable = true) {
            let ret = [];

            let lrFreeCellMap_Keys = Object.keys(lr.FreeCellMap);
            for (let i = 0; i < lrFreeCellMap_Keys.length; i++) {

                let addr = lr.FreeCellMap[lrFreeCellMap_Keys[i]];

                let srcCell =
                    [
                        {
                            index: parseInt(lr.y),
                            isAbsolute: false
                        },
                        {
                            index: parseInt(lr.x),
                            isAbsolute: false
                        },
                    ];


                let cellCorrd = extractLabel(addr, srcCell);

                let y = cellCorrd[0].index;
                let x = cellCorrd[1].index;


                // FUU const lrCell = JSON.parse(JSON.stringify(pgridDataIn[y][x]));
                const lrCell = lodash_clone(pgridDataIn[y][x]);
                lrCell.y = y;
                lrCell.x = x;

                const lrCellValue = pgridCellGetOnlyValOrValStr(lrCell);
                // Used by Convert_FactRowsDataJSONToDBFactData
                lrCellValue.Meta = {
                    Save: {
                        DimValX: pgridDataIn[y][x].Meta.DimVal
                        /*.Meta.Save.DimValX , DimValY: pgridDataIn[y][x].Meta.Save.DimValY */
                    }
                };

                // let metaWritable = lodash_get(pgridDataIn[y][x], "Meta.Writable", null);
                let metaSaveWritable = lodash_get(pgridDataIn[y][x], "Meta.Save.Writable", null)


                let doSaveValue = false;

                let isExplicitSave = false;

                if (String(metaSaveWritable) == "true") {
                    isExplicitSave = true;
                }

                if (isExplicitSave) {
                    doSaveValue = true;

                }

                if (doSaveValue /*onlyWritable*/) {
                    // if (lodash_get(pgridDataIn[y][x], "Meta.Writable", false) == true) {
                    // linkedRangesFactsCollections[factsCollectName].linkedRangesFacts[lr.name].facts.push(lrCellValue)
                    // VS keeps inserting spaces!!  ret.push([`${y}: ${x} `, lrCellValue]);
                    ret.push([y + ":" + x, lrCellValue]);
                    // }
                } //else don´t add 

            }

            return ret;
        }



        let ret_lrFacts = null;

        let lr = this;

        let DSCol = lodash_get(lr, "adjacentCells.selfCell.Meta.Save.DSCol", null); //Set in Phase4_Insert_Metadata
        let DSColHierarchyId = lodash_get(lr, "adjacentCells.selfCell.Meta.Save.DSColHierarchyId", null);


        //Skipping row axis stuff
        ret_lrFacts = {
            name: lr.name, type: lr.type, factDims: {
                column: DSCol,
                colHierarchyId: DSColHierarchyId
            }
            , facts: []
        }



        let lrCoordFacts = GetFreeCellMapCellCoordVals(pgridDataIn, lr, true);

        let lrFacts_ValSaveMeta = lrCoordFacts.map(o => o[1]);

        console.debug(`Adding ${lrFacts_ValSaveMeta.length} facts from LR: ${lr.name}`);

        ret_lrFacts.facts = lrFacts_ValSaveMeta;

        return ret_lrFacts;
    }
}