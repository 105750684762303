var lang = {
    login: {
        office365: "Logga in med Office 365"
    },
    main: {
        title: "Business Vision | RE(a)ACT"
    },
    error: {
        notfound: "Kunde inte hitta sidan",
        userExists: "Användaren finns redan!"
    },
    settings: {
        save: "SPARA",
        delete: "TA BORT",
        apply: "APPLICERA",
        discard: "ÅNGRA",
        fileDrop: "Släpp fil här",
        upload: "Ladda upp fil",
        workOrSchoolAccount: "Work or school account",
        microsoftAccount: "Microsoft account",
        statusEnabled: "Aktiv",
        statusDisabled: "Inaktiv",
        addRole: "Skapa ny roll",
        cancel: "Avbryt",
        noticeboard: "Notiser",
        aboutmimica: "Om MIMICA",
        toggledrawer: "Toggla sidmeny",
        customerselector: "Välj kund",
        topbar: {
            userSwitch: "Användare",
            roleSwitch: "Roller"
        },
        sidepanel: {
            assignedMembersTitle: "MEDLEMMAR",
            notAssignedMembersTitle: "ICKE TILLDELADE",
            assignedRolesTitle: "ANVÄNDARENS ROLLER",
            notAssignedRolesTitle: "EJ TILLDELADE ROLLER",
            userName: "Namn",
            userEmail: "Email",
            userWorkPhone: "Jobbtelefon",
            userMobilePhone: "Mobiltelefon",
            userAdd: "Lägg till användare",
            groupAccessSuccess: "Anslutningen till gruppen lyckades",
            groupAccessFailed: "Anslutningen till gruppen misslyckades"
        },
        table: {
            permissions: {
                headerRoleName: "ROLLNAMN"
            },
            roles: {
                headerName: "NAMN",
                headerLevelDescription: "BESKRIVNING",
                headerAssignedUsers: "TILLDELADE ANVÄNDARE"
            },
            users: {
                headerName: "NAMN",
                headerEmail: "EMAIL",
                headerRoles: "ROLL(ER)"
            },
            usermgt: {
                headerName: "NAMN",
                headerEmail: "EMAIL",
                headerLoginType: "KONTOTYP"
            },
            reaact: {
                headerService: "SERVICE",
                headerApp: "APPLIKATION",
                headerTab: "TAB",
                headerName: "RAPPORTNAMN"
            }
        },
        report: {
            name: "Rapportnamn",
            desc: "Rapportbeskrivning",
            group: "Power BI Group ID",
            report: "Power BI Report ID",
            save: "Spara",
            choosePbiTenant: "Väj Power BI Tenant",
            orCreateNewPbiTenant: "eller skapa ny",
            pbiTenantName: "Power BI Tenant namn",
            savePbiTenant: "Spara Power BI Tenant"
        },
        documents: {
            deleteConfirmationTitle: "Är du säker?",
            deleteConfirmationContent: " objekt kommer att tas bort.",
            moreInfo: "Visa mer information",
            table: {
                headerFilename: "Filnamn",
                headerCreated: "Skapad",
                headerLastModified: "Ändrad",
                headerDescription: "Beskrivning",
                headerUploadedBy: "Uppladdad av"
            },
            buttons: {
                refresh: "Uppdatera",
                upload: "Ladda upp",
                delete: "Ta bort"
            },
            textbox: {
                selectedFile: "Vald fil"
            }
        }
    }
}

module.exports = {
    lang
}