import Vue from "vue/dist/vue.js";
import Vuex from 'vuex';
import PGridUtils, { IsNumeric } from './pgridUtils.js';
import PGridHandlers from './pgridHandlers.js';

import PGridCell from './pgridCell.js';
import PGridBackendAccess from './pgridBackendAccess.js';
import JSONEditor from 'jsoneditor';

import PGridMatrices from './pgridMatrices.js';
import PGridCss from './pgridCssEditor.js';
// import lodash, { get, set } from 'lodash'
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash'

import { MaybieCopyObj } from './pgridCell.js';
import PGridHT from './pgridHT.js';
import { translateFromKey } from './pgridLanguage';

import cycle from '../../lib/cycle/cycle.js'


import PGridDynExtract from './pgridDynExtract.js'
// const PGridDynExtract = require("./pgridDynExtract");

import { columnLabelToIndex } from '../formula-parser/helper/cell.js';

// import cycle from '../../lib/cycle/cycle.js'

import PgridRootApp from "./pgrid-root-app.vue";
import PgridRootAppOnlyError from "./pgrid-root-app-only-error.vue";
import { initState_get } from './pgridVuexStoreDefaults.js'



Vue.use(Vuex);

let VueSetIfReactive = (state, prop, val) => {
    try {
        let reactive = true;

        if (false) { //Always reactive, for now
            if ('_nonReactives' in state) {
                if (state._nonReactives.indexOf(prop) != -1) {
                    reactive = false;
                }
            }
        }

        if (reactive) {
            // console.log(`VueSetIfReactive() reactive prop: '${prop}'`);

            if (state[prop] === val) {
                window.PGridClientDebugMode >= 4 && console.log(`VueSetIfReactive() reactive prop: '${prop}' same value, skipping`);
            } else {
                Vue.set(state, prop, val);
            }
        } else {
            // console.log(`VueSetIfReactive() non-reac prop: '${prop}'`);
            // state[prop] = Object.freeze(val);
            // state[prop] = Vue.shallowRef(val); //Only in Vue 3
            // Object.defineProperty(val, 'nested', { configurable: false });
            PGridHT.makeNonreactive2(val);
            state[prop] = val;
            // Vue.set(state, prop, val);
            // PGridUtils.makeNonreactive2(state[prop]);
            // Object.defineProperty(state[prop], 'nested', { configurable: false });

        }
    } catch (err) {
        console.error(`VueSetIfReactive() go exception: ${err.message} prop: ${prop}`);
    }
}



let initState = initState_get(window);

// Vue.nonreactive(initState.pgridData);
const state = MaybieCopyObj(initState, 5);

const actions = {

    async Action_Stage_Filter(context, { initTimestamp, initUrl, dontLoadGrid, source }) {

        let ret = { IsMissingFilterSelectionCount: null }

        try {
            window.PGridClientDebugMode >= 1 && console.debug(`StageFilter() ${source} -> Action_Stage_Filter()`);
            context.commit("Mutation_UpdatePGridSettings", { prop: "DisableEvents", val: true, source: `${source} -> Action_Stage_Filter()` });

            if (context.state.pgridSettings.viewMode != "Default") {
                console.debug(`Action_Stage_Filter() skipping fetch`);
            } else {
                window.PGridClientDebugMode >= 2 && console.debug("DEBUG_STEP_3_InitPGrid_Action_PgridDataFetchFromDatabase PRE");
                let dbFetchRet = await context.dispatch("Action_PgridDataFetchFromDatabase", { initTimestamp, pgridVars: context.state.pgridVars, source: `${source} -> Action_Stage_Filter` });
                ret.IsMissingFilterSelectionCount = dbFetchRet.IsMissingFilterSelectionCount;

                window.PGridClientDebugMode >= 2 && console.debug("DEBUG_STEP_3_InitPGrid_Action_PgridDataFetchFromDatabase AFTER");
            }

            if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling filter update as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                return ret;
            }


            if (dontLoadGrid) {
                window.PGridClientDebugMode >= 2 && console.debug("Action_Stage_Filter() only reload filters, not grid");
            } else {

                if (context.state.pgridSettings.viewMode != "Default") {
                    window.PGridClientDebugMode >= 2 && console.debug(`Action_Stage_Filter() skipping filter update`);
                } else {
                    await context.dispatch("Action_PgridFiltersUpdateStates", { source: `${source} -> Action_Stage_Filter()` });
                    window.PGridClientDebugMode >= 2 && console.debug("DEBUG_STEP_4_FiltersUpdateInit");
                }
            }
        }
        catch (err) {
            let errMsg = `> Action_Stage_Filter() exception: ${err.messge || err}`

            console.error(errMsg);


            (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> Action_Stage_Filter()` }) : alert(errMsg);

            throw new Error(errMsg);

        }

        let foo234 = "bar";
        return ret;
        let foo243234 = "bar";
    },
    async Action_Stage_Grid(context, { initTimestamp, initUrl, render = false, source = null }) {
        try {

            if (window.PGridClientDebugMode >= 2) {
                console.debug(`Action_Stage_Grid() source: ${source} render: ${render}`);
            }

            let retHotData_IfInit = null;

            if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Stage_Grid at hotRef reset, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);

                return;
            }

            if (context.state.pgridSettings.viewMode == "Default") {

                false && console.debug("DEBUG_STEP_6-10_Action_InitializeGrid START");

                //  await context.dispatch("Action_ApplyLRPgridCss", { source: `${source} -> Action_Stage_Grid()` });

                retHotData_IfInit = await context.dispatch("Action_InitializeGrid", { initTimestamp, initUrl, source: `${source} -> Action_Stage_Grid()` });

                if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                    window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Stage_Grid at Mutation_UpdatePGridSettings, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                    return;
                }

                context.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEvents', val: false, source: `${source} -> Action_Stage_Grid()` });
                false && console.debug("DEBUG_STEP_6-10_Action_InitializeGrid END");

                if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                    window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Stage_Grid at Action_Redraw, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                    return;
                }
            }


            if (render) {

                let dataToUseOnRender = null;

                if (retHotData_IfInit) {
                    dataToUseOnRender = retHotData_IfInit;
                } else if (lodash_get(context, "hotSettings.data", null)) {
                    dataToUseOnRender = context.hotSettings.data;
                }
                else {
                    let pgridDataHT = PGridUtils.Get_HOTUncalcedDataAndSettings(context, { source: `${source} -> Action_Stage_Grid()` });

                    dataToUseOnRender = pgridDataHT.data;
                }


                // FUB: Called two times; in Handle_Action_Load_FactsFromDBToGrid, for calculation of column sized -- then in Action_Stage_Grid, for hiding rows. This may generate dublicate CSS rules and is sub optimal
                await context.dispatch("Action_ApplyLRPgridCss", { source: `${source} -> Action_Stage_Grid()` });

                // window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #3', PGridVueStore.state.hotRef.getData().length);
                context.commit('Mutation_UpdateHOTSettings', { render: true, prop: 'data', val: dataToUseOnRender, source: `${source} -> Action_Stage_Grid` });
                window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #4', PGridVueStore.state.hotRef.getData().length);

                // console.warn("DISABLED: This redraw is performed after an initial draw, is possible to optimize!");
                // context.dispatch("Action_Redraw", { source: `${source} -> Action_Stage_Grid()` });
            }
        }
        catch (err) {
            let errMsg = `> Action_Stage_Grid() muted exception: ${err.messge || err}`

            console.error(errMsg);
        }
    },
    /**
         */
    async Action_PgridRESET(context, { source, type }) {
        try {


            window.PGridClientDebugMode >= 2 && console.debug("Action_PgridRESET() source: " + source + " type: " + type);

            delete window.colFilterRows; //FUB


            context.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEvents', val: true, source: `${source} -> Action_PgridRESET` });
            context.commit('Mutation_UpdatePGridSettings', { prop: 'hasUnsavedFactChangesForUrl', val: null, source: `${source} -> Action_PgridRESET` });

            context.commit('Mutation_UpdatePGridSettings', { prop: 'isLoading', val: true, source: `${source} -> Action_PgridRESET` });
            context.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_SelectCellVal', val: null, source: `${source} -> Action_PgridRESET` });
            // context.commit('Mutation_UpdatePGridSettings', { prop: 'attestRequired', val: initState.pgridSettings.attestRequired, source: `${source} -> Action_PgridRESET` });
            // context.commit('Mutation_UpdatePGridSettings', { prop: 'attestStatusOK', val: initState.pgridSettings.attestStatusOK, source: `${source} -> Action_PgridRESET` });


            context.commit('Mutation_UpdatePGridSettings', { prop: 'IsMissingFilterSelectionCount', val: initState.pgridSettings.IsMissingFilterSelectionCount, source: `${source} -> Action_PgridRESET` });

            context.commit('Mutation_UpdateRoot', { prop: 'lastSelected', val: null, source: `${source} -> Action_PgridRESET` });

            context.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "set", val: null, source: `${source} -> Action_PgridRESET()` });


            if ('PGridDTC' in window) {
                window.PGridDTC.CallCount = 0;
            }
            window.PGridSpinningTreeCount = 0;
            window.PGridSpinningTreeCountSYNC = 40;
            if (type != "switchTableDebugMode") {
                context.commit('Mutation_UpdatePGridSettings', { prop: 'calcOrderCache', val: null, source: `${source} -> Action_PgridRESET` });
            }

            // if (context.state.hotRef) {
            context.commit('Mutation_UpdateHOTSettings', { prop: 'data', val: null, source: `${source} -> Action_PgridRESET` });
            // }
            if (type == "filter" || type == "methodSwitchViewMode" || type == "switchTableDebugMode") {
                window.PGridClientDebugMode >= 2 && console.debug("Action_PgridRESET() mode: filter or switch");

                // context.commit('Mutation_UpdateHOTSettings', { prop: 'data', val: null, source: `${source} -> Action_PgridRESET` });
                type != "switchTableDebugMode" && context.commit("Mutation_UpdateHOTSettings", { prop: "spanCells", val: [], source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_UpdateHOTSettings', { prop: "LRHotSettings", val: [], source: `${source} -> Action_PgridRESET` });

                context.commit('Mutation_UpdatePGridSettings', { prop: "LRPGridSettingsCollection", val: {}, source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced", val: {}, source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced_Inv", val: {}, source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferencingLinkedRangeOverlays", val: null, source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_UpdatePGridSettings', { prop: "delayedAfterChangeChanges", val: [], source: `${source} -> Action_PgridRESET` });

                if (context.state.pgridSettings.viewMode == "Default") {

                    context.commit('Mutation_UpdateRoot', { prop: 'pgridData', val: null, source: `${source} -> Action_PgridRESET` });
                    // context.commit('Mutation_UpdateRoot', { prop: 'pgridDataRowsDBCache', val: null, source: `${source} -> Action_PgridRESET` });
                }

                context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: [], source: `${source} -> Action_PgridRESET` });

                if (type != "switchTableDebugMode") {
                    context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: null, source: `${source} -> Action_PgridRESET` });
                }
                context.commit('Mutation_UpdateRoot', { prop: 'lastSelected', val: null, source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_UpdateRoot', { prop: 'LowestRightNonHiddenLR', val: null, source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_UpdateRoot', { prop: 'LowestRightNonHiddenCell', val: null, source: `${source} -> Action_PgridRESET` });
                type != "switchTableDebugMode" && context.commit('Mutation_UpdateRoot', { prop: 'LowestRightOptimization', val: true, source: `${source} -> Action_PgridRESET` });

            } else {
                console.debug("Action_PgridRESET() mode: normal (or actually un-normal!)");
                // context.commit('Mutation_UpdatePGridSettings', { prop: 'headers_Hidden', val: initState.pgridSettings.headers_Hidden, source: `${source} -> Action_PgridRESET` });
                context.commit('Mutation_Reset_GlobalState', { ignoreList: ['customCss', 'aceCssEditorRef'] });
            }

            if (context.state.pgridVars == null) {
                window.PGridClientDebugMode >= 3 && console.log("Action_PgridRESET() context.state.pgridVars == null: skipping Action_ApplyLRPgridCss")
            } else {
                await context.dispatch("Action_ApplyLRPgridCss", { source: `${source} -> Action_PgridRESET()` });
            }
            // await context.dispatch("Action_ApplyLRPgridCss", { source: `${source} -> Action_PgridRESET()` });
        }
        catch (err) {
            let errMsg = `> Action_PgridRESET() muted exception: ${err.stack || err.messge || err}`

            console.error(errMsg);
        }
    },


    /**
     * @requires state.pgridVars
     */
    async Action_PgridDataFetchFromDatabase(context, { initTimestamp, pgridVars, source }) {
        try {

            let ret = { IsMissingFilterSelectionCount: null }

            if (pgridVars == null) {
                throw `Action_PgridDataFetchFromDatabase() Error: No pgridVars.source: ${source}`
            } else {

                var pgridDataRows = null;
                var pgridDim = null;

                let responseQueryPGridData;
                try {


                    // Sanitize unnessary variable data before sending
                    let pgridVarsSanitized = JSON.parse(JSON.stringify(pgridVars));
                    delete pgridVarsSanitized.GridDefinition;
                    delete pgridVarsSanitized.GridDescription;
                    delete pgridVarsSanitized.GridTitle;
                    delete pgridVarsSanitized.GridDescriptionSQL;

                    responseQueryPGridData = await PGridBackendAccess.QueryPGridData(pgridVarsSanitized, { source: `${source} -> Action_PgridDataFetchFromDatabase` });

                    ret.IsMissingFilterSelectionCount = responseQueryPGridData.PGridGetGridDataAndDataSets[0].IsMissingFilterSelectionCount;

                    if (responseQueryPGridData.PGridGetGridDataAndDataSets == null) {
                        throw "Invalid response, PGridGetGridDataAndDataSets should not be null "
                    }


                    if (responseQueryPGridData.PGridGetGridDataAndDataSets.length != 1) {
                        throw "Invalid response, length should be 1 row, is " + responseQueryPGridData.PGridGetGridDataAndDataSets.length
                    }


                    if (responseQueryPGridData.PGridGetGridDataAndDataSets[0].Error) {

                        if (ret.IsMissingFilterSelectionCount) {
                            window.PGridClientDebugMode >= 2 && console.debug(`Action_PgridDataFetchFromDatabase() Ignoring DB query error as is missing filter selections: ${responseQueryPGridData.PGridGetGridDataAndDataSets[0].Error}`);
                        }
                        else {
                            throw new Error("Response error: " + responseQueryPGridData.PGridGetGridDataAndDataSets[0].Error);
                        }

                    }

                    try {
                        pgridDataRows = JSON.parse(responseQueryPGridData.PGridGetGridDataAndDataSets[0].GridDataJSON);

                        if (pgridDataRows == null) {
                            throw `Missing PGridDataSets where [Type] == 'Template_Store' or missing GridData?`;
                        }

                    } catch (pgridRowData_error) {
                        throw "Error! Faild to parse grid data: " + pgridRowData_error;
                    }

                    try {
                        pgridDim = JSON.parse(responseQueryPGridData.PGridGetGridDataAndDataSets[0].GridDataSetsJSON);
                        if (pgridDataRows == null) {
                            throw `[mimPG].[PGridDatasetDefinition_Get] didn't return any rows`;
                        }
                    } catch (pgridDimData_error) {
                        throw "Error! Faild to parse dimension data: " + pgridDimData_error;
                    }


                    if (context.state.pgridSettings.initOfLastQuery_Timestamp == initTimestamp) {

                        let newVirtualQueryString = responseQueryPGridData.PGridGetGridDataAndDataSets[0].VirtualQueryString;

                        if ((newVirtualQueryString != null && pgridVars.VirtualQueryString != newVirtualQueryString)) {

                            // new URL() https://dmitripavlutin.com/parse-url-javascript/
                            let gotoUrl = new URL(window.location.pathname + newVirtualQueryString, window.location.href);

                            let url_newVirtualQueryString = gotoUrl.search;
                            // console.log("Action_PgridDataFetchFromDatabase() window.history.pushState: " + gotoUrl.href);
                            // window.history.pushState({ path: gotoUrl.href }, "", gotoUrl.href);

                            PGridUtils.HistoryPushStateIfNotSame(gotoUrl.href, `Action_PgridDataFetchFromDatabase()`);

                            context.commit('Mutation_UpdatePGridVars', { prop: 'VirtualQueryString', val: url_newVirtualQueryString, source: 'Action_PgridDataFetchFromDatabase' });

                            PGridUtils.updateTabLinks(url_newVirtualQueryString, `Action_PgridDataFetchFromDatabase`);

                        }
                    } else {
                        console.debug(`Action_PgridDataFetchFromDatabase recived old request, ignoring it`);
                    }


                } catch (err) {
                    let errMsg = `> responseQueryPGridData got exception: ${err.message || err}`;
                    throw new Error(errMsg);
                }

                //Reset data
                // context.commit('Mutation_UpdateRoot', { prop: 'pgridDataRowsDBCache', val: pgridDataRows, source: 'Action_PgridDataFetchFromDatabase' });

                //Grid data

                // if (ret.IsMissingFilterSelectionCount == 0) {
                let pgridData = PGridUtils.Convert_PGridRowsToPgridData(pgridDataRows);
                context.commit('Mutation_UpdateRoot', { prop: 'pgridData', val: pgridData, source: 'Action_PgridDataFetchFromDatabase' });


                context.commit('Mutation_UpdatePGridSettings', { prop: 'isCustomerAdmin', val: responseQueryPGridData.PGridGetGridDataAndDataSets[0].IsCustomerAdmin > 0, source: 'Action_PgridDataFetchFromDatabase' });

                //Dimensions
                context.commit('Mutation_UpdateRoot', { prop: 'pgridDim', val: pgridDim, source: 'Action_PgridDataFetchFromDatabase' });
                // }
            }
            return ret;
        }
        catch (err) {
            let errMsg = `> Action_PgridDataFetchFromDatabase() got exception: ${err.messge || err}`
            throw new Error(errMsg);
        }
    },

    async action_SavePgridDataToDatabase(context, { initTimestamp }) {
        try {
            console.log("Vuex Action: action_SavePgridDataToDatabase");
            let pgridVars = context.state.pgridVars;

            context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: true, source: `action_SavePgridDataToDatabase()` });

            let isDynamic = PGridUtils.Is_DynamicMode(state);
            let pgridData = null;
            if (isDynamic) {
                pgridData = context.state.pgridDataDynContent;
            } else {
                pgridData = context.state.pgridData;
            }

            let pgridDataRows = PGridUtils.Convert_PgridDataToPGridRows(pgridData, isDynamic, context.state);
            let responseSavePGridData;
            try {
                responseSavePGridData = await PGridBackendAccess.SavePGridData(pgridVars, pgridDataRows);

                //Everything seems fine, then reload ui from database
                // await context.dispatch('Action_PgridDataFetchFromDatabase', { initTimestamp, pgridVars });
                // context.commit('Action_PgridFiltersUpdateStates', { source: 'action_SavePgridDataToDatabase' });

            } catch (err) {
                let errMsg = `> action_SavePgridDataToDatabase responseSavePGridData got exception: ${err.message || err}`
                throw errMsg;
            } finally {
                context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `action_SavePgridDataToDatabase()` });
            }
        } catch (err) {
            let errMsg = `> action_SavePgridDataToDatabase() got exception: ${err.message || err}`;
            let source = "unkonwn";
            console.error(errMsg);
            // alert(errMsg);

            (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> action_SavePgridDataToDatabase()` }) : alert(errMsg);
            context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `action_SavePgridDataToDatabase()` });
            context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: false, source: `action_SavePgridDataToDatabase()` });

        }
    },


    async Action_SavePgridFactToDatabase(context, { eventArgs, source }) {
        try {
            console.debug("Vuex Action: action_SavePgridFactToDatabase");

            if (PGridUtils.Is_DynamicMode(context.state)) {

                let pgridData = await PGridUtils.Get_CurrentPGridData_ASYNC({ context, source: "action_SavePgridFactToDatabase" });

                context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: true, source: `action_SavePgridFactToDatabase()` });

                //Filters should be available here?

                // Sanitize unnessary variable data before sending
                let pgridVarsSanitized = JSON.parse(JSON.stringify(context.state.pgridVars));
                delete pgridVarsSanitized.GridDefinition;
                delete pgridVarsSanitized.GridDescription;
                delete pgridVarsSanitized.GridTitle;
                delete pgridVarsSanitized.GridDescriptionSQL;

                await PGridMatrices.SavePgridFactToDatabase(context, pgridData, context.state.pgridFilter, pgridVarsSanitized, context.state.pgridDim, eventArgs);

                context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `action_SavePgridFactToDatabase()` });
            }
            else {
                throw new Error("Can only save in Default mode");
            }
        } catch (err1) {

            let errMsg = `${translateFromKey('saveFactError', context.state.pgridSettings.language)}\n\n${err1}`;
            console.warn(errMsg);
            // alert(errMsg);
            (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> action_SavePgridFactToDatabase()` }) : alert(errMsg);
            context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `action_SavePgridFactToDatabase() got exception` });

            throw new Error(errMsg);
        }

        // Don't indicate loaded here do this later insted, after re-loading facts from db.
        // context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `...())` });
    },
    async action_SavePgridCssToDatabase(context) {
        try {
            console.debug("Vuex Action: action_SavePgridCssToDatabase");

            let variables = { CustomerKey: context.state.pgridVars["CustomerKey"], Css: context.state.customCss };


            let responseSavePGridCss;
            try {
                if (PGridCss.ValidateCss(variables.Css)) {
                    responseSavePGridCss = await PGridBackendAccess.SavePGridCss(variables);
                }

                if (responseSavePGridCss == null) {
                    //OK ?
                }
            } catch (responseSavePGridCss_error) {
                throw 'responseSavePGridCss_error: ' + responseSavePGridCss_error.toString();
            }


        } catch (action_SavePgridCssToDatabase_error) {
            // alert("action_SavePgridCssToDatabase_error: " + action_SavePgridCssToDatabase_error)
            let errMsg = "action_SavePgridCssToDatabase_error: " + action_SavePgridCssToDatabase_error;
            let source = "unknown";
            (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> action_SavePgridCssToDatabase()` }) : alert(errMsg);
        }
    },
    async action_ApplyPgridCss(context) {
        try {
            console.debug("Vuex Action: action_ApplyPgridCss");

            let idApplyPgridCss = "PGridCustomerCss-" + context.state.pgridVars.CustomerKey;
            let classApplyPgridCss = "PGridCustomerCss-class";
            if (!PGridCss.CustomCssIsLoaded(idApplyPgridCss)) {
                let css = context.state.customCss;
                PGridCss.UpdatePGridStyle(idApplyPgridCss, classApplyPgridCss, css);
            }
        } catch (action_SavePgridCssToDatabase_error) {
            // alert("action_SavePgridCssToDatabase_error: " + action_SavePgridCssToDatabase_error)
            let errMsg = "action_SavePgridCssToDatabase_error: " + action_SavePgridCssToDatabase_error;
            let source = "unknown";
            (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> action_ApplyPgridCss()` }) : alert(errMsg);

        }
    },
    async Action_ApplyLRPgridCss(context, opt = { source: "unknown" }) {
        try {

            const HOT_GRID_HTML_TABLE_2_HOT_TABLE_OFFSET = 2;

            false && console.debug(`Vuex Action: Action_ApplyLRPgridCss source: ${opt.source} -> Action_ApplyLRPgridCss()`);

            let lrPGridSettings = lodash_get(context, "state.pgridSettings.LRPGridSettingsCollection", {});
            let lrSettCollCss = "";

            let lrPGridSettingsItem = lrPGridSettings;


            let pgHiddenColumns = [];


            /* Currently only accepts inputBar_Hidden, headers_Hidden, and Columns: { "A": { "Hidden": true } } */
            for (let lrPGridSettingsItemKey in lrPGridSettingsItem) {

                // if (lrPGridSettingsItemKey == "inputBar_Hidden" && lrPGridSettingsItem[lrPGridSettingsItemKey] == true) {
                //     context.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_Hidden', val: lrPGridSettingsItem[lrPGridSettingsItemKey], source: `${opt.source} -> Action_ApplyLRPgridCss()` });
                //     context.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_BarMode', val: 'none', source: `${opt.source} -> Action_ApplyLRPgridCss()` });
                //     // Assume only called on Init, then Redraw is not required here
                //     //  PGridHT.Redraw(context, { source: `${opt.source} -> Action_ApplyLRPgridCss()` });
                // }
                // else if (lrPGridSettingsItemKey == "headers_Hidden") {
                //     context.commit('Mutation_UpdatePGridSettings', { prop: 'headers_Hidden', val: lrPGridSettingsItem[lrPGridSettingsItemKey], source: `${opt.source} -> Action_ApplyLRPgridCss()` });
                // }
                // else 
                if (lrPGridSettingsItemKey == "Columns")

                /*
                    Column style handling, that inserts CSS targeting "colgroup > col"
                */ {
                    let lrPGridSettingsCol = lodash_get(lrPGridSettingsItem, "Columns", {});

                    let lrPGridSettingsCol_Keys = Object.keys(lrPGridSettingsCol);

                    let hasFixWidth = false;
                    for (let i = 0; i < lrPGridSettingsCol_Keys.length; i++) {

                        let htmlColIdx = -1;
                        let tableColIdx = -1;
                        let colIdx_Key = lrPGridSettingsCol_Keys[i];

                        if (PGridUtils.IsNumeric(colIdx_Key)) {
                            tableColIdx = parseInt(colIdx_Key);
                            if (tableColIdx == -1) {
                                throw new Error(`Action_ApplyLRPgridCss() Number(): Illegal column key: ${colIdx_Key}`)
                            }
                        } else {
                            tableColIdx = columnLabelToIndex(colIdx_Key);
                            if (tableColIdx == -1) {
                                throw new Error(`Action_ApplyLRPgridCss() columnLabelToIndex(): Illegal column key: ${colIdx_Key}`)
                            }
                        }

                        htmlColIdx = tableColIdx + HOT_GRID_HTML_TABLE_2_HOT_TABLE_OFFSET;

                        // "LRPGridSettings": {
                        //     "Columns": {
                        //         "E": {
                        //             "FixWidth": 200
                        //         }
                        //     }
                        // },



                        let fixedColWidth = lodash_get(lrPGridSettingsCol[colIdx_Key], "FixWidth", null);
                        if (fixedColWidth) {
                            hasFixWidth = true;
                            let cssVal = "";
                            if (PGridUtils.IsNumeric(fixedColWidth)) {

                                if (fixedColWidth == "width") {
                                    cssVal += `${fixedColWidth}px !important;
                                    `
                                }

                                cssVal += `${fixedColWidth}px !important;
                                `
                            } else {
                                cssVal += fixedColWidth;
                            }

                            let genCss = `width: ${cssVal};
`;
                            // lrColCssArr.push(genCss);
                        }




                        // "LRPGridSettings": {
                        //     "Columns": {
                        //         "F": {
                        //             "MinWidth": 30,
                        //             "MinWidthRepeatChar": ".",  //Default '-'
                        //         }
                        //     }
                        // },

                        let strToDefineSmallestWidth = '';
                        let MinWidth_Ypos = 0;

                        let MinWidth = lodash_get(lrPGridSettingsCol[colIdx_Key], "MinWidth", null);
                        if (MinWidth) {

                            let MinWidthRepeatChar = lodash_get(lrPGridSettingsCol[colIdx_Key], "MinWidthRepeatChar", "-");

                            MinWidth_Ypos = lodash_get(lrPGridSettingsCol[colIdx_Key], "MinWidthYPos", 0);

                            if (PGridUtils.IsNumeric(MinWidth)) {
                                for (let i = 0; i < MinWidth; i++) {
                                    strToDefineSmallestWidth += MinWidthRepeatChar;
                                }
                            }
                        }



                        // "LRPGridSettings": {
                        //     "Columns": {
                        //         "F": {
                        //             "Hidden": true
                        //         }
                        //     }
                        // },



                        let hiddenCol = lodash_get(lrPGridSettingsCol, `[${colIdx_Key}].Hidden`, false);

                        if (hiddenCol) {
                            let genCss = `visibility: collapse;`

                            //
                            // lrColCssArr.push(genCss);
                            //
                            let currentLen = pgHiddenColumns.length;
                            if (currentLen <= tableColIdx) {
                                PGridUtils.resizeArray(pgHiddenColumns, tableColIdx + 1, false);
                            }
                            pgHiddenColumns[tableColIdx] = true;
                        }


                        // "LRPGridSettings": {
                        //     "Columns": {
                        //         "F": {
                        //             "MinText": "Foobarbaz"
                        //         }
                        //     }
                        // },

                        let RemoveBelowColumnSizer = lodash_get(lrPGridSettingsCol[colIdx_Key], "RemoveBelowColumnSizer", false);


                        let MinText = lodash_get(lrPGridSettingsCol[colIdx_Key], "MinText", null);
                        if (MinText) {
                            strToDefineSmallestWidth = String(MinText);
                        }


                        if (strToDefineSmallestWidth) {
                            let pgridDataBuffer = await PGridUtils.Get_CurrentPGridData_ASYNC({ context, source: `${opt.source} -> Action_ApplyLRPgridCss()` });

                            let currCell = pgridDataBuffer[MinWidth_Ypos][tableColIdx];

                            let insertCell = {
                                "ValStr": strToDefineSmallestWidth,
                                "Format": "pcell-hidden",
                                "Meta": {
                                    "Validator": {
                                        "type": "columnsizer"
                                    },
                                    "References": [`LRPGridSettings: ${colIdx_Key}`]
                                }
                            }

                            let mergedCell = PGridCell.mergeCells(currCell, insertCell, ["MergeFormat"]);

                            pgridDataBuffer[MinWidth_Ypos][tableColIdx] = mergedCell;

                            /* Not sure why we have this? */
                            if (RemoveBelowColumnSizer) {

                                let y_foundColumnSizer = -1

                                for (let y = 1; y < pgridDataBuffer.length && y_foundColumnSizer == -1; y++) {

                                    let examineCell = pgridDataBuffer[y][tableColIdx];
                                    let valType = lodash_get(examineCell, "Meta.Validator.type", null);
                                    if (valType == "columnsizer") {
                                        y_foundColumnSizer = y;
                                    }
                                }

                                if (y_foundColumnSizer != -1) {
                                    false && console.debug(`Action_ApplyLRPgridCss() Found columnsizer to remove on row: ${y_foundColumnSizer} col: ${tableColIdx}`)

                                    let clearColumnSizerCell = {
                                        "ValStr": "",
                                        "Meta": {
                                            "References": [`LRPGridSettings: RemoveBelowColumnSizer ${colIdx_Key}`]
                                        }
                                    }
                                    let currCell2 = pgridDataBuffer[y_foundColumnSizer][tableColIdx];
                                    let mergedCell2 = PGridCell.mergeCells(currCell2, clearColumnSizerCell);
                                    pgridDataBuffer[y_foundColumnSizer][tableColIdx] = mergedCell2;
                                }

                            }

                            context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `${opt.source} -> Action_ApplyLRPgridCss()` });
                        }





                        // "LRPGridSettings": {
                        //     "Columns": {
                        //       "9": {
                        //         "InsertCSS": [
                        //           "width: 600px !important;"
                        //         ]
                        //       }
                        //     }
                        //   },

                        let lrColCssArr = [];

                        let lrColCssArrInsertCss = lodash_get(lrPGridSettingsCol[colIdx_Key], "InsertCSS", null);

                        if (lrColCssArrInsertCss) {
                            lrColCssArr = lrColCssArr.concat(lrColCssArrInsertCss);
                        }

                        if (lrColCssArr.length > 0) {

                            lrColCssArr.forEach(lrColCss => {

                                lrSettCollCss += `
                            .ht_clone_top_left_corner > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > colgroup:nth-child(1) > col:nth-child(${htmlColIdx}),
                            .ht_clone_top > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > colgroup:nth-child(1) > col:nth-child(${htmlColIdx}),
                            .ht_master > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > colgroup:nth-child(1) > col:nth-child(${htmlColIdx}),
                            .ht_clone_left > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > colgroup:nth-child(1) > col:nth-child(${htmlColIdx}) {
                                ${lrColCss}
                            }

                            `;
                            });
                        }


                        /*
                            By default table has 'white-space: pre-line;' (see handsontable.css)
                            Here every column gets 'white-space: nowrap'.
                            However, if there is a colspan the class forceWordWrap is applyed (see cellMerge/utils.js)
                            Setting the propery 'cellProperties.forceWordWrap = true' is done to force correct behavior when LimitStrLenBy is > 0 (see pgridHT.js PGridCellRenderer() )
                        */
                        let cols = context.state.pgridDataDynContent[0].length;
                        for (let col = 0; col < cols; col++) {
                            //FUB: Not sure nowrap is needed here
                            lrSettCollCss += `.ht_clone_top_left_corner.handsontable > div > div > div > table > tbody > tr > td:nth-child(${col}),
                            .ht_clone_top.handsontable > div > div > div > table > tbody > tr > td:nth-child(${col}),
                            .ht_master.handsontable > div > div > div > table > tbody > tr > td:nth-child(${col}),
                            .ht_clone_left.handsontable > div > div > div > table > tbody > tr > td:nth-child(${col})
                        {
                                white-space: nowrap;
                        }
                        `;

                        }

                    }

                    function firstNonHiddenCol(pgHiddenColumns, startCol = 0) {
                        let ret = null;

                        for (let i = startCol; i < pgHiddenColumns.length && ret === null; i++) {

                            if (pgHiddenColumns[i] === false) {
                                ret = i
                            }
                        }


                        if (ret == null) {
                            ret = startCol + pgHiddenColumns.length; //Then next column must be shown
                        }

                        return ret;
                    }


                    let firstNonHiddenColIs = firstNonHiddenCol(pgHiddenColumns);

                    lrSettCollCss += `
.handsontable td:nth-of-type(${firstNonHiddenColIs + 1}) {
    border-left: 1px solid #CCC;
}
.handsontable.htRowHeaders thead tr th:nth-child(${firstNonHiddenColIs + 2}) {
    border-left: 1px solid #CCC;
}`

                    if (false && hasFixWidth) { //This is not needed now then the height calc bug is fixed
                        window.PGridClientDebugMode >= 1 && console.debug(`hasFixWidth Disabling PGridPerformanceMode_Heights`);
                        context.commit('Mutation_UpdatePGridSettings', { prop: 'window_PGridPerformanceMode_Heights', val: false, source: `${source} -> Action_ApplyLRPgridCss` });
                    }

                }



                /*
                   Row style handling, that inserts CSS targeting "tbody > tr"
                */
                {
                    let lrPGridSettingsRow = lodash_get(lrPGridSettingsItem, "Rows", {});

                    let lrPGridSettingsRow_Keys = Object.keys(lrPGridSettingsRow);

                    for (let i = 0; i < lrPGridSettingsRow_Keys.length; i++) {

                        let htmlRowIdx = -1;
                        let tableRowIdx = -1;
                        let rowIdx_Key = lrPGridSettingsRow_Keys[i];

                        if (PGridUtils.IsNumeric(rowIdx_Key)) {
                            tableRowIdx = Number(rowIdx_Key);
                            if (tableRowIdx == -1) {
                                throw new Error(`Action_ApplyLRPgridCss() Number(): Illegal row key: ${rowIdx_Key}`)
                            }
                        } else {
                            tableRowIdx = rowLabelToIndex(rowIdx_Key);
                            if (tableRowIdx == -1) {
                                throw new Error(`Action_ApplyLRPgridCss() rowLabelToIndex(): Illegal row key: ${rowIdx_Key}`)
                            }
                        }

                        htmlRowIdx = tableRowIdx;

                        let lrRowCssArr = [];

                        // "LRPGridSettings": {
                        //     "Rows": {
                        //         "10": {
                        //             "Hidden": true
                        //         }
                        //     }
                        // },


                        // let hiddenRow = lodash_get(lrPGridSettingsRow[rowIdx_Key], "Hidden", false);
                        // if (hiddenRow) {
                        //     let genCss = `visibility: collapse;`;
                        //     lrRowCssArr.push(genCss);
                        // } else {

                        // }


                        // "LRPGridSettings": {
                        //     "Rows": {
                        //       "9": {
                        //         "InsertCSS": [
                        //           "height: 60px !important;"
                        //         ]
                        //       }
                        //     }
                        //   },

                        let lrRowCssArrInsertCss = lodash_get(lrPGridSettingsRow[rowIdx_Key], "InsertCSS", null);

                        if (lrRowCssArrInsertCss) {
                            lrRowCssArr = lrRowCssArr.concat(lrRowCssArrInsertCss);
                        }

                        if (lrRowCssArr.length > 0) {

                            lrRowCssArr.forEach(lrRowCss => {

                                lrSettCollCss += `
                            .ht_clone_top_left_corner > div > div > div > table > tbody > tr:nth-child(${htmlRowIdx}),
                            .ht_clone_top > div > div > div > table > tbody > tr:nth-child(${htmlRowIdx}),
                            .ht_master > div > div > div > table > tbody > tr:nth-child(${htmlRowIdx}),
                            .ht_clone_left > div > div > div > table > tbody > tr:nth-child(${htmlRowIdx}) {
                                ${lrRowCss}
                            }

                            `;
                            });
                        }
                    }
                }

                // })
            }

            context.commit('Mutation_UpdateHOTSettings', { prop: 'pgHiddenColumns', val: pgHiddenColumns, op: 'set', source: `${opt.source} -> Action_ApplyLRPgridCss()` });

            let final_lrCss = '';

            // let scrollBarCss = `.pgrid .pgrid-main-container ::-webkit-scrollbar {  // no other than only .pgrid seems to work?

            let scrollBarCss = `.pgrid ::-webkit-scrollbar {
    width: ${context.state.pgridSettings.scrollbarSizeVertical}px;
    height: ${context.state.pgridSettings.scrollbarSizeHorizontal}px;
    background-color: #f5f5f5;
}

.pgrid .pgrid-filters ::-webkit-scrollbar {
    width: ${initState.pgridSettings.scrollbarSizeVertical}px;
    height: ${initState.pgridSettings.scrollbarSizeHorizontal}px;
    background-color: #f5f5f5;
}`
            final_lrCss = final_lrCss + `\n` + scrollBarCss;

            if (context.state.pgridSettings.headers_Hidden) {
                let headersHiddenCss = `.handsontable .htCore tr:first-child td
{
    border-top-color: transparent;
}
.handsontable .htCore td:first-of-type
{
    border-left-color: transparent;
}`
                final_lrCss = final_lrCss + `\n` + headersHiddenCss;
            }


            final_lrCss = final_lrCss + `\n` + lrSettCollCss;

            let idApplyPgridCss = "PGridLRCss-" + context.state.pgridVars.CustomerKey + "-" + context.state.pgridVars.GridKey;
            let classApplyPgridCss = "PGridLRCssLRClass";
            if (context.state.pgridVars) {
                PGridCss.UpdatePGridStyle(idApplyPgridCss, classApplyPgridCss, final_lrCss);
            }
        } catch (err) {
            console.error(`Action_ApplyLRPgridCss: got exception: ${err.message} `);
        }
    },




    async Action_ApplyGridCss(context, opt = { source: "unknown" }) {
        try {

            if (context.state.pgridVars) {
                let final_GridCss = context.state.pgridVars.GridCss || "";

                if (true || final_GridCss) {
                    let idApplyPgridCss = "PGridCss-" + context.state.pgridVars.CustomerKey + "-" + context.state.pgridVars.GridKey;
                    let classApplyPgridCss = "PGridCssClass";
                    PGridCss.UpdatePGridStyle(idApplyPgridCss, classApplyPgridCss, final_GridCss);
                }
            }
        } catch (err) {
            console.error(`Action_ApplyGridCss: got exception: ${err.message} `);
        }
    },




    async Action_Load_FactsFromDBToGrid(context, { initTimestamp, initUrl, source }) {

        await PGridHandlers.Handle_Action_Load_FactsFromDBToGrid(context, { initTimestamp, initUrl, source });
    },






    /*
    This gets called by  HOT {afterSelection, afterChange, afterOnCellMouseDown} -> handleOrDelayAfterChange() changes timouts
    Input: HOT changes (array of cell changes)
    AfterState: cells are inserted to pgridData
    */
    async Action_HOT_ApplyGridChange(context, { hotChanges, source }) {

        let viewMode = null;
        let hotRef = null;
        let hotData = null;

        context.commit('Mutation_UpdatePGridSettings', { prop: 'isApplyGridChange', op: "set", val: true, source: `${source} -> Action_HOT_ApplyGridChange` });


        viewMode = context.state.pgridSettings.viewMode;
        hotRef = context.state.hotRef;

        window.PGridClientDebugMode == 2 && console.debug(`Action_HOT_ApplyGridChange() source ${source}`);
        window.PGridClientDebugMode == 3 && console.debug(`MUX:Action_HOT_ApplyGridChange() source ${source} changes: ${JSON.stringify(hotChanges)}`);

        let doCalcMode = "none"; //none, all or changes

        let changedReferedCells = [];
        let changedNoneReferedCells = []; //hotChanges cells that has no current reference
        let changedFormulaCells = []; //hotChange cells which has formula
        let changedReferenceInCells_Exists = false //hotChange cells that has updated cell references from old value

        // let cellsThatAreReferenced_Reversed = {};
        let cellsThatAreReferenced = context.state.pgridSettings.cellsThatAreReferenced;
        let cellsThatAreReferenced_Inv = context.state.pgridSettings.cellsThatAreReferenced_Inv;
        let cellsThatAreReferenced_Keys = Object.keys(cellsThatAreReferenced);

        let pgridData = await PGridUtils.Get_CurrentPGridData_ASYNC({
            context, source: `${source} -> Action_HOT_ApplyGrid`
        });

        window.PGridClientDebugMode > 3 && console.log(`Action_HOT_ApplyGrid() ChangecellsThatAreReferenced: ${JSON.stringify(cellsThatAreReferenced)}`);


        if (hotRef == null) {
            //A possiblity if the grid has been changed, exit action
            console.warn(`Action_HOT_ApplyGridChange() hotref == null`);
        }
        else {

            hotData = hotRef.getData();

            let currentLastY = hotData.length;
            let currentLastX = hotData[0].length;

            let newTableOrChanges = null;
            if (hotChanges != null) {
                window.PGridClientDebugMode >= 3 && console.debug(`MUX: Action_HOT_ApplyGridChange() only updating changes from HOT to PGridData`);
                newTableOrChanges = { changes: hotChanges, hotData: null, viewMode: viewMode };

                let has_changedFormulaCell = false;
                let has_changedReferedCells = false;
                let has_changedNoneReferedCells = false;


                for (let i = 0; i < hotChanges.length; i++) {

                    let is_changedFormulaCell = false;
                    let is_changedReferedCells = false;
                    let is_changedNoneReferedCells = false;

                    let is_readOnlyCell = false;


                    let [cellY, cellX, oldVal, newVal] = hotChanges[i];


                    let old_pcell = pgridData[cellY][cellX];

                    let old_pcell_formula = null;
                    if (old_pcell && "Formula" in old_pcell) {
                        old_pcell_formula = old_pcell.Formula;
                    };


                    if (old_pcell && "Save" in old_pcell && old_pcell.Save && "ReadOnly" && old_pcell.Save) {

                        is_readOnlyCell = old_pcell.Save.ReadOnly;
                    };

                    if (cellY == 2 && cellX == 14) {
                        let foo = "debug here";
                    }

                    if (cellY == 0 && cellX == 1) {
                        let foo = "debug here";
                    }

                    if (is_readOnlyCell) {
                        //Don´t change if read only
                        continue;
                    }

                    //  context.state.pgridPlugins.openTable.changedRows[cellY] = true;

                    let changeCellAddr = `${cellY}:${cellX}`;


                    let oldValHasFormula = PGridCell.isFormula(old_pcell_formula);
                    let newValHasFormula = PGridCell.isFormula(newVal);
                    let oldAndNewDiffsInHasFormula = oldValHasFormula != newValHasFormula;


                    if (cellsThatAreReferenced_Keys.indexOf(changeCellAddr) != -1) {
                        changedReferedCells.push(changeCellAddr);
                        is_changedReferedCells = true;
                        has_changedReferedCells = true;
                    }
                    else {
                        changedNoneReferedCells.push(changeCellAddr);
                        is_changedNoneReferedCells = true;
                        has_changedNoneReferedCells = true;
                    }

                    //If changing cell has incomming "=Formula" or is an old formula cell
                    if (oldAndNewDiffsInHasFormula || newValHasFormula) {
                        is_changedFormulaCell = true;
                        has_changedFormulaCell = true;
                        changedFormulaCells.push(`${changeCellAddr}:${newVal}`);

                        window.PGridClientDebugMode > 1 && console.debug(`MUX found adding Formula: ${changeCellAddr}: ${newVal}`);
                    }
                    //If changing cell has removed "=Formula" or is an old formula cell
                    if (oldAndNewDiffsInHasFormula && !newValHasFormula) {
                        is_changedFormulaCell = true;
                        has_changedFormulaCell = true;
                        // if (typeof (newVal) === 'undefined') {
                        //     console.log(`MUX removing undefined`);
                        // } else if (newVal === null) {
                        //     console.log(`MUX removing null`);
                        // } else {
                        changedFormulaCells.push(`${changeCellAddr}:${newVal}`);
                        // }

                        window.PGridClientDebugMode > 1 && console.debug(`MUX found removing Formula: ${changeCellAddr}: ${newVal}`);
                    }


                    /* detect if the cell has changes is references (if had or has any formula) */

                    if (
                        oldValHasFormula
                        ||
                        newValHasFormula
                    ) {

                        let refsKeys_Old = PGridUtils.GetRefsFromFormula((oldValHasFormula ? old_pcell_formula : oldVal), cellY, cellX); //or use cellsThatAreReferenced_Inv?
                        let refsKeys_New = PGridUtils.GetRefsFromFormula(newVal, cellY, cellX);

                        if (is_changedFormulaCell || JSON.stringify(refsKeys_Old) != JSON.stringify(refsKeys_New)) {

                            window.PGridClientDebugMode > 1 && console.log(`MUX:Action_HOT_ApplyGrid() *** DETECTED updated cell REF "${cellY},${cellX}"}`);

                            changedReferenceInCells_Exists = true;

                            //Always update
                            PGridMatrices.UpdateCellReferences(changeCellAddr, refsKeys_Old, refsKeys_New, cellsThatAreReferenced, cellsThatAreReferenced_Inv);

                        }
                    }

                }


                /* Determin doCalcMode based erlier observations */

                if (changedReferenceInCells_Exists) {
                    doCalcMode = "changes_withRefUpdates";
                    context.commit('Mutation_UpdatePGridSettings', { prop: 'calcOrderCache', val: null, source: `${source} -> Action_HOT_ApplyGridChange()` });
                    context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced", val: {}, source: `${source} -> Action_HOT_ApplyGridChange` });
                    context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced_Inv", val: {}, source: `${source} -> Action_HOT_ApplyGridChange` });
                }
                else if (
                    /* as write cells without references must also be added, and for now we don't whant update the grid directly, as it might generete race conditions */
                    has_changedReferedCells
                    ||
                    has_changedReferedCells
                    ||
                    has_changedFormulaCell
                ) {
                    doCalcMode = "changes";
                }

            } else {

                console.warn(`Action_HOT_ApplyGridChange() hotRef should not be null`)
                // return;
            }


            //Apply the changes
            let { pgridDataSaved, factChagesWasDetected, hotDataInserted } = await context.dispatch('Action_SaveNewHotDataToPgridData', { newTableOrChanges: newTableOrChanges, hotData: hotData, source: `${source} -> Action_HOT_ApplyGridChange` });

            if (hotDataInserted) {
                hotData = hotDataInserted;
            }

            if (doCalcMode == "none") {

                window.PGridClientDebugMode >= 3 && console.debug(`Action_HOT_ApplyGridChange() doCalcMode == "none"`);
                context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `...())` }); //At least required when enabled by colFilterRows.js onAfterChange() CompileFilterSelection

                // Forcing  horizontal scrollbar to render in certain conditions
                {
                    const filterHeight = 58;
                    let filterRowHeight = lodash_get(document.querySelectorAll(".pgrid-filter-list"), "[0].offsetHeight", null);

                    if (filterRowHeight == null) {
                        window.PGridClientDebugMode >= 3 && console.debug(`pgrid-filters.vue filterRowHeight == nullED!`);
                    } else {
                        let nrOfFilterRows = Math.floor(filterRowHeight / filterHeight);

                        if (nrOfFilterRows >= 2) {
                            window.PGridClientDebugMode >= 3 && console.debug(`Action_HOT_ApplyGridChange() FUB (nrOfFilterRows >= 2 ) => context.state.hotRef.render() to force hor-scrollbar to render`);
                            context.state.hotRef.render();
                        } else if (context.state.hotRef.getSettings().fixedColumnsLeft > 0) {
                            window.PGridClientDebugMode >= 3 && console.debug(`Action_HOT_ApplyGridChange() FUB (hotRef.getSettings().fixedColumnsLeft > 0) => context.state.hotRef.render() to force hor-scrollbar to render`);
                            context.state.hotRef.render();
                        }
                    }
                }


            } else {

                window.PGridClientDebugMode >= 2 && console.debug("MUX: Action_HOT_ApplyGridChange() doCalcMode:" + doCalcMode);
                if (doCalcMode == "all") {
                    let newHotData = await context.dispatch("Action_Calc_AllOrChanges", { calcMode: doCalcMode, source: `${source} -> Action_HOT_ApplyGridChange` });

                } else if (doCalcMode == "changes" || doCalcMode == "changes_withRefUpdates") {
                    await context.dispatch("Action_Calc_AllOrChanges", { calcMode: doCalcMode, changedReferedCells, changedNoneReferedCells, changedFormulaCells, source: `${source} -> Action_HOT_ApplyGridChange` });
                }

            }


            /*
                After any is HOT grid change, this code detects if any registred,
                in pgridSettings.cellsThatAreReferencingLinkedRangeOverlays, Overlay items
                rule (with If_Cell_Value_Is_True) should be taken care of by lr.Phase8_Insert_Overlay(...["postcalcEvent"]
            */

            let cellLRRefs_Keys = null;

            let overlayUpdatedFormulas = false;

            if (context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
                cellLRRefs_Keys = Object.keys(context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays);
            }

            let formulaWasUpdated_inOverlay = false;

            if (cellLRRefs_Keys) {

                window.PGridClientDebugMode >= 2 && console.debug(`Action_HOT_ApplyGridChange: Has cellLRRefs_Keys source: ${source} -> Action_HOT_ApplyGridChange()`)
                let pgridDataBuffer = null;
                let hotTableBuffer = null;
                let hotCellsToUpdatedBuffer = [];


                for (let i = 0; i < hotChanges.length; i++) {

                    let [y, x, oldVal, newVal] = hotChanges[i];

                    let foundIdx = cellLRRefs_Keys.indexOf(`overlay_postcalc:${y}:${x}`);
                    if (foundIdx != -1) {

                        if (context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
                            let lrAffected_List = context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays[cellLRRefs_Keys[foundIdx]];

                            for (let l = 0; l < lrAffected_List.length; l++) {
                                let lrAffected = lrAffected_List[l];

                                let [lrName, yLR, xLR] = lrAffected.split(":");

                                if (pgridDataBuffer == null) {
                                    // pgridDataBuffer = JSON.parse(JSON.stringify(pgridDataSaved));
                                    pgridDataBuffer = pgridDataSaved;
                                }
                                if (hotTableBuffer == null) {
                                    hotTableBuffer = hotData;
                                }

                                let lr = PGridMatrices.Get_LRFromTableCoordinates(yLR, xLR, pgridDataBuffer);

                                let { pgridDataInserted, hotTableInserted /* not used */, hotCellsToUpdate, formulaWasUpdated } = PGridMatrices.InsertOverlay(
                                    lr
                                    , pgridDataBuffer
                                    , hotTableBuffer
                                    , context
                                    , "postcalcEvent");

                                if (formulaWasUpdated) {
                                    console.log("formulaWasUpdated_inOverlay=true");
                                    formulaWasUpdated_inOverlay = true;
                                }

                                pgridDataBuffer = pgridDataInserted;
                                hotTableBuffer = hotTableInserted;

                                for (let n = 0; n < hotCellsToUpdate.length; n++) {
                                    let hotCellUpd = hotCellsToUpdate[n];

                                    if (currentLastY >= hotCellUpd[0] && currentLastX >= hotCellUpd[1]) {
                                        PGridCell.addOrUpdateToHotCellsToUpdate(hotCellsToUpdatedBuffer, hotCellUpd[0], hotCellUpd[1], hotCellUpd[2]);
                                    } else {
                                        window.PGridClientDebugMode >= 2 && console.warn(`Action_HOT_ApplyGridChange():1 skipping insert to ${hotCellUpd[0]}:${hotCellUpd[1]}  '${hotCellUpd[2]}'`);
                                    }
                                }
                            }
                        }
                    }

                }

                /* Is this function broken, when is factChagesWasDetected really used? */
                if (factChagesWasDetected) {

                    if (cellLRRefs_Keys.indexOf("overlay_postcalc:-1:-1") != -1) {

                        if (pgridDataBuffer == null) {
                            pgridDataBuffer = pgridDataSaved;
                        }
                        if (hotTableBuffer == null) {
                            hotTableBuffer = hotData;
                        }


                        let foundLRs = PGridMatrices.Get_LRsFromTableCached(pgridDataBuffer); // Get all linked ranges

                        for (let k = 0; k < Object.keys(foundLRs).length; k++) {

                            let lr = foundLRs[Object.keys(foundLRs)[k]];


                            let pgridDataBufferBefore = null;
                            if (window.PGridClientDebugMode >= 3) {
                                pgridDataBufferBefore = JSON.parse(JSON.stringify(pgridDataBuffer));
                            }


                            let { pgridDataInserted, hotTableInserted, hotCellsToUpdate } = PGridMatrices.InsertOverlay(
                                lr
                                , pgridDataBuffer
                                , hotTableBuffer
                                , context
                                , "unsavedfactsEvent");

                            pgridDataBuffer = pgridDataInserted;
                            hotTableBuffer = hotTableInserted;


                            if (window.PGridClientDebugMode >= 3) {
                                PGridUtils.DebugDetectTableChanges(`ACTION_HOT_ApplyGridChange()`, pgridDataBuffer, pgridDataBufferBefore);
                            }


                            // hotCellsToUpdatedBuffer = hotCellsToUpdatedBuffer.concat(hotCellsToUpdate); /* not used */
                            for (let n = 0; n < hotCellsToUpdate.length; n++) {
                                let hotCellUpd = hotCellsToUpdate[n];

                                if (currentLastY >= hotCellUpd[0] && currentLastX >= hotCellUpd[1]) {
                                    PGridCell.addOrUpdateToHotCellsToUpdate(hotCellsToUpdatedBuffer, hotCellUpd[0], hotCellUpd[1], hotCellUpd[2]);
                                } else {
                                    window.PGridClientDebugMode >= 1 && console.warn(`Action_HOT_ApplyGridChange():2 skipping insert to ${hotCellUpd[0]}:${hotCellUpd[1]}  '${hotCellUpd[2]}'`);
                                }
                            }
                        }
                    }

                }

                window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #9', PGridVueStore.state.hotRef.getData().length);

                if (pgridDataBuffer) {
                    context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `${source} -> Action_HOT_ApplyGridChange` });
                }

                window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #10', PGridVueStore.state.hotRef.getData().length);

                if (formulaWasUpdated_inOverlay) {
                    context.commit('Mutation_UpdatePGridSettings', { prop: 'calcOrderCache', val: null, source: `${source} -> Action_HOT_ApplyGridChange()` });
                }


                window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #11', PGridVueStore.state.hotRef.getData().length);

                if (hotCellsToUpdatedBuffer.length > 0) { /*will always be true ? */


                    PGridUtils.PGridTimerStart('Action_HOT_ApplyGridChange:setDataAtCell');

                    context.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', op: "add", val: 1, source: `${source} -> Action_HOT_ApplyGridChange` });
                    hotRef.setDataAtCell(hotCellsToUpdatedBuffer, null, null, `${source} -> Action_HOT_ApplyGridChange`);
                    // await context.dispatch("Action_Redraw", { source: `${source} -> Action_HOT_ApplyGridChange()` });
                }
                else {
                    await context.dispatch("Action_Redraw", { source: `${source} -> Action_HOT_ApplyGridChange()` });

                    window.PGridClientDebugMode >= 2 && console.debug(`Action_HOT_ApplyGridChange() HT updated psource: ${source}`);

                    context.commit('Mutation_UpdatePGridSettings', { prop: 'isCellEditing', op: "set", val: false, source: `Action_HOT_ApplyGridChange() HT updated source: ${source}` });
                }
            }
        }


        context.commit('Mutation_UpdatePGridSettings', { prop: 'lastChangesLocked', op: "set", val: false, source: `Action_HOT_ApplyGridChange` });

        context.commit('Mutation_UpdatePGridSettings', { prop: 'isApplyGridChange', op: "set", val: false, source: `Action_HOT_ApplyGridChange` });

    },


    async action_pgridUpdateCssPanel(context, newShowPanel) {
        console.log("Vuex Action: action_pgridUpdateCssPanel: " + newShowPanel + " context.state.pgridVars.CustomerKey: " + context.state.pgridVars.CustomerKey);// + " cellSelection: " + cellSelection);


        if (newShowPanel) {

            let idApplyPgridCss = "PGridCustomerCss-" + context.state.pgridVars.CustomerKey;
            let classApplyPgridCss = "PGridCustomerCss-class";

            await PGridCss.LoadPGridCss(idApplyPgridCss, classApplyPgridCss, context.state.pgridVars.CustomerKey); //Force (re-) load CSS from DB
        }

        context.commit('mutation_updatePgridSettings', { "prop": "showCssPanel", "value": newShowPanel });
    },

    async Action_PGridToggleSpansAndHidden(context, { disableSpanAndShowHidden = null, source = "unknown for Action_PGridToggleSpansAndHidden" }) {

        let disableSpanAndShowHidden_OLD = context.state.pgridSettings.PGridTableShowHiddenMode;
        context.commit("Mutation_UpdatePGridSettings", { prop: "PGridTableShowHiddenMode", val: disableSpanAndShowHidden, source: `${source} -> Action_PGridToggleSpansAndHidden` });
        context.commit('Mutation_UpdatePGridSettings', { prop: 'headers_Hidden', val: false, source: `${source} -> Action_PGridToggleSpansAndHidden` });

        context.commit('Mutation_UpdateRoot', { prop: 'LowestRightOptimization', val: !disableSpanAndShowHidden, source: `${source} -> Action_PGridToggleSpansAndHidden` });

        let rootEl = document.querySelector("#pgrid-root");
        if (rootEl) {
            if (disableSpanAndShowHidden) {
                rootEl.classList.add("pgrid-editor-mode");
                rootEl.classList.remove("pgrid-normal-mode");
            } else {
                rootEl.classList.add("pgrid-normal-mode");
                rootEl.classList.remove("pgrid-editor-mode");
            }
        }


        if (context.state.pgridSettings.PGridTableDebugMode_HasBeenActive) {

            if (disableSpanAndShowHidden_OLD != context.state.pgridSettings.PGridTableShowHiddenMode) {

                let configUpdate = { mergeCells: disableSpanAndShowHidden ? null : context.state.hotSettings.spanCells };
                await context.dispatch("Action_ReConfig", { configUpdate: configUpdate, source: `${source} -> Action_SwitchEditMode()` });
            }

            // context.state.hotRef.updateSettings()
            await context.dispatch("Action_Redraw", { source: `${source} -> Action_SwitchEditMode()` });
        } else {
            //Only re-calculate if edit mode has not been activated before
            // context.commit('Mutation_UpdatePGridSettings', { prop: "hasNeverLoadedFilters", val: true, source: `${source} -> Action_SwitchEditMode()` });

            await context.dispatch("Action_InjectAndRendrFilters", { type: "switchTableDebugMode", source: `${source} -> Action_SwitchEditMode()` });

            context.commit('Mutation_UpdatePGridSettings', { prop: "PGridTableDebugMode_HasBeenActive", val: true, source: `${source} -> Action_SwitchEditMode()` });
        }


    },



    /*
    * Action_Calc_AllOrChanges
    *
    *
    * Returns:
    *
    *
    *           sets state.pgridSettings.cellsThatAreReferenced
    *           sets HOT data to
    */

    // DEBUG_STEP_8-10_Action_Calculata_All
    async Action_Calc_AllOrChanges(context, { calcMode, initTimestamp, initUrl, changedReferedCells, changedNoneReferedCells, changedFormulaCells, source }) {

        window.PGridClientDebugMode >= 2 && console.debug(`MUX:Action_Calc_AllOrChanges() source: ${source}`);

        let cellsThatAreReferenced = context.state.pgridSettings.cellsThatAreReferenced;
        let cellsThatAreReferenced_Inv = context.state.pgridSettings.cellsThatAreReferenced_Inv;

        let Action_Calc_AllOrChanges_Stage1 = Date.now();

        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage1, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
        }

        let retHotData_IfCalcAll = null;

        // let debug = false;


        if (context.state.pgridSettings.DisableEvents) {
            if (window.PGridClientDebugMode >= 2) {
                console.debug(`Action_Calc_AllOrChanges: events disabled, but will continue`);
            }
        }

        let pgridData = await PGridUtils.Get_CurrentPGridData_ASYNC({ context, source: `${source} -> Action_Calc_AllOrChanges` });

        let Action_Calc_AllOrChanges_Stage2 = Date.now();

        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage2, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
        }


        let calcRes = null; //  {  pgridTableCalculated, affectedCells, cellWithFormulasOrReferences }

        try {

            if (window.PGridClientDebugMode >= 3) {
                console.debug(`MUX: Pre Calculate_All_Formulas() cellsThatAreReferenced`, JSON.parse(JSON.stringify(cellsThatAreReferenced)));
                console.debug(`MUX: Pre Calculate_All_Formulas() cellsThatAreReferenced_Inv`, JSON.parse(JSON.stringify(cellsThatAreReferenced_Inv)));
                console.debug(`MUX: Pre Calculate_All_Formulas() changedReferedCells`, JSON.parse(JSON.stringify(changedReferedCells || null)));
                console.debug(`MUX: Pre Calculate_All_Formulas() changedNoneReferedCells`, JSON.parse(JSON.stringify(changedNoneReferedCells || null)));
                console.debug(`MUX: Pre Calculate_All_Formulas() changedFormulaCells`, JSON.parse(JSON.stringify(changedFormulaCells || null)));
            }

            let StartTime_DEBUG_STEP_8_Calculate_All_Formulas = Date.now();

            calcRes = await PGridUtils.Calculate_All_Formulas(context, pgridData, { cellsThatAreReferenced: context.state.pgridSettings.cellsThatAreReferenced, changedReferedCells, changedFormulaCells, cellsThatAreReferencingLinkedRangeOverlays: context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays, source: `${source} -> Action_Calc_AllOrChanges` });

            window.PGridClientDebugMode >= 2 && console.debug(`DEBUG_STEP_8_Calculate_All_Formulas elapsed ${(Math.floor((Date.now() - StartTime_DEBUG_STEP_8_Calculate_All_Formulas) / (100)))} 10th s`); // in seconds
            window.PGridClientDebugMode >= 3 && console.debug(`MUX:Action_Calc_AllOrChanges() calcRes.cellsToUpdate`, JSON.parse(JSON.stringify(calcRes.cellsToUpdate)));
        }
        catch (err) {
            console.error("Action_Calc_AllOrChanges() got error: ", err);
            // alert(`Calculation error: ${ err.stack || err.message || err }`);
        }

        let Action_Calc_AllOrChanges_Stage3 = Date.now();
        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage3, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
        }


        window.PGridClientDebugMode >= 3 && console.debug(`Action_Calc_AllOrChanges() cellsThatAreReferenced old`, state.pgridSettings.cellsThatAreReferenced);
        window.PGridClientDebugMode >= 3 && console.debug(`Action_Calc_AllOrChanges() cellsThatAreReferenced incomming`, calcRes.cellsThatWasReferenced);
        window.PGridClientDebugMode >= 3 && console.debug(`Action_Calc_AllOrChanges() cellsWithFormulas incomming`, calcRes.cellsWithFormulas);

        //Get current pgriddata
        // let pgridDataCopy = JSON.parse(JSON.stringify(calcRes.pgridTableCalculated));
        let pgridDataCopy = lodash_clone(calcRes.pgridTableCalculated);

        //Only update cellsThatAreReferenced if not set
        let merge_cellsThatAreReferenced = lodash_merge({}, context.state.pgridSettings.cellsThatAreReferenced, calcRes.cellsThatWasReferenced);
        let merge_cellsThatAreReferenced_Inv = PGridUtils.InvertObjArr(merge_cellsThatAreReferenced);

        window.PGridClientDebugMode >= 3 && console.debug(`MUX:Action_Calc_AllOrChanges() cellsThatAreReferenced merged`, merge_cellsThatAreReferenced);

        context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced", op: "set", val: merge_cellsThatAreReferenced, source: `${source} -> Action_Calc_AllOrChanges` });
        context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced_Inv", op: "set", val: merge_cellsThatAreReferenced_Inv, source: `${source} -> Action_Calc_AllOrChanges` });

        let Action_Calc_AllOrChanges_Stage4 = Date.now();
        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage4, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
        }

        /*
        * Question?  //FUB
        *
        *   Now will the pgridTableCalculated will only update the HOT table
        *
        *   Why not the pgridData store, and then generate the hot table from that?
        *
        *
        */

        let convRes = PGridUtils.Convert_PgridDataToHotTable(pgridDataCopy, "default", `${source} -> Action_Calc_AllOrChanges`);

        let hotData = convRes.data;

        let Action_Calc_AllOrChanges_Stage5 = Date.now();
        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage5, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
        }

        if (calcMode == "all" /*source.indexOf("Action_HOT_ApplyGridChange") == -1*/) { //Only on INIT, as all following calls to this will be from Action_HOT_ApplyGridChange

            let formulaWasUpdated_inOverlay = false;

            //Handle Overlay postCalc items
            const foundLRs = PGridMatrices.Get_LRsFromTable(pgridDataCopy, { source: `${source} -> Action_Calc_AllOrChanges()` }); // Get all linked ranges

            let foundLRs_Keys = Object.keys(foundLRs);
            for (let i = 0; i < foundLRs_Keys.length; i++) {

                if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                    window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage5:${i}, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                    return;
                }

                let lr = foundLRs[foundLRs_Keys[i]];

                let { pgridDataInserted, hotTableInserted, formulaWasUpdated } = PGridMatrices.InsertOverlay(
                    lr
                    , pgridDataCopy
                    , hotData
                    , context
                    , "postcalcEvent2");

                if (formulaWasUpdated) {
                    // console.warn("// formulaWasUpdated_inOverlay = true;");
                    formulaWasUpdated_inOverlay = true;
                }
                pgridDataCopy = pgridDataInserted;
                hotData = hotTableInserted

            }



            if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage5.2, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                return;
            }

            context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataCopy, source: `${source} -> Action_Calc_AllOrChanges` });


            if (formulaWasUpdated_inOverlay) {
                context.commit('Mutation_UpdatePGridSettings', { prop: 'calcOrderCache', val: null, source: `${source} -> Action_Calc_AllOrChanges()` });
                context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced", val: {}, source: `${source} -> Action_Calc_AllOrChanges` });
                context.commit('Mutation_UpdatePGridSettings', { prop: "cellsThatAreReferenced_Inv", val: {}, source: `${source} -> Action_Calc_AllOrChanges` });

                let hotData_OverlayChanges = await context.dispatch("Action_Calc_AllOrChanges", { calcMode: "all" });
                hotData = hotData_OverlayChanges;
            }


            retHotData_IfCalcAll = hotData;
        }
        else { //"changes" or "changes_withRefUpdates"

            let cellsToUpdateList = [];

            for (let u = 0; u < calcRes.cellsToUpdate.length; u++) {

                let updCell = calcRes.cellsToUpdate[u];

                let x = updCell.x;
                let y = updCell.y;



                if (y == 3 && x == 7) {
                    let foo = "debug here 2";
                }

                if (updCell.updated) {


                    if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage5.3, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                        return;
                    }

                    //Insert calculated values
                    let { hotValue, error } = PGridCell.Insert_Calculated_Cell(updCell, pgridDataCopy);

                    let skipHotWrite = false;
                    if (error !== null) {
                        if ((y < 0 || y + 1 > pgridDataCopy.length)
                            || (x < 0 || x + 1 > pgridDataCopy[y].length > x)) {
                            skipHotWrite = true; //Skip writing error cells that expands table
                        }
                    }

                    if (skipHotWrite === false) {

                        window.PGridClientDebugMode >= 3 && console.debug(`[[[setDataAtCell]]]  Action_Calc_AllOrChanges() y: ${y} y, x: ${x}, value: ${hotValue} `);
                        window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #6', PGridVueStore.state.hotRef.getData().length);

                        {
                            let pgridData = null;
                            try {
                                pgridData = await PGridUtils.Get_CurrentPGridData_ASYNC({ context, source: `Action_Calc_AllOrChanges()` });
                                window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #7', PGridVueStore.state.hotRef.getData().length);
                            }
                            catch {
                                //This happens when calling Action_PgridRESET
                                return;
                            }

                            // let pcell = PGridUtils.getPGridCell(pgridData, y, x);

                            // let newPcellValue = PGridCell.GetPCellValue_FromHOTValue(hotValue);
                            // pcell = PGridCell.merge_PCellValue(pcell, newPcellValue); //Should insert

                        }

                        cellsToUpdateList.push({ y, x, hotValue });
                    }
                }
            }

            if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage5.4, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                return;
            }

            window.PGridClientDebugMode >= 1 && console.debug(`[[[setDataAtCell]]]  Action_Calc_AllOrChanges() cellsToUpdateList.length ${cellsToUpdateList.length} `);

            if (cellsToUpdateList.length > 0) {
                window.PGridClientDebugMode >= 2 && console.warn("setDataAtCell is not effective");
            }

            let cellsToUpdateListHOTData_Array = [];
            let cellsToUpdateListPGridData_Array = [];


            if (!context.state.hotRef) {
                let errMsg = "Action_Calc_AllOrChanges: context.state.hotRef is null"
                alert(`Detected an error:\n\n` + errMsg + `\n\nPlease reload page`)
                console.error(errMsg)
                throw new Error(errMsg)
            }

            let pgridHOTDataToCheckForChanges = context.state.hotRef.getData();
            let pgridHOTData_MaxY = pgridHOTDataToCheckForChanges.length;
            let pgridHOTData_MaxX = pgridHOTDataToCheckForChanges[0].length;

            let countIgnoredCells = 0; // count of the number of cells that don't need to be updated
            for (let i = 0; i < cellsToUpdateList.length; i++) {
                let { y, x, hotValue } = cellsToUpdateList[i];

                if (y > pgridHOTData_MaxY - 1
                    || x > pgridHOTData_MaxX - 1) {

                    //Always update if outside hot table visable limits, but to cellsToUpdateListPGridData_Array
                    cellsToUpdateListPGridData_Array.push([y, x, hotValue]);

                } else {

                    let currentPCellHOTVal = pgridHOTDataToCheckForChanges[y][x];
                    if (hotValue == currentPCellHOTVal) {
                        countIgnoredCells++;
                    } else {
                        cellsToUpdateListHOTData_Array.push([y, x, hotValue]);
                    }
                }
            }
            if (countIgnoredCells) {
                console.warn(`Action_Calc_AllOrChanges() Found ${countIgnoredCells} identical target cells, did not add those to setDataAtCell array`);
            }


            if (cellsToUpdateListPGridData_Array.length > 0) {

                for (let i = 0; i < cellsToUpdateListPGridData_Array.length; i++) {

                    let y = cellsToUpdateListPGridData_Array[i][0];
                    let x = cellsToUpdateListPGridData_Array[i][1];
                    let hotValue = cellsToUpdateListPGridData_Array[i][2];

                    // Calculate the pgrid value to insert
                    let pcell = PGridUtils.getPGridCell(pgridData, y, x);
                    let newPcellValue = PGridCell.GetPCellValue_FromHOTValue(hotValue);
                    pcell = PGridCell.merge_PCellValue(pcell, newPcellValue); //Should insert
                    pgridDataCopy[y][x] = pcell;

                }
                context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataCopy, source: `${source} -> Action_Calc_AllOrChanges 2` });

            }

            //Only update cells if cellsToUpdateList_Array has any changes
            if (cellsToUpdateListHOTData_Array.length > 0) {

                context.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', op: "add", val: 1, source: `${source} -> Action_Calc_AllOrChanges` });

                PGridUtils.PGridTimerStart('Action_calc_allOrChanges:setDataAtCell');

                if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                    window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage5.5, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                    return;
                }


                // function renderCell(instance, row, col, value, prop = null) {
                //     const td = instance.getCell(row, col, true);
                //     // const coords = instance.getCoords(td)
                //     const renderer = instance.getCellRenderer(row, col)
                //     const cellProperties = instance.getCellMeta(row, col)

                //     const gridData = instance.getData();

                //     // gridData[row][col] = value;
                //     renderer(instance, td, row, col, prop, value, cellProperties)
                // }


                context.state.hotRef.setDataAtCell(cellsToUpdateListHOTData_Array, null, null, `${source} -> Action_Calc_AllOrChanges`);

                PGridUtils.PGridTimerStop('afterChange:setDataAtCell');
                PGridUtils.PGridTimerStop('beforeChange:setDataAtCell');
                PGridUtils.PGridTimerStop('Action_calc_allOrChanges:setDataAtCell');
                PGridUtils.PGridTimerStop('Action_HOT_ApplyGridChange:setDataAtCell');
                PGridUtils.PGridTimerPrint();
                PGridUtils.PGridTimerReset();

                if (window.PGridClientDebugMode >= 3) {
                    console.debug("¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ is this after afterChange ????????????????????????");
                }
            } else {

                /* Else, enable setdataatcell events */
                if (context.state.pgridSettings.DisableEventsSetDataAtCellNTimes > 0) {
                    if (window.PGridClientDebugMode >= 2) {
                        console.warn(`********* context.state.pgridSettings.DisableEventsSetDataAtCellNTimes: ${context.state.pgridSettings.DisableEventsSetDataAtCellNTimes} -> 0 by force *********`);
                    }
                    context.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', op: "set", val: 0, source: `${source} -> Action_Calc_AllOrChanges` });
                }
            }

            window.PGridClientDebugMode >= 3 && console.debug("Action_Calc_AllOrChanges DONE");
        }

        let Action_Calc_AllOrChanges_Stage6 = Date.now();

        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_Calc_AllOrChanges stage5.6, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
        }


        if (window.PGridClientDebugMode >= 2) {
            let timeNow = Date.now();
            console.debug(''
                + `[Action_Calc_AllOrChanges_Stage1] elapsed ${(Math.floor((Action_Calc_AllOrChanges_Stage2 - Action_Calc_AllOrChanges_Stage1) / (10)))} 100th s\n` // in seconds
                + `[Action_Calc_AllOrChanges_Stage2] elapsed ${(Math.floor((Action_Calc_AllOrChanges_Stage3 - Action_Calc_AllOrChanges_Stage2) / (10)))} 100th s\n` // in seconds
                + `[Action_Calc_AllOrChanges_Stage3] elapsed ${(Math.floor((Action_Calc_AllOrChanges_Stage4 - Action_Calc_AllOrChanges_Stage3) / (10)))} 100th s\n` // in seconds
                + `[Action_Calc_AllOrChanges_Stage4] elapsed ${(Math.floor((Action_Calc_AllOrChanges_Stage5 - Action_Calc_AllOrChanges_Stage4) / (10)))} 100th s\n` // in seconds
                + `[Action_Calc_AllOrChanges_Stage5] elapsed ${(Math.floor((Action_Calc_AllOrChanges_Stage6 - Action_Calc_AllOrChanges_Stage5) / (10)))} 100th s\n` // in seconds
                + `[Action_Calc_AllOrChanges_Stage6] elapsed ${(Math.floor((timeNow - Action_Calc_AllOrChanges_Stage6) / (10)))} 100th s`); // in seconds
        }

        return retHotData_IfCalcAll;
    },

    /*
    * Runns after initial data has been loaded
    * Init filter objects (which updates UI)
    */
    async Action_PgridFiltersUpdateStates(context, { source = null }) {

        window.PGridClientDebugMode >= 2 && console.debug(`${source} -> Action_PgridFiltersUpdateStates`)
        try {

            let accVirtualQueryString = state.pgridVars.VirtualQueryString;
            let pgridDefs = state.pgridVars.GridDefinition;

            // Apply _DYN extractions for example GuidanceHtml
            PGridDynExtract.UpdateDynProps_Generic(context, pgridDefs.Filter);

            let filterNamesAndDS = PGridUtils.Filter_ConvertPgridDefinitionToFilterNames(pgridDefs);



            let newTitle = `${context.state.pgridVars.GridTitle}`

            let filtersWithSelection = filterNamesAndDS.map((o, idx) => {

                // let usingThis = { /*pgridDefs, */ o_filtertitle: o.filtertitle, state_pgridDim: state.pgridDim, accVirtualQueryString };
                try {

                    let { VirtualQueryString: newVirtialQueryString, PGridFilter: filter, /*isPublished: filterIndicateIsPublished*/ }
                        = PGridUtils.Filter_ConvertPgridDefinitionToPGridFilter(pgridDefs, filterNamesAndDS, o.filtertitle, state.pgridDim, accVirtualQueryString);



                    accVirtualQueryString = newVirtialQueryString;


                    PGridUtils.updateTabLinks(accVirtualQueryString, `Action_PgridFiltersUpdateStates()`);

                    PGridUtils.HistoryPushStateIfNotSame(accVirtualQueryString, `Action_PgridFiltersUpdateStates()`);

                    // let filtVal = filter.filterOptions.find(x => x.value == filter.filterValue);

                    let filtVal = null;

                    for (let s = 0; filtVal == null && s < filter.filterOptions.length; s++) {
                        let foSearch = filter.filterOptions[s];
                        if (filter.filterValue.value == foSearch.value) {
                            filtVal = JSON.parse(JSON.stringify(foSearch));
                        }
                    }


                    if (!filtVal) {
                        filtVal = { value: undefined, text: "error" };
                    }

                    let keywordsDB = {
                        "FILTER_SELECTED_VALUE": filtVal.value,
                        "FILTER_SELECTED_TEXT": filtVal.text,
                    }

                    if (filter.hiddenToGrid != true) {
                        let addToTitle = filter.pageTitleTemplate;
                        [addToTitle,] = PGridUtils.applyKeywordsDb(keywordsDB, addToTitle);
                        newTitle += addToTitle;
                    }

                    //FUB Store the old value here
                    filter.old_filterValue = lodash_get(context, `state.pgridFilter.Filters[${idx}].old_filterValue`, {});

                    return filter;
                } catch (err) {
                    console.error(`Faild to update filter: ${err.message}`);
                    return null;
                }
            });

            if (document.title != newTitle) {
                document.title = newTitle;
            }

            context.commit('Mutation_FiltersUpdate', { prop: "Filters", val: filtersWithSelection });

            let applyMode = lodash_get(state, "pgridVars.GridDefinition.Filter.ApplyMode", "Auto_All_Selected");
            context.commit('Mutation_FiltersUpdate', { prop: "ApplyMode", val: applyMode });


            //Handle button props
            let dynFilterButtons = lodash_get(state, "pgridVars.GridDefinition.Filter.DynamicButtons", initState.pgridFilter.DynamicButtons);
            PGridDynExtract.UpdateDynProps_ButtonList(context, dynFilterButtons);
            context.commit('Mutation_FiltersUpdate', { prop: "DynamicButtons", val: dynFilterButtons });


            let guidances = [];

            if (lodash_has(state, "pgridVars.GridDefinition.Filter.Guidances")) {
                guidances = lodash_get(state, "pgridVars.GridDefinition.Filter.Guidances", initState.pgridFilter.Guidances);
            }
            else if (lodash_has(state, "pgridFilter.Guidances")) {
                guidances = state.pgridFilter.Guidances;
            }

            let addPostfixIfNonNull = function (inStr, postfix) {
                let ret = null;
                if (inStr !== null && inStr !== undefined) {
                    ret = `${inStr}${postfix}`;
                }
                return ret;
            }

            for (let g = 0; g < guidances.length; g++) {

                let guide = guidances[g];

                if (guide.Guidance_Html) {
                    let GuidanceHtml_safe = PGridUtils.safeifyHtmlData(guide.Guidance_Html)
                    if (GuidanceHtml_safe != guide.Guidance_Html) {
                        guide.Guidance_Html = GuidanceHtml_safe;
                    }

                    guide.Guidance_MinWidth = addPostfixIfNonNull(lodash_get(guide, "Guidance_MinWidth", initState.pgridFilter.Guidances_MinWidth), 'px');
                    guide.Guidance_MaxWidth = addPostfixIfNonNull(lodash_get(guide, "Guidance_MaxWidth", initState.pgridFilter.Guidances_MaxWidth), 'px');
                    guide.Guidance_Color = lodash_get(guide, "Guidance_Color", initState.pgridFilter.Guidances_MaxWidth);
                }

            }


            if (guidances.length == 0) {
                let guidanceHtml_legacy = null;
                if (context.state.pgridFilter.GuidanceHtml !== initState.pgridFilter.GuidanceHtml) { //If not updated by MutateStore
                    guidanceHtml_legacy = context.state.pgridFilter.GuidanceHtml; //Update self to make html safe
                } else {
                    guidanceHtml_legacy = lodash_get(state, "pgridVars.GridDefinition.Filter.GuidanceHtml", initState.pgridFilter.GuidanceHtml);
                }

                if (guidanceHtml_legacy != null) {

                    let Guidances_MinWidth = null;
                    if (!lodash_has(state, "pgridVars.GridDefinition.Filter.Guidances_MinWidth")) {
                        Guidances_MinWidth = lodash_get(state, "pgridVars.GridDefinition.Filter.Guidances_MinWidth", initState.pgridFilter.Guidances_MinWidth);
                    } else if (!lodash_has(state, "pgridVars.GridDefinition.Filter.GuidanceHtmlMinWidth")) {
                        Guidances_MinWidth = lodash_get(state, "pgridVars.GridDefinition.Filter.GuidanceHtmlMinWidth", initState.pgridFilter.GuidanceHtmlMinWidth);
                    }

                    let guidance_legacy_single = {
                        Guidance_Html: PGridUtils.safeifyHtmlData(guidanceHtml_legacy),
                        Guidance_MinWidth: Guidances_MinWidth,
                        Guidance_MaxWidth: Guidances_MaxWidth,
                        Guidance_Color: lodash_get(state, "pgridVars.GridDefinition.Filter.Guidances_Color", initState.pgridFilter.Guidances_Color)
                    }

                    guidances = [
                        guidance_legacy_single
                    ]

                }
            }



            context.commit('Mutation_FiltersUpdate', { prop: "Guidances", val: guidances });

            // let guidanceHtml_minWidth = lodash_get(state, "pgridVars.GridDefinition.Filter.GuidanceHtmlMinWidth", initState.pgridFilter.GuidanceHtmlMinWidth);
            // context.commit('Mutation_FiltersUpdate', { prop: "GuidanceHtmlMinWidth", val: Number(guidanceHtml_minWidth) });

            let hidden = lodash_get(state, "pgridVars.GridDefinition.Filter.Hidden", false);
            context.commit('Mutation_FiltersUpdate', { prop: "Hidden", val: hidden });

        } catch (err) {
            let errMsg = `> Mutation_FiltersUpdateInit() got exception: ${err.message || err}`;
            throw new Error(errMsg);
        }
    },


    //DEBUG_STEP_3-5_Action_UpdateFilter
    async Action_InjectAndRendrFilters(context, { type = "filter", source = null }) {

        window.PGridClientDebugMode >= 2 && console.debug(`DEBUG_STEP_PRE_4 set #pgrid-root-container = ''`);

        let rootContainer = document.getElementById("pgrid-root-container");
        if (rootContainer) {
            if (rootContainer.children.length >= 2) {

                let gridAndFormulaContainer = rootContainer.children[1];

                let thePgrid = document.getElementById("pgrid-my-hot");

                if (thePgrid) {
                    // rootContainer.removeChild(rootContainer.children[1]); //Removing the grid
                    gridAndFormulaContainer.removeChild(thePgrid); //Removing the grid
                }
            }
        }

        if (type == "switchTableDebugMode") {
            context.commit('Mutation_UpdatePGridSettings', { prop: 'IsLoadingDisabled', val: true, source: `${source} > Action_InjectAndRendrFilters()` });
        }

        if (context.state.pgridSettings.hasNeverLoadedFilters || (type == 'switchTableDebugMode' && context.state.pgridSettings.PGridTableDebugMode_HasBeenActive)) {
            context.commit('Mutation_UpdatePGridSettings', { prop: 'hasNeverLoadedFilters', val: false, source: `${source} > Action_InjectAndRendrFilters()` });
        } else {
            window.PGridClientDebugMode >= 3 && console.debug(`Found loaded filters, resetting type filter`);
            await context.dispatch("Action_PgridRESET", { type: type, source: `${source} > Action_InjectAndRendrFilters()` });
        }

        new Vue({
            el: '#pgrid-root-container'
            , store: context
            , render: h => h(PgridRootApp)
        });

        /* Loading continues from  pgrid-filters.vue mounted, then Action_Stage_Filter() */

    },

    async Action_InjectAndRendrFiltersERROR(context, { source = null }) {

        new Vue({
            el: '#pgrid-root-container'
            , store: context
            , render: h => h(PgridRootAppOnlyError)
        });
    },


    //DEBUG_STEP_6-10_Action_InitializeGrid
    async Action_InitializeGrid(context, { initTimestamp, initUrl, type = "reload_full", source = null }) {

        let retHotDataIfInit = false;

        try {
            if (type == "reload_full") {

                window.PGridClientDebugMode >= 2 && console.debug("DEBUG_STEP_6_Action_Load_FactsFromDBToGrid");

                if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                    window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_InitializeGrid BEFORE Action_Load_FactsFromDBToGrid, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                    return;
                }

                await context.dispatch("Action_Load_FactsFromDBToGrid", { initTimestamp, initUrl, source: `${source} -> Action_InitializeGrid` });

                if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                    window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Action_InitializeGrid AFTER Action_Load_FactsFromDBToGrid, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                    return;
                }

                window.PGridClientDebugMode >= 2 && console.debug("DEBUG_STEP_8-10_Action_Calculata_All");
                retHotDataIfInit = await context.dispatch("Action_Calc_AllOrChanges", { calcMode: "all", initTimestamp, initUrl, source: `${source} -> Action_InitializeGrid` });

                let foo = "bar";
            }
        } catch (err) {
            let errMsg = `> Action_InitializeGrid() got exception(source: ${source}): ${err.message || err}`;
            throw new Error(errMsg);
        }

        return retHotDataIfInit;
    },


    /*
    *  Input: hotData, (state.pgridDataDynContent or state.pgridData) and changes
    *  Outpus: Merge hotData with sets state.pgridDataDynContent or pgridData depending on Is_DynamicMode
    */

    async Action_SaveNewHotDataToPgridData(context, { newTableOrChanges, source }) {

        if (window.PGridClientDebugMode >= 3) {
            console.debug(`Mutation_SaveNewHotDataToPgridData() source: ${source} newTableOrChanges: ${JSON.stringify(newTableOrChanges)} `);
        } else {
            window.PGridClientDebugMode >= 2 && console.debug(`Mutation_SaveNewHotDataToPgridData() source: ${source}`);
        }

        let ret = {
            pgridDataSaved: null,
            factChagesWasDetected: false,
            hotData: null,
        }

        let pgridData = await PGridUtils.Get_CurrentPGridData_ASYNC({ context, source: `${source} -> Action_SaveNewHotDataToPgridData()` });

        if (newTableOrChanges == null) {
            //May be empty on init
            window.PGridClientDebugMode >= 2 && console.debug("Mutation_SaveNewHotDataToPgridData() got empty newData!");
            return;
        } else {


            let mType = PGridUtils.Translate_ViewModeToMergeType(newTableOrChanges.viewMode);

            let pgridData_Before = JSON.parse(JSON.stringify(pgridData));

            let pgridDataWithHotChanges = PGridUtils.Merge_HotTableToPgridTable(newTableOrChanges.hotData, newTableOrChanges.changes, pgridData, mType, source);

            context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridData, source: `${source} -> Mutation_SaveNewHotDataToPgridData` });

            let pgridData_After = pgridDataWithHotChanges; //PGridUtils.Get_CurrentPGridData({ state, source: `${source} -> Mutation_SaveNewHotDataToPgridData` });

            //only calculate ret.factChagesWasDetected if attestRequired  of Debug level > 3
            if (state.pgridSettings.attestRequired || window.PGridClientDebugMode > 3) {
                try {

                    //FUB, is it possible to make more optimized change detection, or is Diff_PgridData quick enought?
                    /*
                    *
                    * *************  Monitor grid changes here if PGridClientDebugMode > 3  **************
                    *
                    */
                    let saveDiff = PGridUtils.Diff_PgridData(pgridData_Before, pgridData_After);

                    let saveDiff_Keys = Object.keys(saveDiff);

                    for (let i = 0; i < saveDiff_Keys.length; i++) {

                        let saveDiff_TypeKey = saveDiff_Keys[i]; //added, deleted or updated


                        let saveDiff_Type = saveDiff[saveDiff_TypeKey];

                        let saveDiff_RowKeys = Object.keys(saveDiff_Type);

                        for (let j = 0; j < saveDiff_RowKeys.length; j++) {

                            let saveDiff_TypeRowKey = saveDiff_RowKeys[j];
                            let saveDiff_TypeRowCols = saveDiff[saveDiff_TypeKey][saveDiff_TypeRowKey];

                            let saveDiff_TypeRowColsKeys = Object.keys(saveDiff_TypeRowCols);

                            for (let k = 0; k < saveDiff_TypeRowColsKeys.length; k++) {

                                let saveDiff_TypeRowCol = saveDiff_TypeRowColsKeys[k];

                                let saveDiff_Cell = pgridData_After[saveDiff_TypeRowKey][saveDiff_TypeRowCol];

                                let isWritable = lodash_get(saveDiff_Cell, "Meta.Save.Writable", false);
                                let isReadOnly = lodash_get(saveDiff_Cell, "Meta.Save.ReadOnly", false);

                                // console.warn("Why is factChagesWasDetected = true needed now?");
                                // ret.factChagesWasDetected = true;
                                // ret.factChagesWasDetected = true;

                                /* Is this function broken, when is factChagesWasDetected really used? */
                                if (state.pgridSettings.attestRequired) {

                                    if (isWritable && !(isReadOnly === true)) {

                                        window.PGridClientDebugMode >= 3 && console.warn(`This writable cell was changed (source: ${source}): ${JSON.stringify(pgridData_After[saveDiff_TypeRowKey][saveDiff_TypeRowCol])} `);

                                        await PGridUtils.DirtyGridFlag(context, `${source} -> Mutation_SaveNewHotDataToPgridData()`);

                                        if (state.pgridSettings.attestStatusOK && state.pgridSettings.hasUnsavedFactChangesForUrl != null) {

                                            //No longer OK
                                            context.commit('Mutation_UpdatePGridSettings', { prop: 'attestStatusOK', val: false, source: `${source} -> Mutation_SaveNewHotDataToPgridData` });

                                            ret.factChagesWasDetected = true;

                                        }
                                    }

                                }
                            }
                        }
                    }


                    window.PGridClientDebugMode > 2 && console.debug("Diff:" + JSON.stringify(saveDiff, null, 3));
                } catch (err) {
                    console.debug(`Diff: faild: ${err.message || err} `);
                }
            }

            ret.pgridDataSaved = pgridData_After;
            ret.hotData = newTableOrChanges.hotData;

        }


        return ret;
    },

    /*
    Declares:
        window.PGridVueStore
        window.PGridConfig
        window.PGridVueDump()
    */

    async Action_DeclareWindowPGridVueStore(context, { config, source }) {
        window.PGridVueStore = context;
        window.PGridConfig = config;

        false && console.log(`Action_DeclareWindowPGridVueStore: source: ${source} `)

        //   *********** PGridMonitorDataBox_StartIntervall start ********** //
        window.PGridMonitorDataBox_StartIntervall = function (rowStart = 7, rowEnd = 17, colStart = 3, colEnd = 8) {

            window.PGridMonitorDataBox_clearIntervallId = setInterval(() => {
                window.PGridMonitorDataBox_OnIntervall(rowStart, rowEnd, colStart, colEnd);
            }, 500);
        }

        window.PGridMonitorDataBox_OnIntervall = function (rowStart, rowEnd, colStart, colEnd) {

            function pad(width, str, padding) {
                return (width <= str.length) ? str : pad(width, padding + str, padding)
            }

            if (PGridVueStore.state.pgridDataDynContent) {


                let finalRes = "";

                let pgridDataIn = PGridVueStore.state.pgridDataDynContent

                for (var y = 0; y < pgridDataIn.length; y++) {

                    let doRow = (y >= rowStart && y <= rowEnd);

                    let dbgRow = `[${pad(3, String(y), ' ')}] `;

                    if (doRow) {
                        for (var x = 0; x < pgridDataIn[0].length; x++) {


                            if (x >= colStart && x <= colEnd) {
                                let cellData = pgridDataIn[y][x];
                                let cellTxt = String(PGridCell.getHotValue2(cellData));
                                dbgRow += "'" + pad(10, cellTxt, ' ') + "'" + ",";
                                // dbgRow += "'" + cellTxt + "'" + ",";
                            }
                        }
                        finalRes += dbgRow + "\n";
                    }

                }

                if (!("PGridMonitorDataBox_OnIntervall_lastFinalRes" in window)) {
                    window.PGridMonitorDataBox_OnIntervall_lastFinalRes = finalRes;
                }

                if (window.PGridMonitorDataBox_OnIntervall_lastFinalRes != finalRes) {
                    window.PGridMonitorDataBox_OnIntervall_lastFinalRes = finalRes;
                    console.debug(finalRes);
                }

            }
        }
        //   *********** PGridMonitorDataBox_StartIntervall end ********** //


        //   *********** PGridMonitorDelayAfterChange_StartIntervall start ********** //
        window.PGridMonitorDelayAfterChange_StartIntervall = function () {

            window.PGridMonitorDelayAfterChange_clearIntervallId = setInterval(() => {
                window.PGridMonitorDelayAfterChange_OnIntervall();
            }, 200);
        }

        window.PGridMonitorDelayAfterChange_OnIntervall = function () {

            function pad(width, str, padding) {
                return (width <= str.length) ? str : pad(width, padding + str, padding)
            }

            if (PGridVueStore.state.pgridSettings) {


                let finalRes = "";

                let delayedAfterChangeChanges = PGridVueStore.state.pgridSettings.delayedAfterChangeChanges;

                finalRes = JSON.stringify(delayedAfterChangeChanges, null, 2);

                if (!("PGridMonitorDelayAfterChange_OnIntervall_lastFinalRes" in window)) {
                    window.PGridMonitorDelayAfterChange_OnIntervall_lastFinalRes = finalRes;
                }

                if (window.PGridMonitorDelayAfterChange_OnIntervall_lastFinalRes != finalRes) {
                    window.PGridMonitorDelayAfterChange_OnIntervall_lastFinalRes = finalRes;
                    console.debug(`PGridMonitorDelayAfterChange\n` + finalRes);
                }

            }
        }
        //   *********** PGridMonitorDelayAfterChange_StartIntervall end ********** //


        window.PGridVueDump = function (opt = { includeDim: true, includeData: false, includeRefs: false }) {

            console.debug("PGridVueDump() called");

            let dump = {};
            Object.keys(state).filter(x =>
                (x != "hotRef")
                && (opt.includeData || x != "pgridData" && x != "pgridDataDynContent")
                && (opt.includeDim || x != "pgridDim")
                && (x != "customCss")
                && (x != "aceCssEditorRef")
                && (x != "pgridfiltersRef")
            ).forEach(lvl1Key => {

                console.log("working with key:" + lvl1Key)

                let lvl1 = state[lvl1Key];

                let lvl1_clone = lodash_cloneDeep(lvl1);


                dump[lvl1Key] = lvl1_clone;

                if (lvl1) {
                    Object.keys(lvl1)

                        .filter(x => (!opt.includeData && lvl1Key == "hotSettings" && (
                            x == "data"
                            || x == "hotRef"
                            || x == "spanCells"))
                            ||
                            (!opt.includeRefs && lvl1Key == "pgridSettings" && (x == "cellsThatAreReferenced"))
                            // ||
                            // (!opt.includeData && lvl1Key == "pgridDataRowsDBCache" && (x == "Rows"))
                        )

                        .forEach(lvl2KeyDel => {
                            dump[lvl1Key][lvl2KeyDel] = "<cencored>"

                            // let decycled2 = JSON.decycle(dump);
                            // console.log(`lvl1Key: ${lvl1Key} lvl2KeyDel:${lvl2KeyDel}`);
                            // let testThis2 = JSON.stringify(decycled2, null, 2);

                        });
                }

                if (lvl1) {
                    Object.keys(lvl1).forEach(lvl2KeySort => {

                        let lvl2 = lvl1[lvl2KeySort];

                        if (lvl2 && typeof lvl2 === 'object' && !Array.isArray(lvl2)) {
                            lvl2 = Object.keys(lvl2).sort(function (a, b) { return JSON.stringify(lvl2[a]) - JSON.stringify(lvl2[b]) })
                        }

                        // dump[lvl1Key][lvl2KeySort] = "<cencored>"

                        // let decycled2 = JSON.decycle(dump);
                        // console.log(`lvl1Key: ${lvl1Key} lvl2KeyDel:${lvl2KeyDel}`);
                        // let testThis2 = JSON.stringify(decycled2, null, 2);

                    });
                }
            })


            dump.columnFilter = { colFilterRows: window.colFilterRows, filter_firstRow: window.filter_firstRow, filter_lastRow: window.filter_lastRow, compiledFilterRows: window.compiledFilterRows }

            // console.log("Decycle json");
            // let decycled = JSON.decycle(dump);
            // console.log(JSON.stringify(decycled, null, 2));
            console.log(JSON.stringify(dump, null, 2));
        }

        window.PGridCellsDump = function () {
            console.log({ 'pgridDataDynContent': state.pgridDataDynContent, 'hotData': state.hotRef.getData() });
        }
    },
    async Action_Redraw(context, opt = { source: '<unkonwn>', force: false }) {

        if (!("force" in opt)) {
            opt.force = false;
        }

        window.PGridClientDebugMode >= 2 && console.debug(`Action_Redraw got called from: ${opt.source} `);

        PGridHT.Redraw(context, { source: `${opt.source} -> Action_Redraw()`, force: opt.force });
    },
    async Action_ReConfig(context, { configUpdate = null, source = null }) {
        window.PGridClientDebugMode >= 2 && console.debug(`Action_Redraw got called from: ${source} `);

        PGridHT.ReConfig(context, { configUpdate: configUpdate, source: `${source} -> Action_Redraw()` });
    },
    async Action_SwitchEditMode(context, { source = "unknown for Action_SwitchEditMode" }) {
        window.PGridClientDebugMode >= 3 && console.debug(`Action_SwitchEditMode(): source: ${source}`);

        let neweditMode = !context.state.pgridSettings.PGridTableEditMode;
        context.commit('Mutation_UpdatePGridSettings', { prop: "PGridTableEditMode", val: neweditMode, source: `${source} -> Action_SwitchEditMode()` });

        if (context.state.pgridSettings.inputBar_Hidden_Restore == null) {
            context.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_Hidden_Restore', val: context.state.pgridSettings.inputBar_Hidden, source: `${source} -> Action_SwitchEditMode()` });

        }

        if (neweditMode == false) {
            context.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_Hidden', val: context.state.pgridSettings.inputBar_Hidden_Restore, source: `${source} -> Action_SwitchEditMode()` });
        }
        else {
            context.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_Hidden', val: false, source: `${source} -> Action_SwitchEditMode()` });
            if (context.state.pgridSettings.inputBar_BarMode == 'none') {
                context.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_BarMode', val: 'small', source: `${source} -> Action_SwitchEditMode()` });
            }
        }

        await context.dispatch("Action_Redraw", { source: `${source} -> Action_SwitchEditMode()` });


        // let rootEl = document.querySelector("#pgrid-root");
        // if (rootEl) {
        //     if (neweditMode) {
        //         rootEl.classList.add("pgrid-editor-mode");
        //         rootEl.classList.remove("pgrid-normal-mode");
        //     } else {
        //         rootEl.classList.add("pgrid-normal-mode");
        //         rootEl.classList.remove("pgrid-editor-mode");
        //     }
        // }


        // if (context.state.pgridSettings.PGridTableDebugMode_HasBeenActive) {
        //     await context.dispatch("Action_Redraw", { source: `${source} -> Action_SwitchEditMode()` });
        // } else {
        //     //Only re-calculate if edit mode has not been activated before
        //     context.commit('Mutation_UpdatePGridSettings', { prop: "PGridTableDebugMode_HasBeenActive", val: true, source: `${source} -> Action_SwitchEditMode()` });
        //     await context.dispatch("Action_InjectAndRendrFilters", { type: "switchTableDebugMode", source: `${source} -> Action_SwitchEditMode()` });
        // }
    },




    async Action_UpdateDebugPanel(context, { newShowPanel, source = "unknown for Action_UpdateDebugPanel" }) {
        // action_pgridUpdatePanel(context, newShowPanel) {
        window.PGridClientDebugMode >= 2 && console.debug("Vuex Action: Action_UpdateDebugPanel: " + newShowPanel);// + " cellSelection: " + cellSelection);
        context.commit('Mutation_UpdatePGridSettings', { "prop": "showPanel", "val": newShowPanel });

        // context.commit('Mutation_UpdatePGridSettings', {
        //     prop: 'cellsThatAreReferencingLinkedRangeOverlays'
        //     , op: 'addkey'
        //     , key: `overlay_selectionchange:${If_Cell_Is_Selected__CellToExamine.y}:${If_Cell_Is_Selected__CellToExamine.x}`
        //     , mergeValue: true
        //     , val: [`${this.name}:${this.y}:${this.x}`], source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
        // });

        await PGridUtils.sleep(500);

        PGridUtils.hideGhostPanelHosts(); //Bugfix tweek, will create multiple ms-PanelHost div if not removed
        let lastSelected_saveToForceUpdate = context.state.lastSelected;

        await PGridUtils.sleep(0);
        context.commit("Mutation_UpdateRoot", {
            prop: "lastSelected",
            val: null,
            source: `${source} -> Action_UpdateDebugPanel() `
        });

        await PGridUtils.sleep(0);
        context.commit("Mutation_UpdateRoot", {
            prop: "lastSelected",
            val: lastSelected_saveToForceUpdate,
            source: `${source} -> Action_UpdateDebugPanel() `
        });


    },

}

/*
*
*   Mutations
*
*/

const mutations = {

    Mutation_Reset_GlobalState: (state, { ignoreList = [], onlyList = null }) => {
        console.log("Mutation_Reset_GlobalState reseting state");
        Object.keys(state).forEach(key => {
            if (key in state) {

                if (onlyList != null) {
                    if (onlyList.indexOf(key) != -1) {
                        state[key] = state[key];
                    }
                } else if (ignoreList.indexOf(key) > -1) {
                    state[key] = state[key];
                }
                else if (state[key] == null) {
                    //Object.assign does not work if first argument is null
                    state[key] = initState[key];
                }
                else if (initState[key] == null) {
                    //Object.assign does not work if second argument is null
                    state[key] = initState[key];
                } else {
                    Object.assign(state[key], initState[key]);
                }
            }
        })

        let debugHelper = "foobar";
    },


    Mutation_UpdateRoot(state, { prop, val, op = "set", source }) {

        let nonReactive = false;
        let finalVal = null;

        if (prop == 'pgridDataDynContent') {

            nonReactive = true;
            window.PGridClientDebugMode >= 2 && console.debug(`Mutation_UpdateRoot ${prop} = ...source: ${source}`);
        }

        if (prop == 'pgridData') {
            nonReactive = true;
        }

        if (op == "push") {

            let oldVal = null;
            if (prop[prop] === undefined || state[prop] === null) {
                oldVal = [];
            }
            else {
                oldVal = state[prop];
            }

            let newVal = oldVal;
            newVal.push(val);
            finalVal = newVal;
        } else if (op === "add") {
            finalVal = state[prop] + val;
        } else {
            finalVal = val;
        }

        if (nonReactive) {
            state[prop] = Object.freeze(finalVal);
        } else {
            // VueSetIfReactive(state, prop, finalVal);

            if (prop == 'pgridLRCoordinatesHints') {
                // makeNonreactive2(finalVal);
                state[prop] = finalVal;
                PGridHT.makeNonreactive2(state[prop]);

            } else {

                Vue.set(state, prop, finalVal);
            }
        }
    },


    Mutation_UpdatePGridVars(state, { prop, val, source }) {
        window.PGridClientDebugMode >= 3 && console.debug(`Mutation_UpdatePGridVars ${prop} = ...source: ${source} `);

        // Vue.set(state.pgridVars, prop, val);
        VueSetIfReactive(state.pgridVars, prop, val);
    },



    Mutation_UpdateHOTSettings(state, { prop, val, render = false, op = "set", source }) {

        window.PGridClientDebugMode >= 3 && console.log(`Mutation_UpdateHOTSettings - setting prop: '${prop}' ${typeof val != 'object' ? 'Val: ' + val : "object"} op: '${op}' render: ${render} source: ${source} `);

        if (op == "push") {

            let oldVal = state.hotSettings[prop];
            let newVal = lodash_clone(oldVal);

            newVal.push(val);
            // Vue.set(state.hotSettings, prop, newVal);
            VueSetIfReactive(state.hotSettings, prop, newVal);

        } else {


            if (prop == "data") {

                if (val == null) {
                    if (state.hotRef != null) {
                        window.PGridClientDebugMode >= 2 && console.debug(`Mutation_UpdateHOTSettings() destroying HOT`);
                        PGridHT.Destroy({ source: `${source} -> Mutation_UpdateHOTSettings` });
                        // Vue.set(state, 'hotRef', null);
                        VueSetIfReactive(state, 'hotRef', null);
                    }
                    //Can still destroy "data", eaven without hotRef
                    // Vue.set(state.hotSettings, prop, val);


                    (state.hotSettings, prop, val);
                }
                else {

                    if (state.hotRef == null) {
                        // console.warn(`You should not have to load HOT data as instance is null, only set in hotSettings.source: ${ source } `);
                        // console.debug(`Mutation_UpdateHOTSettings state.hotRef == null, but hotSettings.data was set: ${ source } `);
                        // Vue.set(state.hotSettings, prop, val);
                        VueSetIfReactive(state.hotSettings, prop, val);
                    } else {
                        window.PGridClientDebugMode >= 3 && console.debug(`Mutation_UpdateHOTSettings loading new data source: ${source}`);
                        // Vue.set(state.hotSettings, "DisableEvents", true);
                        VueSetIfReactive(state.hotSettings, "DisableEvents", true);

                        // Vue.set(state.hotSettings, prop, val);
                        (state.hotSettings, prop, val);

                        window.PGridClientDebugMode >= 3 && console.debug(`Mutation_UpdateHOTSettings hotSettings.data was set: ${source}`);

                        PGridHT.Update({ source: `${source} -> Mutation_UpdateHOTSettings` });
                        window.PGridClientDebugMode >= 3 && console.debug(`Mutation_UpdateHOTSettings PGridHT.Update() was run: ${source}`);

                        // Vue.set(state.hotSettings, "DisableEvents", false);
                        VueSetIfReactive(state.hotSettings, "DisableEvents", false);

                    }
                }
            } else {
                //All other props that is not 'data'
                // Vue.set(state.hotSettings, prop, val);
                VueSetIfReactive(state.hotSettings, prop, val);

                /* Update of HOT settings after init is not implemented or tested */
                if (state.hotRef != null) {
                    state.hotRef.updateSettings(state.hotSettings, false);
                }

            }
        }

        if (render) {
            false && console.debug(`Mutation_UpdateHOTSettings will render()`);

            PGridHT.Init({ source: `${source} -> Mutation_UpdateHOTSettings() prop: '${prop}'` });

            window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #2', PGridVueStore.state.hotRef.getData().length);

        }
    },

    Mutation_UpdateMimicaAppSettings(state, { prop, val, op = "set", source }) {

        window.PGridClientDebugMode >= 3 && console.log(`Mutation_UpdateMimicaAppSettings - setting prop: '${prop}' ${typeof val != 'object' ? 'Val: ' + val : "object"} op: '${op}' source: ${source} `);

        VueSetIfReactive(state.mimicaAppSettings, prop, val);

    },

    //FUB Temporary solution, using window object should be removed in future
    Mutation_UpdatePGridWindow(state, { prop, val, op = "set", source, key = null, mergeValue = false }) {

        //This is applyed with Mutation_UpdatePGridSettings using "applyToWindow: true"
        /*
        if (window) {
            window[prop] = val;
        }
        */

        if (prop == "PGridPerformanceMode_Heights") {
            let foo = "bar";
        }

        let prop2 = "window_" + prop;
        this.commit('Mutation_UpdatePGridSettings', { prop: prop2, val, op, source, key, mergeValue, applyToWindow: false });
        this.commit('Mutation_UpdatePGridSettings', { prop, val, op, source, key, mergeValue, applyToWindow: true });
    },

    Mutation_UpdatePGridSettings(state, { prop, val, op = "set", source, key = null, mergeValue = false, applyToWindow = false }) {

        try {


            if (prop == "isLoading") {


                if (state.pgridSettings.IsLoadingDisabled) {
                    window.PGridClientDebugMode >= 3 && console.debug(`IsLoadingDisabled will not show load screen. source: ${source}`)
                    return;
                }

            }

            let newVal = null;
            let finalNewVal = null;

            newVal = val;


            if (prop.indexOf("delayedAfterChangeChanges") != -1) {
                window.PGridClientDebugMode >= 2 && console.debug(`Setting *********** delayedAfterChangeChanges ************ ${prop} -> ${JSON.stringify(newVal)} source: ${source}`)
            }

            if (prop.indexOf("calcOrderCache") != -1) {
                window.PGridClientDebugMode >= 3 && console.debug(`Setting new calcOrderCache -> ${newVal} source: ${source}`)

                // newVal = Object.keys(newVal).sort(function (a, b) { return newVal[a] - newVal[b] })
                // window.PGridClientDebugMode >= 1 && console.warn(`Setting new calcOrderCache ******************* DOING NOT NECESSARY SOTING **************`);
            }


            if (prop.indexOf("isSaving") != -1) {
                window.PGridClientDebugMode >= 3 && console.debug(`Setting isSaving mode ${prop} -> ${newVal} source: ${source}`)
            }

            if (prop.indexOf("DisableEvents") != -1) {
                window.PGridClientDebugMode >= 3 && console.debug(`Setting EVENT mode ${prop} -> ${newVal} source: ${source}`)
            }

            if (prop == 'initOfLastQuery_Timestamp') {
                window.PGridClientDebugMode >= 3 && console.debug(`\n\n****** Setting NEW initOfLastQuery_Timestamp: ${newVal} source: ${source}\n`)
            }
            if (prop == 'initOfLastQuery_Url') {
                window.PGridClientDebugMode >= 3 && console.debug(`\n\n****** Setting NEW initOfLastQuery_Url: \n\nOLD: ${state.pgridSettings.initOfLastQuery_Url == null ? null : state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nNEW: ${newVal.split(`&`).join(`\n`)}\n\nsource: ${source}\n`)
            }


            if (applyToWindow == false && !(prop in state.pgridSettings)) {
                throw new Error(`Mutation_UpdatePGridSettings: there is no property named ${prop} in state.pgridSettings`);
            }


            if (applyToWindow) {


                if (op === "push") {

                    if (newVal === null) {
                        finalNewVal = newVal;
                    } else {
                        let oldVal = window[prop];

                        if (!(Array.isArray(oldVal))) {
                            oldVal = [];
                        }
                        oldVal.push(newVal);
                        finalNewVal = oldVal;
                    }
                } else if (op == "set") {
                    finalNewVal = newVal;
                } else {
                    throw new Error(`Mutation_UpdatePGridSettings() Unknown op '${op}'`);
                }

                window[prop] = finalNewVal;

                //     if (prop == "window_PGridClientDebugMode") {
                //         window.PGridClientDebugMode >= 3 && console.debug(`PGridClientDebugMode set to ${newVal}`);
                //         window.PGridClientDebugMode = newVal;
                //     }

                // if (prop == "window_PGridPerformanceMode") {
                //     window.PGridClientDebugMode >= 3 && console.debug(`PGridPerformanceMode set to ${newVal}`);
                //     window.PGridPerformanceMode = newVal;
                // }
                // if (prop == "window_PGridPerformanceMode_Heights") {
                //     window.PGridClientDebugMode >= 3 && console.debug(`PGridPerformanceMode set to ${newVal}`);
                //     console.warn(`window_PGridPerformanceMode_Heights cannot be changed` /*does not seems to work*/);
                //     window.PGridPerformanceMode_Heights = newVal;
                // }
            } else {


                if (op === "subtract") {
                    finalNewVal = state.pgridSettings[prop] - newVal;
                } else if (op === "add") {
                    finalNewVal = state.pgridSettings[prop] + newVal;
                } else if (op === "merge") {
                    finalNewVal = lodash_merge({}, state.pgridSettings[prop], newVal);
                } else if (op === "push") {
                    finalNewVal = lodash_clone(state.pgridSettings[prop]);
                    finalNewVal.push(newVal);
                } else if (op === "addkey") {

                    if (mergeValue && state.pgridSettings[prop] && key in state.pgridSettings[prop]) {
                        //Merge current value (if exists) with the new
                        // let oldInnerVal = lodash_clone(state.pgridSettings[prop][key]);
                        let oldInnerVal = state.pgridSettings[prop][key];
                        if (Array.isArray(oldInnerVal)) {

                            newVal = lodash_union(oldInnerVal, val);

                            // finalNewVal = lodash_clone(state.pgridSettings[prop])
                            finalNewVal = state.pgridSettings[prop];
                            finalNewVal[key] = newVal;

                        } else {
                            throw new Error(`Mutation_UpdatePGridSettings() invalid/unsupported merge object type`);
                        }
                    } else {
                        //No merge
                        // finalNewVal = lodash_clone(state.pgridSettings[prop])
                        finalNewVal = state.pgridSettings[prop];
                        if (finalNewVal === null) {
                            finalNewVal = {};
                        }
                        finalNewVal[key] = newVal;
                    }

                } else if (op == "set") {
                    finalNewVal = newVal;
                } else {
                    throw new Error(`Mutation_UpdatePGridSettings() Unknown op '${op}'`);
                }

                // Vue.set(state.pgridSettings, prop, newVal);
                VueSetIfReactive(state.pgridSettings, prop, finalNewVal);
            }
        } catch (err) {
            let errMsg = `Mutation_UpdatePGridSettings() Got an exception: { prop: ${prop}, val: ${val}, op: ${op}, source: ${source}, key: ${key}, mergeValue: ${mergeValue} }: ${err.message}`;
            (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> Mutation_UpdatePGridSettings()` }) : alert(errMsg);
        }
    },

    mutation_updatePgridSettings(state, updateObj) {

        window.PGridClientDebugMode >= 3 && console.debug(`Consider soon migration of 'mutation_updatePgridSettings' -> 'Mutation_UpdatePGridSettings' ${JSON.stringify(updateObj)}`);
        // window.PGridClientDebugMode >= 1 && console.debug(`mutation_updatePgridSettings: ${JSON.stringify(updateObj)}`);

        let oldValue = state.pgridSettings[updateObj.prop];
        let newValue = null;

        if ("add" in updateObj) {
            //Is incemention of settings
            // state.pgridSettings[updateObj.prop] += updateObj.add;
            newValue = state.pgridSettings[updateObj.prop] + updateObj.add;
            window.PGridClientDebugMode >= 3 && console.debug(`mutation_updatePgridSettings add: ${updateObj.prop} ${oldValue} -> ${state.pgridSettings[updateObj.prop]} `)
        }
        else if ("subtract" in updateObj) {
            //Is incemention of settings
            // state.pgridSettings[updateObj.prop] -= updateObj.subtract;
            newValue = state.pgridSettings[updateObj.prop] - updateObj.subtract;
            window.PGridClientDebugMode >= 3 && console.debug(`mutation_updatePgridSettings subtract: ${updateObj.prop} ${oldValue} -> ${state.pgridSettings[updateObj.prop]} `)
        } else {
            // state.pgridSettings[updateObj.prop] = updateObj.value;
            newValue = updateObj.value;
            window.PGridClientDebugMode >= 3 && console.debug(`mutation_updatePgridSettings update: ${updateObj.prop} ${oldValue} -> ${state.pgridSettings[updateObj.prop]} `)
        }
        VueSetIfReactive(state.pgridSettings, updateObj.prop, newValue);

    },
    Mutation_FiltersUpdate(state, { prop, val, source = null }) {
        try {
            window.PGridClientDebugMode >= 2 && console.debug(`
                (${JSON.stringify({ prop, source })})`);
            // Vue.set(state.pgridFilter, prop, val);

            if (source != "PreFilterRendr()" && prop == "Filters" && state.pgridFilter && Array.isArray(state.pgridFilter.Filters) && state.pgridFilter.Filters.length > 0) {

                for (let i = 0; i < state.pgridFilter.Filters.length; i++) {

                    let updateFilter = true;

                    if (state.pgridFilter.Filters[i].filterValue !== val[i].filterValue) {
                        //Keep updateFilter = true;
                    }
                    else if (val[i].filterOptions == null || val[i].filterOptions.length == 0) {
                        //Keep updateFilter = true;
                    }
                    else if (JSON.stringify(val[i].filterOptions) != JSON.stringify(state.pgridFilter.Filters[i].filterOptions)) {
                        //Keep updateFilter = true;
                    }
                    else {
                        updateFilter = false;
                    }

                    if (updateFilter) {
                        state.pgridFilter.Filters.splice(i, 1, val[i]);
                    }

                }

            }
            else
                VueSetIfReactive(state.pgridFilter, prop, val);
        } catch (err) {
            let errMsg = `> Mutation_FiltersUpdate() got exception: ${err.message || err} source: ${source}`;
            throw new Error(errMsg);
        }
    }
}


export default new Vuex.Store({
    state,
    actions,
    mutations,
})


