<template>
  <div class="pgrid-filters" ref="pgridfilters">
    <div class="pgrid-filter-list-container" ref="pgridfilterslistcontainer">
      <div class="pgrid-filter-list">
        <pgrid-filter-chooser
          v-for="(f, idx1) in this.$store.state.pgridFilter.Filters"
          :key="`${idx1}-${f.title}`"
          v-bind:filterIdx="idx1"
          v-on:filterUpdate="handleFilterChange($event)"
        />

        <ou-button
          v-for="(btn, idx2) in this.$store.state.pgridFilter.DynamicButtons"
          :key="`${idx2}-${btn.Title}`"
          type="primary"
          class="stack-inline pgrid-save-button noprint"
          @click="uiDynButtonPush(btn)"
          v-bind:disabled="
            btn.Disabled ||
            pgridSettings.IsMissingFilterSelectionCount != 0 ||
            (btn.Disabled_WHEN_NO_CHANGES && pgridSettings.hasUnsavedFactChangesForUrl == null) ||
            pgridSettings.isSaving ||
            pgridSettings.isLoading
          "
          v-show="btn.Visible && pgridSettings.viewMode == 'Default'"
          >{{ btn.Title }}</ou-button
        >
      </div>
      <!-- v-bind:my-available-width="freeSpace_guidance" -->
      <!-- <ou-button
          v-for="(btn, idx3) in this.$store.state.pgridFilter.DynamicButtons"
          :key="`${idx3}-FOO-${btn.Title}`"
          type="primary"
          class="stack-inline pgrid-save-button noprint"
          @click="uiDynButtonPush(btn)"
          v-bind:disabled="
            btn.Disabled || (btn.Title != 'PUBLICERA' && pgridSettings.hasUnsavedFactChangesForUrl == null) || pgridSettings.isSaving
          "
          v-show="btn.Visible && pgridSettings.viewMode == 'Default'"
          >{{ btn.Title }}</ou-button
        > -->
    </div>
    <pgrid-guidance
    v-for="(f, idx1) in this.$store.state.pgridFilter.Guidances"
          :key="`guidance-nr-${idx1}`"
          v-bind:guidanceIdx="idx1"
      />
      <!-- v-bind:myContentHtml="this.$store.state.pgridFilter.GuidanceHtml" -->
  <div style="clear: both; display: block"></div>
  </div>
</template>
<script>
import Vue from "vue/dist/vue.js";
import {
  clone as lodash_clone,
  cloneDeep as lodash_cloneDeep,
  mergeWith as lodash_mergeWith,
  isEqual as lodash_isEqual,
  isNumber as lodash_isNumber,
  filter as lodash_filter,
  find as lodash_find,
  get as lodash_get,
  has as lodash_has,
  merge as lodash_merge,
  set as lodash_set,
  union as lodash_union,
  unionBy as lodash_unionBy,
  uniq as lodash_uniq,
  uniqBy as lodash_uniqBy,
} from "lodash";
import PgridFilterChooser from "./pgrid-filter-chooser.vue";
import PGridGuidance from "./pgrid-guidance.vue";
import PGridUtils from "./pgridUtils";

import PGridDynExtract from "./pgridDynExtract.js";
// const PGridDynExtract = require("./pgridDynExtract");
import PGridHandlers from "./pgridHandlers";

export default {
  name: "pgrid-filters",

  data: function () {
    return {
      pgridSettings: this.$store.state.pgridSettings,
      // freeSpace_guidance: 240, //0,
      // freeSpace_guidance_margin: 30, //Smaller value may result that Version filter may bounce down the pgrid-guidance after save, if it gets longer filter value text
      // show_guidance: true, //false,
      // show_guidance_threshold: 150,
    };
  },

  mounted: async function () {
    /******************************************* */
    //Check this out: https://jsfiddle.net/8wg7o6c6/
    /******************************************* */

    let context = this.$store;

    await PGridHandlers.Handle_FilterMounted(context);

    context.commit("Mutation_UpdateRoot", { prop: "pgridfiltersRef", val: this, source: `pgrid-filters.vue.mounted()` });

    this.handleFilterRowResize();
    window.addEventListener("resize", this.handleFilterRowResize);

    let rootContainer = document.querySelector("#pgrid-root-container");
    if (rootContainer) {
      rootContainer.addEventListener("click", this.handleFilterDeselectHelper);
    }
  },

  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleFilterRowResize);

    let rootContainer = document.querySelector("#pgrid-root-container");
    if (rootContainer) {
      rootContainer.removeEventListener("click", this.handleFilterDeselectHelper);
    }
  },
  methods: {
    handleFilterChange(filterUpdate) {
      let context = this.$store;
      PGridUtils.HandleFilterSelectionChange(context, filterUpdate);
    },
    applyButtonClick() {
      this.$store.dispatch("action_pgridFiltersHandleUpdates", {
        source: "applyButtonClick",
        isApply: true,
      });
    },

    // uiButtonSaveFact: async function (event) {
    //   let context = this.$store;
    //   await PGridUtils.HandleButtonPress("save", context);
    // },

    // uiButtonPublishFact: async function (event) {
    //   let context = this.$store;

    //   await PGridUtils.HandleButtonPress("publish", context);
    // },

    uiDynButtonPush: async function (dynBtn) {
      let context = this.$store;

      /* Delay HandleButtonPress untill we are sure no pending cell editing, loading or */
      /* isApplyGridChange (reqired for non referenced cells) are whaiting for processing */
      const trySaveMaxCount = 100;
      let tryCount = 0;
      while (
        tryCount < trySaveMaxCount &&
        (context.state.pgridSettings.isCellEditing ||
          context.state.pgridSettings.isLoading ||
          context.state.pgridSettings.isSaving ||
          context.state.pgridSettings.isApplyGridChange)
      ) {
        await PGridUtils.sleep(50);
        tryCount++;
      }

      if (tryCount >= trySaveMaxCount) {
        alert(
          `Faild to trigger button action as calculation was not finished, or cell edit not finished, within timeout.\n\nTry press button again`
        );
      } else {
        window.PGridClientDebugMode >= 2 && console.debug(`uiDynButtonPush(): ${dynBtn.Title}`);

        context.commit("Mutation_UpdatePGridSettings", { prop: "isSaving", val: true, source: `uiDynButtonPush(): ${dynBtn.Title}` });

        await PGridUtils.HandleButtonPress(dynBtn, context);
      }
    },

    handleFilterRowResize(event) {
      try {
        let context = this.$store;

        const filterHeight = 58;

        let filterRowDOM = document.querySelectorAll(".pgrid-filter-list");
        let filterRowHeight = lodash_get(filterRowDOM, "[0].offsetHeight", 0); //lodash_get(document.querySelectorAll(".pgrid-filter-list"), "[0].offsetHeight", null);
        let filterRowOffsetTop = lodash_get(filterRowDOM, "[0].offsetTop", 0);

        let guidanceRowDOM = document.querySelectorAll(".pgrid-guidance");
        let guidanceRowOffsetTop = lodash_get(guidanceRowDOM, `[${guidanceRowDOM.length-1}].offsetTop`, 0);

        let nrOfFilterRows = Math.floor(filterRowHeight / filterHeight);

        if (guidanceRowOffsetTop > filterRowOffsetTop) {
          nrOfFilterRows++; //GuidanceHTML has generated a new row. Make it count
        }

        context.commit("Mutation_UpdatePGridSettings", {
          prop: "filterAndGuidanceList_RowsMode",
          val: `filterrows${nrOfFilterRows}`, //filterrows0, filterrows1, filterrows2...
          source: `handleFilterRowResize()`,
        });


        window.PGridClientDebugMode >= 3 && console.debug(`handleFilterRowResize if working.... DISABLED!`); //FUB
      } catch (e) {
        console.error(`handleFilterRowResize(): got error: ${err.message || err}`);
      }
    },
    handleFilterDeselectHelper(event) {
      /* Needed to make dopdown to de-select when clicking on the area under the grid */
      let context = this.$store;

      let found_wtHolder = false;
      let found_wtHider = false;

      let cp = event.composedPath();
      let cp_length = cp.length;

      for (let i = 0; i < cp_length; i++) {
        let el = cp[i];
        let elClassName = el.className;

        if (elClassName == "wtHolder") {
          found_wtHolder = true;
        }
        if (elClassName == "wtHider") {
          found_wtHider = true;
        }
      }

      if (found_wtHolder === true && found_wtHider == false) {
        context.state.hotRef.selectCell(0, 0);
        context.state.hotRef.deselectCell();
      }
    },
  },
  components: { "pgrid-filter-chooser": PgridFilterChooser, "pgrid-guidance": PGridGuidance },
};
</script>
<style>
.pgrid-filter-list {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  float: left;

  /* flex-wrap: nowrap; */
  flex-wrap: wrap;

  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.pgrid-filter-item {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 6px;

  /* min-width: 330px; */
  font-size: 15px;
}

button.pgrid-save-button {
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 6px;
  /* width: 100px; */
}

.pgrid-filters button.pgBackButton {
  height: 48px;
  min-width: 48px;

  padding-left: 2px;
  padding-right: 4px;
  padding-top: 5px;

  margin-top: 4px;
  margin-right: 4px;
}
</style>
