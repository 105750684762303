<template>
  <div>
    <div class="pgrid-inputbar noprint" v-show="!($store.state.pgridSettings.inputBar_Hidden == true)" :class="'barmode-' + barModeName">
      <div class="pgrid-inputbar-box">
        <div class="pgrid-formula-label">&#402;&#8339;</div>
      </div>
      <div class="pgrid-inputbar-box">
        <textarea
          class="pgrid-textarea"
          v-bind="$attrs"
          v-model="textareaValue"
          v-bind:rows="barModeCols"
          @keydown.esc="escPressed"
          @keydown.enter="enterPressed"
          @focus="hasFocus = true"
          @blur="hasFocus = false"
          v-on:keyup="handleCursorChange()"
          ref="textareaRef"
        />
      </div>
      <div class="pgrid-inputbar-box">
        <button class="pgrid-textarea-barmode" v-on:click="toggleExpand">
          <svg
            id="pgrid-textarea-arrow-down"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            :height="barMode === 'small' ? '12' : '26'"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-down"
          >
            <polyline v-if="barMode === 'small'" points="6 9 12 15 18 9" transform="rotate(0 12 12)" />
            <polyline v-if="barMode === 'medium'" points="6 7 12 13 18 7" />
            <polyline v-if="barMode === 'medium'" points="6 17 12 23 18 17" />
            <polyline v-if="barMode === 'large'" points="6 7 12 13 18 7" transform="rotate(180 12 14)" />
            <polyline v-if="barMode === 'large'" points="6 17 12 23 18 17" transform="rotate(180 12 14)" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import PGridUtils from "./pgridUtils";
import PGridCell from "./pgridCell";

export default {
  name: "textarea-pgrid",
  props: { inputBar_newSelectedCellVal: { default: "", type: String } },
  data: function () {
    return {
      barMode: "small",
      // lastKeyStrokeShiftWasPressed: true,
      editValue: this.inputBar_SelectCellVal,
      editCellCoord: null,
    };
  },

  mounted: function () {
    false && console.debug("pgrid-textarea mounted");

    this.$store.commit("Mutation_UpdatePGridSettings", {
      prop: "inputBar_inputRef",
      val: this.$refs.textareaRef,
      source: "pgrid-textarea mounted",
    });
  },

  computed: {
    hasFocus: {
      get: function () {
        return false;
      },
      set: function (updateValue) {
        setTimeout(() => {
          this.$store.commit("Mutation_UpdatePGridSettings", {
            prop: "inputBar_HasFocus",
            val: updateValue,
            source: "hasFocus set",
          });

          if (updateValue == true && this.$store.state.lastSelected) {
            if (this.editCellCoord == null) {
              let { y, x } = JSON.parse(this.$store.state.lastSelected);

              this.editCellCoord = { y, x };
            }
          }

          if (updateValue == true && this.editCellCoord) {
            if (PGridCell.isFormula(this.$store.state.pgridSettings.inputBar_SelectCellVal)) {
              this.$store.commit("Mutation_UpdatePGridSettings", {
                prop: "inputBar_HasFocusCellJumpBack",
                val: true,
                source: `pgrid-textarea hasFocus set()`,
              });

              // if (this.$store.state.lastSelected) {
              //   if (this.editCellCoord == null) {
              //     let { y, x } = JSON.parse(this.$store.state.lastSelected);

              //     this.editCellCoord = { y, x };

              PGridUtils.AddInputBarClasses(this.$store, this.editCellCoord.y, this.editCellCoord.x, "pg-inputbar-editcell");

              PGridUtils.HeighlightRefCells(this.$store, this.editCellCoord.y, this.editCellCoord.x);
              // }
              // }
            } else {
              this.$store.commit("Mutation_UpdatePGridSettings", {
                prop: "inputBar_HasFocusCellJumpBack",
                val: false,
                source: `pgrid-textarea hasFocus set()`,
              });
            }
          }
        });
      },
    },
    barModeName: function () {
      let ret = null;
      ret = this.barMode;
      return ret;
    },
    barModeCols: function () {
      let ret = null;
      if (this.barMode === "small") {
        ret = 1;
      } else if (this.barMode === "medium") {
        ret = 3;
      } else if (this.barMode === "large") {
        ret = 25;
      }

      return ret;
    },
    inputBar_IsFormulaBuildingMode_Computed: {
      // getter
      get: function () {
        let ret = false;
        if (String(this.editValue).length > 1) {
          if (String(this.editValue)[0] === "=") {
            ret = true;
          }
        }

        return ret;
      },
    },

    textareaValue: {
      // getter
      get: function () {
        if (this.$store.state.pgridSettings.clientDebugMode) {
          false && console.debug("textareaValue get: " + this.$store.state.pgridSettings.inputBar_SelectCellVal);
        }

        return this.$store.state.pgridSettings.inputBar_SelectCellVal;
      },
      set: function (updateValue) {
        if (this.$store.state.pgridSettings.clientDebugMode) {
          console.debug("textareaValue updateValue set: " + this.$store.state.pgridSettings.inputBar_SelectCellVal);
        }

        this.$store.commit("Mutation_UpdatePGridSettings", {
          prop: "inputBar_SelectCellVal",
          val: updateValue,
          source: "pgrid-textarea textareaValue set()",
        });

        if (PGridCell.isFormula(this.$store.state.pgridSettings.inputBar_SelectCellVal)) {
          this.$store.commit("Mutation_UpdatePGridSettings", {
            prop: "inputBar_HasFocusCellJumpBack",
            val: true,
            source: `pgrid-textarea textareaValue set()`,
          });

          if (this.$store.state.lastSelected) {
            let { y, x } = JSON.parse(this.$store.state.lastSelected);
            // setTimeout(() => {
            PGridUtils.AddInputBarClasses(this.$store, y, x, "pg-inputbar-editcell");
            // });
          }

          let cursorPos = this.$refs.textareaRef.selectionStart;
          console.log("pgrid-textarea textareaValue set() cursorPos " + cursorPos);
          this.$store.commit("Mutation_UpdatePGridSettings", {
            prop: "inputBar_HasFocusCellJumpBack_CursorPos",
            val: cursorPos,
            source: `pgrid-textarea textareaValue set()`,
          });
        }
      },
    },
  },
  methods: {
    toggleExpand: function (event) {
      if (this.barMode == "small") {
        this.barMode = "medium";
      } else if (this.barMode == "medium") {
        // this.barMode = "small";
        this.barMode = "large";
      } else if (this.barMode == "large") {
        this.barMode = "small";
      }

      this.$store.commit("Mutation_UpdatePGridSettings", {
        prop: "inputBar_BarMode",
        val: this.barMode,
        source: `pgrid-textarea toggleExpand()`,
      });

      PGridUtils.RemoveInputBarClasses(this.$store);
      if (this.$store.state.pgridSettings.inputBar_BarMode != "small") {
        if (this.$store.state.lastSelected) {
          let { y, x } = JSON.parse(this.$store.state.lastSelected);
          PGridUtils.HeighlightRefCells(this.$store, y, x);
        }
      }

      if (window.PGridVueStore) {
        window.PGridVueStore.dispatch("Action_Redraw", { source: `resizedw()`, force: true });
      }
    },

    escPressed: function () {
      console.log("escPressed()");
      this.$refs.textareaRef.blur();

      this.$store.commit("Mutation_UpdatePGridSettings", {
        prop: "inputBar_HasFocusCellJumpBack",
        val: false,
        source: `pgrid-textarea escPressed()`,
      });

      PGridUtils.RemoveInputBarClasses(this.$store);

      if (this.$store.state.hotRef && this.$store.state.lastSelected && this.$store.state.lastSelected != "") {
        let { y, x } = JSON.parse(this.$store.state.lastSelected);

        setTimeout(() => {
          this.$store.state.hotRef.selectCell(y, x);
        });
      }
    },

    enterPressed: async function (e) {
      console.log("enterPressed() and val is " + JSON.stringify({ textareaValue: this.textareaValue, shiftKey: e.shiftKey, e: e }));

      if (!e.shiftKey) {
        this.$store.commit("Mutation_UpdatePGridSettings", {
          prop: "inputBar_HasFocusCellJumpBack",
          val: false,
          source: `pgrid-textarea enterPressed()`,
        });

        if (this.$store.state.hotRef && this.$store.state.lastSelected && this.$store.state.lastSelected != "") {
          let { y, x } = JSON.parse(this.$store.state.lastSelected);

          this.$refs.textareaRef.blur();

          PGridUtils.RemoveInputBarClasses(this.$store);
          console.debug("this.editCellCoord", this.editCellCoord);
          if (this.editCellCoord) {
            let { y, x } = this.editCellCoord;
            let newTextareaValue = this.textareaValue;
            setTimeout(() => {
              this.$store.state.hotRef.selectCell(y + 1, x);
              this.$store.state.hotRef.setDataAtCell(y, x, newTextareaValue, `pgrid-textarea enterPressed()`);
            });

            this.editCellCoord = null;
          }
        }
      }
    },

    handleCursorChange: function () {
      let cursorPos = this.$refs.textareaRef.selectionStart;
      console.log("pgrid-textarea handleCursorChange cursorPos " + cursorPos);
      this.$store.commit("Mutation_UpdatePGridSettings", {
        prop: "inputBar_HasFocusCellJumpBack_CursorPos",
        val: cursorPos,
        source: `pgrid-textarea handleCursorChange set()`,
      });
    },
  },
};
</script>
<style scoped>
.pgrid-inputbar {
  /* display: flex; */
}

.pgrid-inputbar.barmode-small {
  min-height: 32px;
}
.pgrid-inputbar.barmode-medium {
  min-height: 67px;
}
.pgrid-inputbar.barmode-large {
  min-height: 434px;
}

.pgrid-formula-label {
  font-size: 18px;
  margin: 2px 4px;
  display: inline-block;
}

.pgrid-textarea {
  margin: 2px 4px;
  resize: none;
  z-index: 1000;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 200;

  display: inline-block;
}

textarea[rows="1"].pgrid-textarea {
  max-height: 17px;
}

button.pgrid-textarea-barmode {
  padding: 1px 6px;
  border: 2px 2px;
  margin: 2px 4px;
  cursor: pointer;
}
.pgrid-inputbar {
  display: flex;
  float: left;
}
.pgrid-inputbar-box {
  /* margin: 3px; */
  /* display: inline-block; */
  /* height: 28px; */
}
.pgrid-inputbar-box:first-child {
  margin-left: 3px;
  /* margin-bottom: 12px; */
}
</style>
