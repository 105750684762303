

<template>
  <div id="pgrid-root-container" class="pgrid rootContainer">
    <PgridErrorMessageBox class="nofilters"></PgridErrorMessageBox>
  </div>
</template>
<script>
import PgridErrorMessageBox from "./pgrid-error-message-box.vue";

export default {
  name: "pgrid-root-app",
  components: {},
  mounted: async function () {},
  components: {
    PgridErrorMessageBox,
  },
};
</script>
<style>
#pgrid-root-container {
  margin-top: 3px; /*3.2px; */ /*Some uneaven offset is (or was?) needed to avoid unwhanted light shadows in cells*/
  margin-left: 8px; /*8.7px; */ /*Some uneaven offset is (or was?) needed to avoid unwhanted light shadows in cells*/
  position: relative;
}
</style>
