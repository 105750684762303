import { render, staticRenderFns } from "./pgrid-root-app-only-error.vue?vue&type=template&id=38f9f5c8&"
import script from "./pgrid-root-app-only-error.vue?vue&type=script&lang=js&"
export * from "./pgrid-root-app-only-error.vue?vue&type=script&lang=js&"
import style0 from "./pgrid-root-app-only-error.vue?vue&type=style&index=0&id=38f9f5c8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports