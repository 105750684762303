<template>
  <div class="pgrid-filter-item" v-if="filter.hiddenToGrid">
    {{ filter.displayTitle }}

    <p v-bind="filter.updateType" />
    <p v-bind="filter.updateKey" />
    <p v-bind="filter.updateValue" />

    <div class="pgrid-error-msg" v-if="errMsg != null">{{ errMsg }}</div>
  </div>
</template>
<script>

import PGridUtils from "./pgridUtils";
import {
  clone as lodash_clone,
  cloneDeep as lodash_cloneDeep,
  mergeWith as lodash_mergeWith,
  isEqual as lodash_isEqual,
  isNumber as lodash_isNumber,
  filter as lodash_filter,
  find as lodash_find,
  get as lodash_get,
  has as lodash_has,
  merge as lodash_merge,
  set as lodash_set,
  union as lodash_union,
  unionBy as lodash_unionBy,
  uniq as lodash_uniq,
  uniqBy as lodash_uniqBy,
} from "lodash";

export default {
  name: "pgrid-filter-mutatestore",
  props: {
    filterIdx: { type: Number, required: true },
  },
  data: function () {
    return {
      errMsg: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.pgridFilter.Filters[this.filterIdx];
    },
  },
};
</script>
<style scoped>
.pgrid-error-msg {
  color: red;
}
</style>
