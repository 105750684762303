<template>
  <div id="pgrid-error-message-box" class="pgrid errorMessageBox" v-if="this.$store.state.globalErrorMessage != null">
    <div id="globalErrorMessage">
      <div>Got error:</div>
      <textarea spellcheck="false" v-model="myErrMessage"></textarea>
      <span class="errorMessageClose material-icons" @click="errorMessageCloseBtn">cancel</span>
    </div>
  </div>
</template>
<script>
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';

function formatErrorMessage(inErrMsg) {
  let retErrMsg = `${inErrMsg}`;
  retErrMsg = retErrMsg.replace(/\\n/g, `\n`);
  return retErrMsg;
}

export default {
  name: "pgrid-error-message-box",
  components: {},
  computed: {
    myErrMessage() {
      if (this.$store.state.globalErrorMessage == null) {
        return ["No error messages"];
      } else {
        let globalErrorMessageClone = lodash_clone(this.$store.state.globalErrorMessage);
        return globalErrorMessageClone.map((m) => formatErrorMessage(m));
      }
    },
  },
  methods: {
    errorMessageCloseBtn: function () {
      let source = "pgrid-error-message-box";
      this.$store.commit("Mutation_UpdateRoot", {
        prop: "globalErrorMessage",
        op: "set",
        val: null,
        source: `${source} -> errorMessageCloseBtn()`,
      });
    },
  },
  mounted: async function () {},
};
</script>
<style>
.nofilters #globalErrorMessage textarea {
  /* width: 100%; */
  height: calc(100vh - 400px);
}

#globalErrorMessage textarea {
  height: calc(100vh - 265px);
  width: calc(100vw - 300px);
  position: absolute;
  overflow: auto;
  border: dotted;
  padding: 5px;
}

#pgrid-error-message-box.nofilters {
  top: 10px;
  height: calc(100vh - 150px);
}
#pgrid-error-message-box {
  position: absolute;
  top: 100px;
  left: 10px;
  background: rgba(255, 240, 240, 0.8);
  border: 1px dotted black;
  width: 95%; /* width: calc(100vw - 100px); */
  height: calc(100vh - 220px);
  padding: 10px;
  z-index: 110; /* above grid, below filter dropdown */
}

#globalErrorMessage > div {
  font-weight: bold;
}

span.errorMessageClose {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 23px;
  cursor: pointer;
}
</style>
