import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';
import { translateFromKey } from './pgridLanguage.js'
export function FillInRenderOptions(newCell, validType, lang) {

    let updated = false;

    if (validType == "checkbox") {

        lodash_set(newCell, "Meta.Validator.RendererOptions", [
            { Caption: translateFromKey('cellTypeCheckbox_Checked', lang), Value: 1 },
            { Caption: translateFromKey('cellTypeCheckbox_UnChecked', lang), Value: 0 }
        ]);
        updated = true;
    }
    else if (validType == "yesno") {

        lodash_set(newCell, "Meta.Validator.RendererOptions", [
            { Caption: translateFromKey('cellTypeYesNo_Yes', lang), Value: 1 },
            { Caption: translateFromKey('cellTypeYesNo_No', lang), Value: 0 }
        ]);
        updated = true;
    }
    else if (validType == "truefalse") {

        lodash_set(newCell, "Meta.Validator.RendererOptions", [
            { Caption: translateFromKey('cellTypeTrueFalse_True', lang), Value: 1 },
            { Caption: translateFromKey('cellTypeTrueFalse_False', lang), Value: 0 }
        ]);
        updated = true;
    }

    return newCell;

}