<template>
  <div class="pgrid-filter-item" v-bind:style="filter.settings_filterStyle">
    {{ filter.displayTitle }}
    <v-select
      ref="vueselectRef"
      :options="filter.filterOptions"
      label="text"
      v-model="optSelected"
      :reduce="(option) => option.value"
      :clearable="true"
      keepWidthDuringSearch
    />
    <div class="pgrid-error-msg" v-if="errMsg != null">{{ errMsg }}</div>
  </div>
</template>
<script>
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";

import PGridUtils from "./pgridUtils";
import {
  clone as lodash_clone,
  cloneDeep as lodash_cloneDeep,
  mergeWith as lodash_mergeWith,
  isEqual as lodash_isEqual,
  isNumber as lodash_isNumber,
  filter as lodash_filter,
  find as lodash_find,
  get as lodash_get,
  has as lodash_has,
  merge as lodash_merge,
  set as lodash_set,
  union as lodash_union,
  unionBy as lodash_unionBy,
  uniq as lodash_uniq,
  uniqBy as lodash_uniqBy,
} from "lodash";

export default {
  name: "pgrid-filter-dropdown",
  props: {
    filterIdx: { type: Number, required: true },
  },
  data: function () {
    return {
      errMsg: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.pgridFilter.Filters[this.filterIdx];
    },
    optSelected: {
      // getter
      get: function () {
        window.PGridClientDebugMode >= 2 &&
          console.log(
            `pgrid-filter-dropdown FOO get:  ${JSON.stringify({
              filterValue: JSON.stringify(this.filter.filterValue),
              value: 2 /*JSON.stringify(this.optSelected)*/,
              typeAheadPointer: 3 /*JSON.stringify(this.$refs.vueselectRef.typeAheadPointer)*/,
            })}`
          );
        if (typeof this.filter.filterValue != "object") {
          if (typeof this.filter.filterValue !== null) {
            this.filter.filterValue = { value: this.filter.filterValue };
          } else {
            this.filter.filterValue = { value: null };
          }
        }
        return this.filter.filterValue.value;
      },
      // setter
      set: function (newOptSelected = null) {
        if (this.$store.state.pgridSettings.DisableEvents_Filters == true) {
          return;
        }

        let oldOptSelected = JSON.parse(JSON.stringify(this.filter.filterValue));

        if (newOptSelected == null) {
          //If pressed the x button in the dropdown
          if (typeof this.filter.filterValue != "object") {
            this.filter.filterValue = {};
          }
          this.filter.filterValue.value = newOptSelected;
          this.$emit("dropdownFilterUpdate", { filterIdx: this.filterIdx, newOptSelected: newOptSelected });
        } else if (
          //ffilt this.filter.filterValue.value == newOptSelected.value
          lodash_get(this.filter.filterValue, "value", null) == newOptSelected
        ) {
          //Is "resetted" by ConfirmNavigation(), do nothing
        } else {
          if (
            PGridUtils.ConfirmNavigation(
              this.$store,
              `pgrid-fiter-dropdown.vue optSelected: ${newOptSelected} - filterIdx ${this.filterIdx}`,
              "Vill du ändå ändra filter?"
            )
          ) {
            //This updates the UI


            let filtValSelected = null;

            for (let s = 0; filtValSelected == null && s < this.filter.filterOptions.length; s++) {
                let foSearch = this.filter.filterOptions[s];
                if (this.filter.filterValue.value == foSearch.value) {
                    filtValSelected = JSON.parse(JSON.stringify(foSearch));
                }
            }

            this.filter.filterValue = filtValSelected; //newOptSelected

            window.PGridClientDebugMode >= 3 && console.debug("pgrid-filter-dropdown: emit dropdownFilterUpdate", newOptSelected);
            this.$emit("dropdownFilterUpdate", {
              filterIdx: this.filterIdx,
              newOptSelected: newOptSelected,
              oldOptSelected: oldOptSelected,
            });
          } else {
            //Reset back to old value
            window.PGridClientDebugMode >= 3 &&
              console.debug(
                `pgrid-filter-dropdown:  ${JSON.stringify({ optSelected: this.optSelected, filterValue: this.filter.filterValue })}`
              );

            // this.$store.state.pgridSettings.DisableEvents_Filters = true;
            this.$store.commit("Mutation_UpdatePGridSettings", {
              prop: "DisableEvents_Filters",
              val: true,
              source: `pgrid-filter-dropdown.vue optSelected() SET`,
            });

            this.optSelected = lodash_clone(this.filter.filterValue); //Does not work for the dropdown. Is it v-select?
            window.PGridClientDebugMode >= 4 &&
              console.debug(`pgrid-filter-dropdown:  ${JSON.stringify({ this_filter_filterValue: this.filter.filterValue })}`);

            this.$refs.vueselectRef.typeAheadPointer = this.filter.filterOptions.filter((o) => o.value == this.optSelected.value)[0].index;
            window.PGridClientDebugMode >= 4 &&
              console.debug(`pgrid-filter-dropdown:  ${JSON.stringify({ typeAheadPointer: this.$refs.vueselectRef.typeAheadPointer })}`);

            // this.$store.state.pgridSettings.DisableEvents_Filters = false;
            this.$store.commit("Mutation_UpdatePGridSettings", {
              prop: "DisableEvents_Filters",
              val: false,
              source: `pgrid-filter-dropdown.vue optSelected() SET`,
            });
          }
        }

        if (window.PGridClientDebugMode >= 2) {
          console.debug(
            `pgrid-filter-dropdown: ALL ${JSON.stringify({
              filter_filterOptions: this.filter.filterOptions,
              this_filterIdx: this.filterIdx,
              this_optSelected: this.optSelected,
              newOptSelected: newOptSelected,
            })}`
          );
        }
      },
    },
  },
  components: { "v-select": VueSelect },
};
</script>
<style scoped>
.pgrid-error-msg {
  color: red;
}
</style>
