import pgridMatrices from './pgridMatrices.js'
import JSONEditor from 'jsoneditor';
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';
import PGridLR_Schema from './pgridLR-schema.json';

export default {
  name: "pgrid-json-editor",
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String
    }
  },
  computed: {
    value2: {
      get: function () {
        let ret = this.value;
        if (ret === "") {
          ret = "{}";
        }
        return ret;
      },
      set: function (newVal) {
        if (newVal === "{}") {
          this.value = ""
        } else {
          this.value = newVal;
        }
      }
    }
  },
  data() {
    return {
      jsonEditorRef: null,
      currentSchema: null
    }
  },
  model: {
    event: "modified"
  },
  methods: {
    changeValue() {

      if (this.jsonEditorRef != null) {
        jsonEditorRef = this.jsonEditorRef;


        let newValueJson = jsonEditor.get();

        console.debug("found newValueJson", newValueJson);

        this.$emit("modified", JSON.stringify(newValueJson));
      }

    }
  },

  mounted() {
    let self = this;

    const jsoneditorOptions = {
      mode: "code",
      // modes: ["code", "form", "text", "tree", "view", "preview"], // allowed modes
      modes: ["code", "form", "tree"], // allowed modes
      statusBar: false,
      schema: PGridLR_Schema,
      onError: function (err) {
        
        let errMsg = err.toString();
        let source = "pgridJsonEditor";
        // alert(err.toString());
        (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> mount()` }) : alert(errMsg);
      },
      onModeChange: function (newMode, oldMode) {
        console.debug("Mode switched from", oldMode, "to", newMode);
      },
      onChange: function () {

        let validJsonText = undefined;
        try {
          console.debug("self.jsonEditorRef.get()");
          validJsonText = JSON.stringify(self.jsonEditorRef.get(), null, 2);
        } catch{
        }

        if (validJsonText !== undefined) {

          console.debug("emit modified: " + validJsonText);
          self.$emit("modified", validJsonText);
        }
      }
    };

    const jsoneditorContainer = document.getElementById(this.id);

    let jsonEditorRef = new JSONEditor(
      jsoneditorContainer
      , jsoneditorOptions
      , {}
    );
    this.jsonEditorRef = jsonEditorRef;

    this.jsonEditorRef.set({});

  },

  watch: {
    value2: function (newVal, oldVal) {
      try {
        if (this.jsonEditorRef != null) {
          let newValObj = {};
          if (newVal !== undefined && newVal !== null && newVal !== "") {
            newValObj = JSON.parse(newVal)
          } else {
            newValObj = {};
          }

          let useJsonSchema = PGridLR_Schema; //Default schema

          if (lodash_get(newValObj, "Type", false)) {
            try {
              var gridLRObj = pgridMatrices.NewPGridLRTypeObj(newValObj.Type);
              if (lodash_get(gridLRObj, "schema", false)) {
                useJsonSchema = gridLRObj.schema;
              }
            } catch (err) {
              let errMsg = `> Failed to set schema for LR Type: ${newValObj.Type} got exception ${err.message || err}`;
              console.error(errMsg);
            }
          }

          if (JSON.stringify(this.currentSchema, null, 2) != JSON.stringify(useJsonSchema, null, 2)) {
            this.jsonEditorRef.setSchema(useJsonSchema);
            this.currentSchema = useJsonSchema;
            this.jsonEditorRef.refresh();
          }

          //Hack to only update if comming from parent. Is there a better way?
          if (JSON.stringify(this.jsonEditorRef.get(), null, 2) != JSON.stringify(newValObj, null, 2)) {

            this.jsonEditorRef.set(newValObj);
          }
        }
      }
      catch (err) {
        console.error(`pgridJsonEditor watch value update error (newVal: '${newVal}') :${err.message || err}`);
      }
    }
  },
  template: '<div :id="id" style="width: 485px; height: 300px;"></div>'
}


