import {toNumber} from '../../helper/number';
import {ERROR_VALUE} from '../../error';

export const SYMBOL = '+';

export default function func(first, ...rest) {
  //Evaluate operator ADD
  const result = rest.reduce((acc, value) => acc + toNumber(value), toNumber(first));

  //Evaluate operator ADD 2
  if (Number.isNaN(result)) {
    throw Error(ERROR_VALUE);
  }

  return result;
}

func.SYMBOL = SYMBOL;
