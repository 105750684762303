import { translateFromKey } from './pgridLanguage.js';
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';

export function conv_sql2pgrid_type(sqlType) {
    let ret;



    if (sqlType === null) {
        ret = "number";
    }
    else {
        let sqlTypeLower = sqlType.toLowerCase();

        if (
            sqlTypeLower.indexOf("varchar") != -1
            || sqlTypeLower.indexOf("nchar") != -1
            || sqlTypeLower.indexOf("text") != -1
        ) {
            ret = "text";
        } else if (sqlTypeLower == "bit") {
            ret = "truefalse";
        } else if (sqlTypeLower == "int") {
            ret = "integer";
        } else if (sqlTypeLower == "bigint") {
            ret = "integer";
        } else if (sqlTypeLower == "smallint") {
            ret = "integer";
        } else if (sqlTypeLower != null && sqlTypeLower.indexOf("decimal") == 0) {
            // ret = "decimal";
            ret = sqlTypeLower; //"decimal(38,8)";
        } else if (sqlTypeLower == "float") {
            ret = "float";
        } else if (sqlTypeLower == "date") {
            ret = "date";
        } else if (sqlTypeLower == "datetime") {
            ret = "datetime";
            throw new Error(`datetime not implemented yet`)
        } else {
            ret = "number";
        }
    }
    return ret;
}
/*
export function conv_pgrid2pgdataset(cDef, cell, lang = 'en') {
 
    let ret = null;
 
    if (cDef == "bit") { //Not optimal, bool should be handled in a better way
 
        //[, 'cellTypeYesNo_No'].map(x => translate(x, vuexStore.state.pgridSettings.language));
 
        if (cell.ValStr == translateFromKey('cellTypeYesNo_Yes', lang) //"Ja") {
            ret = "1";
        }
        else if (cell.ValStr == translateFromKey('cellTypeYesNo_No', lang) //"Nej") {
            ret = "0";
        }
        else {
            throw `invalid bit type`;
        }
    }
    else if (cDef == "date") {
        if (cell.ValStr === null) {
            ret = "NULL";
        } else {
            ret = "'" + cell.ValStr + "T00:00:00.000Z" + "'";
        }
    }
    else {
        if ("Val" in cell) {
            ret = cell.Val;
        } else if ("ValStr" in cell) {
            ret = "'" + cell.ValStr + "'";
        }
        else {
            ret = "NULL";
        }
    }
 
    return ret;
}
*/
export function conv_pgrid2pgdataset(cDef, pcell, htCellMeta) {

    let ret = null;

    let cDef_lower = cDef.toLowerCase();

    let sqlPGridType = lodash_get(pcell, "Meta.Validator.Type", null);

    // if (sqlPGridType === 'checkbox') {
    //     if (pcell.ValStr == htCellMeta.checkedTemplate) {
    //         ret = 1
    //     }
    //     else if (pcell.ValStr == htCellMeta.uncheckedTemplate) {
    //         ret = 0
    //     }
    //     else {
    //         ret = null;
    //     }

    // }
    if (sqlPGridType == 'yesno' || sqlPGridType == 'truefalse' || sqlPGridType === 'checkbox') {
        let lookupByCaption = pcell.Meta.Validator.RendererOptions.find(x => x.Caption == pcell.ValStr);
        if (lookupByCaption) {
            ret = lookupByCaption.Value;
        } else {
            ret = null;
        }
    }
    else {

        let assumeNumberType = true;

        if (cDef_lower == "date") {
            assumeNumberType = false;
            if (pcell.ValStr === null) {
                ret = null;
            }
            else if (pcell.ValStr === undefined) {
                ret = null;
            }
            else if (pcell.ValStr === '') { //Probalby never used
                ret = null;
            } else {
                ret = pcell.ValStr + "T00:00:00.000Z";
            }
        }
        else if (cDef_lower == "integer") { /*pgrid type*/
            ret = pcell.Val;
        }
        else if (cDef_lower == "number") { /*pgrid type*/
            ret = pcell.Val;
        }
        else if (cDef_lower == "float") { /*pgrid type*/
            ret = pcell.Val;
        }
        else if (cDef_lower == "int") {
            ret = pcell.Val;
        }
        else if (cDef_lower == "bigint") {
            ret = pcell.Val;
        }
        else if (cDef_lower == "smallint") {
            if (pcell.Val > 32767) {
                console.warn(`conv_pgrid2pgdataset(): Value ${pcell.Val} larger is to large for an smallint`)
            }
            if (pcell.Val < -32760) {
                console.warn(`conv_pgrid2pgdataset(): Value ${pcell.Val} larger is to small for an smallint`)
            }
            ret = pcell.Val;
        }
        else if (cDef_lower == "money") {
            ret = pcell.Val;
        }
        else if (cDef_lower.indexOf("decimal") === 0) {
            ret = pcell.Val;
        }
        else if (cDef_lower == "bit") {
            ret = pcell.Val;
        }
        else if (cDef_lower.indexOf("varchar") !== -1) {
            assumeNumberType = false;
            ret = pcell.ValStr;
        }
        else if (cDef_lower.indexOf("nchar") !== -1) {
            assumeNumberType = false;
            ret = pcell.ValStr;
        }
        else if (cDef_lower == "text") {
            assumeNumberType = false;
            ret = pcell.ValStr;
        }
        else {
            throw new Error(`conv_pgrid2pgdataset() unknown type cDef: ${cDef}`);
        }

        if (assumeNumberType) {
            if (htCellMeta.numClearToZeroAndHadData) {
                if (ret === undefined || ret === null || ret === "")
                    ret = 0;
            }
        }

    }

    return ret;
}

export function conv_sql2pgdataset_value(sqlType, inCellVal, lang = 'en', sqlPGridTypeOverride = null) {

    let ret = null;

    ret = inCellVal;


    // if (sqlType === "bit" || ( sqlPGridTypeOverride == 'truefalse' || sqlPGridTypeOverride == 'checkbox' )) {

    //     if (sqlPGridTypeOverride == null) {
    //         sqlPGridTypeOverride = 'truefalse';
    //     }


    //     if (sqlPGridTypeOverride == 'truefalse') {
    //         if (inCellVal === 1) {
    //             ret = translateFromKey('cellTypeTrueFalse_True', lang)/*'True'*/;
    //         }
    //         if (inCellVal === 0) {
    //             ret = translateFromKey('cellTypeTrueFalse_False', lang)/*'False'*/;
    //         }
    //     }
    //     else if (sqlPGridTypeOverride == 'checkbox') {
    //         if (inCellVal === 1) {
    //             ret = translateFromKey('cellTypeYesNo_Yes', lang)/*'True'*/;
    //         }
    //         if (inCellVal === 0) {
    //             ret = translateFromKey('cellTypeYesNo_No', lang)/*'False'*/;
    //         }
    //     } else {
    //         if (inCellVal === 1) {
    //             ret = translateFromKey('cellTypeYesNo_Yes', lang)/*'Ja'*/;
    //         }
    //         if (inCellVal === 0) {
    //             ret = translateFromKey('cellTypeYesNo_No', lang)/*'Nej'*/;
    //         }


    //     }
    // }
    // else {

    if (sqlType == "bigint") {
        if (inCellVal == null) {
            ret = null;
        } else {
            ret = parseInt(inCellVal) //seriate-bv gives you 'bigintnummber' as string, henche parse to Int
        }
    }

    if (sqlPGridTypeOverride == 'checkbox') {
        if (inCellVal === 1) {
            ret = translateFromKey('cellTypeCheckbox_Checked', lang)/*'True'*/;
        }
        if (inCellVal === 0) {
            ret = translateFromKey('cellTypeCheckbox_UnChecked', lang)/*'False'*/;
        }
    }

    if (sqlPGridTypeOverride == 'truefalse') {
        if (inCellVal === 1) {
            ret = translateFromKey('cellTypeTrueFalse_True', lang)/*'True'*/;
        }
        if (inCellVal === 0) {
            ret = translateFromKey('cellTypeTrueFalse_False', lang)/*'False'*/;
        }
    }

    if (sqlPGridTypeOverride == 'yesno') {
        if (inCellVal === 1) {
            ret = translateFromKey('cellTypeYesNo_Yes', lang)/*'True'*/;
        }
        if (inCellVal === 0) {
            ret = translateFromKey('cellTypeYesNo_No', lang)/*'False'*/;
        }
    }

    // }


    return ret;
}

export function conv_pgdataset2sql(sqlvalue) {
    if (typeof sqlvalue === 'number') {
        return sqlvalue;
    } else if (typeof sqlvalue === 'string') {
        return "'" + sqlvalue.replace(/'/g, "''") + "'";
    } else if (typeof sqlvalue === 'object' && sqlvalue === null) {
        return 'NULL';
    } else {
        throw new Error(`conv_pgdataset2sql() illegal data (JSON.stringified): ${JSON.stringify(sqlvalue)}`);
    }
}


export function conv_sqlexp2sqlstring(sqlexp) {
    return sqlexp.replace(/'/g, "''");
}


export default {
    conv_sql2pgrid_type,
    conv_pgrid2pgdataset,
    conv_sql2pgdataset_value,
    conv_pgdataset2sql,
    conv_sqlexp2sqlstring
}