<template>
  <div class="pgrid-debugpanel">
    <!-- <textarea>{{ pgridPanelContentPGrid.Cell_DynRef }}</textarea> -->
    <input
      readonly
      v-model="pgridPanelContentPGrid.Cell_DynRef2"
      onFocus="this.select()"
      style="width: 40px; border-width: 0px; border: none; text-align: left"
    />
    <input
      readonly
      v-model="pgridPanelContentPGrid.Cell_DynRef3"
      onFocus="this.select()"
      style="width: 270px; border-width: 0px; border: none; text-align: center"
    />
    <input
      readonly
      v-model="pgridPanelContentPGrid.Cell_DynRef1"
      style="width: 105px; border-width: 0px; border: none; text-align: right"
    />

    <input
      readonly
      v-model="pgridPanelContentPGrid.Cell_DynRef4"
      onFocus="this.select()"
      style="width: 185px; border-width: 0px; border: none; text-align: left; position: absolute; top: 14px; left: 85px"
    />

    <input
      readonly
      v-model="pgridPanelContentPGrid.Cell_DynRef5"
      onFocus="this.select()"
      style="width: 90px; border-width: 0px; border: none; text-align: left; position: absolute; top: 14px; left: 275px"
    />

    <input
      readonly
      v-model="pgridPanelContentPGrid.Cell_DynRef6"
      onFocus="this.select()"
      style="width: 140px; border-width: 0px; border: none; text-align: left; position: absolute; top: 14px; right: 15px"
    />

    <hr class="dotted" />

    <span class="ms-font-m"
      >Val:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ValStr:</span
    >
    <div class="stack-inline-space" />

    <input v-model="pgridPanelContentPGrid.Cell_Val" style="width: 157px" />&nbsp;
    <!-- <span>{{ typeof pgridPanelContentPGrid.Cell_Val }}</span> -->
    <input v-model="pgridPanelContentPGrid.Cell_ValStr" :key="pgridPanelContentPGrid.Cell_ValStr" style="width: 288px" />&nbsp;
    <div class="stack-inline-space" />

    <span class="ms-font-m">Formula:</span>
    <div class="stack-inline-space" />
    <textarea v-model.lazy="pgridPanelContentPGrid.Cell_Formula" style="width: 469px" rows="2"></textarea>
    <!-- <span>{{ typeof pgridPanelContentPGrid.Cell_Formula }}</span> -->
    <div class="stack-inline-space" />

    <span class="ms-font-m">Format:</span>
    <div class="stack-inline-space" />
    <textarea v-model.lazy="pgridPanelContentPGrid.Cell_Format" cols="28" rows="6" />&nbsp;
    <!-- <span>{{ typeof pgridPanelContentPGrid.Cell_Format }}</span> -->
    <div class="stack-inline-space" />

    <span class="ms-font-m">RefType:</span>
    <div class="stack-inline-space" />
    <pgrid-json-editor id="pgrid-json" v-model="pgridPanelContentPGrid.Cell_RefType"></pgrid-json-editor>
    <div class="stack-inline-space" />

    <span class="ms-font-m">Meta:</span>
    <div class="stack-inline-space" />
    <textarea spellcheck="false" v-model.lazy="pgridPanelContentPGrid.Cell_Meta" style="width: 459px" rows="40"></textarea>
    <div class="stack-inline-space" />
    <div style="position: absolute; bottom: 10px; right: 10px">
      <ou-button type="primary" class="stack-inline pgrid-save-button" @click="uiButtonSaveDevEditor">Save</ou-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue/dist/vue.js";
import { mapState } from "vuex";

import JSONEditor from "jsoneditor";
import PgridJsonEditor from "./pgridJsonEditor";

import PGridUtils from "./pgridUtils";
import lodash from "lodash";
import { toLabel } from "../formula-parser/helper/cell";

export default {
  name: "pgrid-cell-panel",

  data: function () {
    return {
      hotSettings: this.$store.state.hotSettings,
      pgridSettings: this.$store.state.pgridSettings,
      pgridPanelContentPGrid: {
        Cell_Val: null,

        Cell_ValStr: null,

        Cell_Format: null,

        Cell_Formula: null,

        Cell_RefType: null,

        Cell_Meta: null,

        Cell_DynRef1: null,
        Cell_DynRef2: null,
        Cell_DynRef3: null,
        Cell_DynRef4: null,
        Cell_DynRef5: null,
        Cell_DynRef6: null,
      },
      pgridPanelContent_Css: null,
    };
  },

  computed: mapState(["lastSelected"]),

  watch: {
    lastSelected: function (newValue, oldValue) {
      try {
        if (this.$store.state.pgridSettings.showPanel) {
          let newValueObj = JSON.parse(newValue);

          true && console.log(`pgrid-cellpanel.vue lastSelected watch ${JSON.stringify(newValueObj)}`);

          this.pgridPanelContentPGrid.Cell_Val = PGridUtils.extractCellDataForPanel(this.$store, "Val");
          this.pgridPanelContentPGrid.Cell_ValStr = PGridUtils.extractCellDataForPanel(this.$store, "ValStr");

          let format = PGridUtils.extractCellDataForPanel(this.$store, "Format");
          if (format != null) {
            format = format.split(",").join("\n");
          }
          this.pgridPanelContentPGrid.Cell_Format = format;
          this.pgridPanelContentPGrid.Cell_Formula = PGridUtils.extractCellDataForPanel(this.$store, "Formula");
          this.pgridPanelContentPGrid.Cell_RefType = PGridUtils.extractCellDataForPanel(this.$store, "RefType");
          let cellMeta = PGridUtils.extractCellDataForPanel(this.$store, "Meta");
          this.pgridPanelContentPGrid.Cell_Meta = JSON.stringify(cellMeta, null, 2);

          if (newValueObj) {
            let lrNameAndCellAddr = { lrName: null, cellAddressR0C0: null };

            // if (format.indexOf("pg-is-lr") != -1) {
            lrNameAndCellAddr = PGridUtils.extractRelativLRNameAndAddressForPanel(
              newValueObj.y,
              newValueObj.x,
              this.$store,
              `lastSelected()`
            );
            // }

            let cellDynRef1 = `{ y: ${newValueObj.y}, x: ${newValueObj.x} }`;
            let cellDynRef2 = `${toLabel({ index: newValueObj.y }, { index: newValueObj.x })}`;
            let cellDynRef3 = ``;
            let cellDynRef4 = ``;
            let cellDynRef5 = ``;
            let cellDynRef6 = ``;

            let dimValY = lodash.get(cellMeta, "Save.DimValY", null);
            let dimValX = lodash.get(cellMeta, "Save.DimValX", null);
            if (dimValY !== null && dimValX !== null) {
              cellDynRef6 = `[${dimValX}][${dimValY}]`;

              if (lrNameAndCellAddr.lrName != null) {
                cellDynRef3 += `{${lrNameAndCellAddr.lrName}}[${dimValX}][${dimValY}]`;
              } else {
                cellDynRef3 += `[${dimValX}][${dimValY}]`;
              }
            }

            if (lrNameAndCellAddr.lrName) {
              cellDynRef4 = lrNameAndCellAddr.lrName;
            }
            if (lrNameAndCellAddr.cellAddressR0C0) {
              cellDynRef5 = lrNameAndCellAddr.cellAddressR0C0;
            }

            this.pgridPanelContentPGrid.Cell_DynRef1 = cellDynRef1;
            this.pgridPanelContentPGrid.Cell_DynRef2 = cellDynRef2;
            this.pgridPanelContentPGrid.Cell_DynRef3 = cellDynRef3;
            this.pgridPanelContentPGrid.Cell_DynRef4 = cellDynRef4;
            this.pgridPanelContentPGrid.Cell_DynRef5 = cellDynRef5;
            this.pgridPanelContentPGrid.Cell_DynRef6 = cellDynRef6;
          }
        }
      } catch (err) {
        let errMsg = `pgrid-cellpanel.vue watch lastSelected() got exception: ${err.message || err}`;
        console.warn(errMsg);
      }
    },
  },
  methods: {
    uiButtonSaveDevEditor: function () {
      let { y, x } = JSON.parse(this.lastSelected);

      let cell_props = this.pgridPanelContentPGrid;
      let cellCorrd = { y, x };

      console.log(`uiButtonSaveDevEditor(${JSON.stringify({ cell_props: cell_props, cellCorrd: cellCorrd })}`);

      PGridUtils.saveCellDataForPanel(this.$store, this.pgridPanelContentPGrid, cellCorrd, {
        source: `pgrid-cellpanel.vue: uiButtonSaveDevEditor()`,
      });
    },
  },

  components: { PgridJsonEditor },
};
</script>
<style scoped></style>
