'use strict'

const { countInDegrees, getRoots, getNonRoots } = require('./utils')

// :: { dependencyId: dependentId } -> [[taskId]]
export default function BatchingToposort(dag, pgridMode = false) {
    const indegrees = countInDegrees(dag)

    let ret = null;
    const sorted = []

    let roots = getRoots(indegrees)

    while (roots.length) {
        sorted.push(roots)

        const newRoots = []
        roots.forEach(root => {
            if (!(root in dag)) {
                dag[root] = [];
            }
            dag[root].forEach(dependent => {
                indegrees[dependent]--
                if (indegrees[dependent] === 0) {
                    newRoots.push(dependent)
                }
            })
        })

        roots = newRoots
    }

    if (pgridMode) {
        ret = { sorted: sorted, circles: getNonRoots(indegrees) };
    } else {
        ret = sorted;
        if (getNonRoots(indegrees).length) {
            throw Error(`Cycle(s) detected; toposort only works on acyclic graphs|${JSON.stringify(getNonRoots(indegrees))}`);
        }
    }

    return ret;
}

// module.exports = batchingToposort

// export default {
//     batchingToposort
// }

export var __useDefault = true;
