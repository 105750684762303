import { PGridLR_Base } from './pgridLR_Base.js';

import PGridLR_MetaCell_Schema from './PGridLR_MetaCell-schema.json';
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';

export class PGridLR_MetaCell extends PGridLR_Base {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}

    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        this.schema = PGridLR_MetaCell_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        this.AxisDefMap = {
            // COLUMNS: { type: 'COLUMNS', mapsTo: null },  //'Column' is a property in the linked range definition
            FACTS: { type: 'FACTS', mapsTo: null }
        }

        this.xLength = 1;
        this.yLength = 1;
        // this.xStart = null;
        // this.yStart = null;
        this.x = x;
        this.y = y;

        this.Overlay = lodash_get(this.linkedRange, "Overlay", null);
        this.OverlayOffsetY = lodash_get(this.linkedRange, "OverlayOffsetY", null);
        this.OverlayOffsetX = lodash_get(this.linkedRange, "OverlayOffsetX", null);

        if (!this.MetaCellMap) {
            this.MetaCellMap = [];
        }
    }

    whoAmI() {
        return 'I am a PGridLR_MetaCell'
    }

    // Nothing to return
    Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {
        let ret = Array();
        return ret;
    }

    // Nothing to to here
    Phase2_Load_DimDataInAxisData(pgridDim) {
        return null;
    }

    // Nothing to to here
    Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {
        let ret = { pgridTableDyn: pgridTableDyn, addedRowsOffset: 0, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }
        return ret;
    }

    Phase5_DoFetchData() {
        return false;
    }

    // Nothing to to here
    Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {
        return pgridTable;
    }

    // Nothing to to here
    Phase6_Generate_FactRange(pgridDataDyn, factData, lang = null) {
        let factRangeData = [[undefined]];
        return factRangeData;
    }

}