<template>
  <div class="pgrid-filter-chooser" v-show="!filter.hiddenToGrid">
    <div class="overlay-filter-loading" v-if="(filter.type != 'MutateStore' && (filter.filterIsLoading || isLoading))">
      <div class="lds-ellipsis" v-if="filter.filterIsLoading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <pgrid-filter-dropdown
      v-if="filter.type == 'Dropdown'"
      v-bind:filterIdx="filterIdx"
      v-on:dropdownFilterUpdate="handleDropDownFilterSelectionChange($event)"
    />
    <pgrid-filter-mutatestore
      v-if="filter.type == 'MutateStore'"
      v-bind:filterIdx="filterIdx"
    />
  </div>
</template>
<script>
import PgridFilterDropdown from "./pgrid-filter-dropdown.vue";
import PgridFilterMutatestore from './pgrid-filter-mutatestore.vue';

export default {
  name: "pgrid-filter-chooser",
  props: {
    filterIdx: { type: Number, required: true },
  },
  computed: {
    filter() {
      return this.$store.state.pgridFilter.Filters[this.filterIdx];
    },
    isLoading() {
      return /* this.$store.state.pgridSettings.isLoading || */ this.$store.state.pgridSettings.isSaving;
    },
  },
  methods: {
    handleDropDownFilterSelectionChange: function (filterUpdate) {
      window.PGridClientDebugMode >= 3 && console.debug("pgrid-filter-chooser: emit filterUpdate", filterUpdate);
      this.$emit("filterUpdate", filterUpdate);
    },
  },
  components: { PgridFilterDropdown, PgridFilterMutatestore },
};
</script>
<style scoped>
.pgrid-error-msg {
  color: red;
}
</style>
